import { CalendarOutlined } from '@ant-design/icons';
import { Alert, CheckboxOptionType, Radio, Space, Switch, Typography, theme } from 'antd';
import { TFunction } from 'i18next';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import RescheduleDateTimePicker from './RescheduleDateTimePicker';
const { Text } = Typography;

function getRescheduleAgentOptions(t: TFunction<'translation'>): CheckboxOptionType[] {

  return [
    { value: false, label: t('misc.anyone') },
    { value: true, label: t('misc.myself') },
  ];
}

interface IRescheduleProps {
  onReschedule(scheduledAfter: string | undefined, toMyself: boolean): void;
  onCloseAdditionalDetails(): void;
  currentRescheduleAfter?: string;
  currentRescheduleToMyself: boolean;
  remainingReschedules?: number;
  rescheduleLimit?: number;
  rescheduleLimitReached: boolean;
}

const Wrapper = styled.div`
  padding-left: ${p => p.theme.padding}px;
  display: flex;
  vertical-align: middle;
`;

const Reschedule: React.FC<IRescheduleProps> = ({ onReschedule, onCloseAdditionalDetails, currentRescheduleAfter, currentRescheduleToMyself, rescheduleLimit, remainingReschedules, rescheduleLimitReached }) => {
  const { token } = theme.useToken();
  const { t } = useTranslation();

  const [isVisible, setIsVisible] = useState<boolean>(!!currentRescheduleAfter);

  const toggleReschedule = () => {
    const newValue = !isVisible;
    if (!newValue) {
      onReschedule(undefined, false);
    }
    else {
      onCloseAdditionalDetails();
    }
    setIsVisible(newValue);
  };

  const rescheduleAgentOptions = useMemo(
    () => getRescheduleAgentOptions(t),
    [t],
  );

  return (
    <Wrapper>
      <Space align='center' size='middle' wrap>
        <Text style={rescheduleLimitReached ? { color: token.colorTextDisabled } : {}}>
          <Space>
            <CalendarOutlined />
            {t('misc.reschedule')}
          </Space>
        </Text>
        <Switch onChange={toggleReschedule} checked={isVisible} disabled={rescheduleLimitReached} />
        {
          rescheduleLimit && !rescheduleLimitReached &&
          <Text style={{ color: token.colorTextLabel }}>
            {t('misc.remaining')} {remainingReschedules}/{rescheduleLimit}
          </Text>
        }
        {
          rescheduleLimitReached &&
          <Alert type="warning" message={t('feedback.rescheduleLimitReached')} showIcon />
        }
        {
          isVisible &&
          <Space size='middle' wrap>
            <RescheduleDateTimePicker
              onDateTimeChange={(dateTime) => onReschedule(dateTime, currentRescheduleToMyself)}
              rescheduledAfter={currentRescheduleAfter}
            />
            <Radio.Group
              options={rescheduleAgentOptions}
              value={currentRescheduleToMyself}
              onChange={(e) => onReschedule(currentRescheduleAfter, e.target.value)}
              optionType="button"
            />
          </Space>
        }
      </Space>
    </Wrapper>
  );
};

export default Reschedule;
