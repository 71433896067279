/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { RootState } from './store';

export function prepareHeaders(headers: Headers, { getState }: { getState(): unknown }): Headers {
  const token = (getState() as RootState).configuration.token;
  headers.set('authorization', `Bearer ${token}`);
  
  return headers;
}

export function apiBaseQuery(apiPath: string): BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> {

  return async (args, store, extraOptions) => {
    const { apiBaseUrl } = (store.getState() as RootState).configuration;
    const rawBaseQuery = fetchBaseQuery({ baseUrl: apiBaseUrl + apiPath, prepareHeaders });

    return rawBaseQuery(args, store, extraOptions);
  };
}

export const post = <T>(url: string, body: NonNullable<T>): FetchArgs => ({
  url,
  method: 'POST',
  body,
});

export const put = <T>(url: string, body: NonNullable<T>): FetchArgs => ({
  url,
  method: 'PUT',
  body,
});

export const patch = <T>(url: string, body?: NonNullable<T>): FetchArgs => ({
  url,
  method: 'PATCH',
  body,
});

export const del = <T>(url: string, body?: NonNullable<T>): FetchArgs => ({
  url,
  method: 'DELETE',
  body,
});

interface PatchAdd {
  op: 'add';
  path: string;
  value: any;
}

interface PatchRemove {
  op: 'remove';
  path: string;
}

interface PatchReplace {
  op: 'replace';
  path: string;
  value: any;
}

interface PatchMove {
  op: 'move';
  path: string;
  from: string;
}

interface PatchCopy {
  op: 'copy';
  path: string;
  from: string;
}

interface PatchTest {
  op: 'test';
  path: string;
  value: any;
}

export type JsonPatchData = (PatchAdd | PatchRemove | PatchReplace | PatchMove | PatchCopy | PatchTest)[];