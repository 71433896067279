import { ConfigProvider, ThemeConfig, theme as antdTheme } from 'antd';
import React, { PropsWithChildren, ReactElement, useMemo } from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { ActivityHistoryFragmentProps } from '..';
import GlobalStyle from '../GlobalStyle';
import { createStore } from '../redux/store';
import ActivityServiceProvider from './ActivityServiceProvider';
import TranslationProvider from './TranslationProvider';
import UserProvider from './UserProvider';

function StyledThemeProvider({ children }: PropsWithChildren<object>): ReactElement {
  const { token: themeTokens } = antdTheme.useToken();

  return (
    <ThemeProvider theme={themeTokens}>
      {children}
    </ThemeProvider>
  );
}

export default function Providers({ children, token, apiBaseUrl, language, theme }: PropsWithChildren<ActivityHistoryFragmentProps>): ReactElement {
  const store = useMemo(
    () => createStore(),
    [],
  );

  const updatedTheme: ThemeConfig = useMemo(
    () => ({
      ...theme,
      components: {
        Tag: {
          marginXS: 0,
        },
      },
    }),
    [theme],
  );

  return (
    <Provider store={store}>
      <ActivityServiceProvider token={token} apiBaseUrl={apiBaseUrl}>
        <UserProvider token={token}>
          <ConfigProvider theme={updatedTheme}>
            <StyledThemeProvider>
              <TranslationProvider language={language}>
                <GlobalStyle />
                {children}
              </TranslationProvider>
            </StyledThemeProvider>
          </ConfigProvider>
        </UserProvider>
      </ActivityServiceProvider>
    </Provider>
  );
}
