export interface CustomerPhoneNumber {
  phoneNumberId: number;
  type: string | null;
  number: string;
  preferred: boolean;
}

export interface CustomerExternalProfile {
  externalProfileId: string;
  provider: string;
  id: string;
  username: string | null;
}

export interface CustomerCustomField {
  fieldDefinitionId: number;
  value: string;
}

export interface Customer {
  readonly customerId: string;
  readonly businessUnitId: string | null;
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
  email: string | null;
  phoneNumbers: CustomerPhoneNumber[];
  externalProfiles: CustomerExternalProfile[];
  customFields: CustomerCustomField[];
}

export const defaultCustomer = Object.freeze<Customer>({
  customerId: '',
  businessUnitId: null,
  firstName: null,
  middleName: null,
  lastName: null,
  email: null,
  phoneNumbers: [],
  externalProfiles: [],
  customFields: [],
});