import React, { FunctionComponent, PropsWithChildren, ReactElement } from 'react';
import styled, { css } from 'styled-components';
import { createShouldForwardProp } from '../../utils/styledUtils';

export interface IconProps extends Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>, 'ref'> {
  icon?: FunctionComponent;
  large?: boolean;
  small?: boolean;
  color?: string;
}

const IconContainer = styled.span.withConfig({ shouldForwardProp: createShouldForwardProp(['icon', 'large', 'small', 'color']) })<IconProps>`
  ${p => (p.large && !p.small) && css`svg { transform: scale(1.143); }`} // 14px --> 16px
  ${p => (p.small && !p.large) && css`svg { transform: scale(0.857); }`} // 14px --> 12px

  color: ${p => p.color};
`;

export default function Icon(props: PropsWithChildren<IconProps>): ReactElement {

  return (
    <IconContainer {...props}>
      {props.icon ? <props.icon /> : props.children}
    </IconContainer>
  );
}