import { useMemo } from 'react';
import { CustomFieldDefinition } from '../models/customFields';
import { Customer } from '../models/customer';
import { useGetCustomFieldsQuery } from '../redux/apis/customerApi';

const defaultCustomFieldDefinitions: CustomFieldDefinition[] = [];

interface CustomerField extends CustomFieldDefinition {
  options: string[];
  value: string | undefined;
}

function useGetCustomerFields(customer: Customer): CustomerField[] {
  const { data: customFieldDefinitions = defaultCustomFieldDefinitions } = useGetCustomFieldsQuery(customer.businessUnitId);

  const customerFields = useMemo<CustomerField[]>(
    () => customFieldDefinitions.map(fd => ({
      id: fd.id,
      label: fd.label,
      type: fd.type,
      options: fd.options ?? [],
      value: customer.customFields.find(f => f.fieldDefinitionId === fd.id)?.value,
    })),
    [customFieldDefinitions, customer.customFields],
  );

  return customerFields;
}

export default useGetCustomerFields;