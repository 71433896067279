import { Button, ButtonProps, Tooltip } from 'antd';
import React, { ReactElement } from 'react';

export interface SearchAndFilterBarToggleButtonProps {
  checked: boolean;
  icon: ButtonProps['icon'];
  onClick: ButtonProps['onClick'];
  tooltip: string;
  ariaLabel: string;
}

export default function SearchAndFilterBarToggleButton({ checked, icon, onClick, tooltip, ariaLabel }: SearchAndFilterBarToggleButtonProps): ReactElement {

  return (
    <Tooltip title={tooltip}>
      <Button
        shape="circle"
        type={checked ? 'primary' : 'default'}
        icon={icon}
        onClick={onClick}
        aria-label={ariaLabel}
        role="checkbox"
        aria-checked={checked}
      />
    </Tooltip>
  );
}