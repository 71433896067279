export function capitalize(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function stringCompare(left: string, right: string): number {
  return left.localeCompare(right, undefined, { sensitivity: 'base' });
}

export function couldBeAPhone(str: string) {
  return /^\+?[\d\s()-]+$/.test(str);
}