import { ExclamationCircleFilled, MergeCellsOutlined, SwapOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BasePropsContext } from '../components/providers/BasePropsProvider';
import { useMergeCustomersMutation } from '../redux/apis/customerApi';

interface CustomerEditPageActions {
  actions: React.ReactNode[];
  contextHolder: React.ReactNode;
}

export default function useCustomerEditPageActions(actualCustomerId: string | undefined, shownCustomerId: string, onCustomersMerged: () => void): CustomerEditPageActions {
  const { messageApi, onSwapCustomer } = useContext(BasePropsContext);
  const [modalApi, modalContextHolder] = Modal.useModal();
  const { t } = useTranslation();

  const [mergeCustomers] = useMergeCustomersMutation();

  const handleMerge = useCallback(
    () => {
      modalApi.confirm({
        title: t('customerActions.mergeCustomersHeader'),
        icon: <ExclamationCircleFilled />,
        content: t('customerActions.mergeCustomersModalText'),
        onOk: async () => {
          if (!actualCustomerId)
            return;

          try {
            await mergeCustomers({ sourceCustomerId: shownCustomerId, targetCustomerId: actualCustomerId }).unwrap();
            messageApi.success(t('messages.customersMerged'));
            onCustomersMerged();
          }
          catch {
            messageApi.error(t('messages.customerMergeFailed'));
          }
        },
      });
    },
    [actualCustomerId, mergeCustomers, messageApi, modalApi, onCustomersMerged, shownCustomerId, t],
  );

  const handleSwap = useCallback(
    () => {
      modalApi.confirm({
        title: actualCustomerId ? t('customerActions.swapCustomerHeader') : t('customerActions.activateCustomerHeader'),
        icon: <ExclamationCircleFilled />,
        content: actualCustomerId
          ? t('customerActions.swapCustomerModalText')
          : t('customerActions.activateCustomerModalText'),
        onOk: () => onSwapCustomer?.(shownCustomerId),
      });
    },
    [actualCustomerId, modalApi, onSwapCustomer, shownCustomerId, t],
  );

  const actions = useMemo(
    () => actualCustomerId === shownCustomerId
      ? [
        // will have actions like Call & Email later on
      ]
      : [
        <Button key="merge" type="primary" disabled={!actualCustomerId} icon={<MergeCellsOutlined />} onClick={handleMerge}>
          {t('customerActions.merge')}
        </Button>,
        <Button key="swap" type="primary" disabled={!onSwapCustomer} icon={actualCustomerId ? <SwapOutlined /> : <UserOutlined />} onClick={handleSwap}>
          {actualCustomerId ? t('customerActions.swap') : t('customerActions.makeActive')}
        </Button>,
      ],
    [actualCustomerId, handleMerge, handleSwap, onSwapCustomer, shownCustomerId, t],
  );

  return {
    actions,
    contextHolder: modalContextHolder,
  };
}