import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { IScriptRendererProps } from '.';
import CardRenderer from './CardRenderer';
import Header from './Header';

const LayoutContainer = styled.div<{ $expanded?: boolean }>`
  height: 100%;
  
  display: flex;
  flex-direction: column;
`;

export default function Layout(props: IScriptRendererProps): ReactElement {
  const { expanded, onExpandedChangeRequested } = props;

  return (
    <LayoutContainer $expanded={expanded}>
      {expanded !== undefined && <Header expanded={expanded} onExpandedChangeRequested={onExpandedChangeRequested} />}
      <CardRenderer {...props} show={expanded !== false} />
    </LayoutContainer>
  );
}