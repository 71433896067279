import { CalendarOutlined } from '@ant-design/icons';
import { Button, Tooltip, theme } from 'antd';
import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Customer } from '../../models/customer';
import { useGetCallbackCampaignsQuery } from '../../redux/apis/campaignApi';
import Frame from '../layouts/Frame';
import Stack from '../layouts/Stack';
import CustomerInfo from './CustomerInfo';
import ScheduleCall from './ScheduleCall';

const StyledFrame = styled(Frame)`
  background-color: ${p => p.theme.colorBgLayout};
  border-radius: ${p => p.theme.borderRadius}px;
`;

export interface CustomerInfoWithActionsProps {
  businessUnitId: string | null;
  customer: Customer;
  onEditClick(): void;
  onSchedule(campaignId: string, phoneNumber: string, date: Date, toMyself: boolean): Promise<boolean>;
  actions?: React.ReactNode[] | ((customer: Customer) => React.ReactNode[]);
  disableSchedule?: boolean;
}

export default function CustomerInfoWithActions({ businessUnitId, customer, onEditClick, onSchedule, actions, disableSchedule }: CustomerInfoWithActionsProps): ReactElement {
  const { token } = theme.useToken();
  const { t } = useTranslation();
  const [isScheduleOpen, setIsScheduleOpen] = useState(false);

  const { data: callbackCampaigns } = useGetCallbackCampaignsQuery(businessUnitId);
  const isScheduleDisabled = useMemo(
    () => disableSchedule || customer.phoneNumbers.length === 0 || !callbackCampaigns || callbackCampaigns.length === 0,
    [callbackCampaigns, customer.phoneNumbers.length, disableSchedule],
  );

  const handleSchedule = useCallback(
    async (campaignId: string, phoneNumber: string, date: Date, toMyself: boolean) => {
      const isSuccess = await onSchedule(campaignId, phoneNumber, date, toMyself);

      if (isSuccess)
        setIsScheduleOpen(false);
    },
    [onSchedule],
  );

  return (
    <Frame horizontalPadding="middle" verticalPadding="small">
      <Stack vertical gap="middle">
        <CustomerInfo
          customer={customer}
          showEditButton
          onEditClick={onEditClick}
        />
        <Stack wrap gap={['middle', 'small']} horizontalAlign="space-between">
          {
            actions?.length
              ? (
                <Stack gap="small">
                  {
                    typeof actions === 'function'
                      ? actions(customer)
                      : actions
                  }
                </Stack>
              )
              : <div />
          }
          <Tooltip title={isScheduleDisabled ? t('scheduleCall.scheduleDisabledHint') : undefined}>
            <Button
              disabled={isScheduleDisabled}
              type={isScheduleOpen ? 'primary' : 'default'}
              icon={<CalendarOutlined />}
              onClick={() => setIsScheduleOpen(!isScheduleOpen)}
              style={{ backgroundColor: isScheduleOpen ? token.colorPrimaryActive : undefined }}
            >
              {t('scheduleCall.scheduleCall')}
            </Button>
          </Tooltip>
        </Stack>
        {
          isScheduleOpen &&
          <StyledFrame padding="small">
            <ScheduleCall
              businessUnitId={businessUnitId}
              customer={customer}
              onSchedule={handleSchedule}
              onCancel={() => setIsScheduleOpen(false)}
            />
          </StyledFrame>
        }
      </Stack>
    </Frame>
  );
}