import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface IRescheduleProps {
  onDateTimeChange(value?: string): void;
  rescheduledAfter?: string;
}

const range = (start: number, end: number) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

const minuteStep = 5;

const RescheduleDateTimePicker: FC<IRescheduleProps> = ({ onDateTimeChange, rescheduledAfter }) => {
  const { t } = useTranslation();

  const dateTime = rescheduledAfter ? dayjs(rescheduledAfter) : undefined;
  const minimumValue = useMemo(() => dayjs(), []);

  const isTimeDisabled = useMemo(
    () => (selectedDate: Dayjs | null) => {
      if (!selectedDate) {
        return {
          disabledHours: () => range(0, 24),
          disabledMinutes: () => range(0, 60),
        };
      }

      if (selectedDate.startOf('day').isSame(minimumValue.startOf('day'))) {
        return {
          disabledHours: () => range(0, minimumValue.hour()),
          disabledMinutes: (selectedHour: number) => (selectedHour == minimumValue.hour() ? range(0, Math.min(minimumValue.minute() + minuteStep, 60)) : []),
        };
      }

      return {
        disabledHours: () => [],
        disabledMinutes: () => [],
      };
    },
    [minimumValue]
  );

  const onChange = (value: dayjs.Dayjs | null) => {
    const newValue = value?.set('second', 0);
    onDateTimeChange(newValue?.toISOString());
  };

  return (
    <DatePicker
      placeholder={t('misc.date')}
      hideDisabledOptions={true}
      disabledDate={val => val.isBefore(minimumValue.startOf('day'))}
      disabledTime={isTimeDisabled}
      showTime={{ format: 'HH:mm' }}
      onChange={onChange}
      value={dateTime}
      showNow={false}
      showSecond={false}
      status={dateTime?.isBefore(minimumValue.add(minuteStep, 'minutes')) ? 'warning' : undefined}
      format="YYYY-MM-DD HH:mm"
    />
  );
};

export default RescheduleDateTimePicker;
