import deDE from './de-DE.json';
import enUS from './en-US.json';
import huHU from './hu-HU.json';
import roRO from './ro-RO.json';

interface Language {
  translation: { };
}

const translations: Record<string, Language> = {
  'en-US': enUS,
  'hu-HU': huHU,
  'ro-RO': roRO,
  'de-DE': deDE,
};

export default translations;