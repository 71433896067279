import { configureStore, EnhancedStore } from '@reduxjs/toolkit';
import React, { Component, ReactNode } from 'react';
import { Provider } from 'react-redux';
import { authReducer, AuthState } from '../redux/authSlice';
import {
  conversationReducer,
  ConversationState,
  initialState,
} from '../redux/conversationSlice';

interface IProps {
  conversationId: string;
  token: string;
  children: ReactNode;
}

class StoreProvider extends Component<IProps> {
  private store: EnhancedStore;

  constructor(props: IProps) {
    super(props);

    const token = this.props.token;

    this.store = configureStore({
      reducer: {
        conversationReducer,
        authReducer,
      },
      preloadedState: {
        conversationReducer: {
          ...initialState,
          conversationId: this.props.conversationId,
        },
        authReducer: {
          token,
        },
      },
    });
  }

  public render() {
    return <Provider store={this.store}>{this.props.children}</Provider>;
  }
}

export type RootState = {
  conversationReducer: ConversationState;
  authReducer: AuthState;
};

export default StoreProvider;
