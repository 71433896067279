import { Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CallbackRequestedBy } from '../../models/dtos';
import Timer from './Timer';

interface IVirtualConversationTitleProps {
  title: string;
  previewTimerExpiresAt?: string;
  afterCallTimerExpiresAt?: string;
  callRequestedBy: CallbackRequestedBy | null;
}

const VirtualConversationTitle: React.FC<IVirtualConversationTitleProps> = ({ title, previewTimerExpiresAt, afterCallTimerExpiresAt, callRequestedBy }) => {
  const { t } = useTranslation();

  return <>
    <Typography.Title level={3} style={{ margin: 0 }}>
      <>
        {
          previewTimerExpiresAt &&
          <>
            {title}
            &nbsp;{t('misc.startsIn')}&nbsp;
            <Timer expiresAt={previewTimerExpiresAt} />
          </>
        }
        {
          afterCallTimerExpiresAt &&
          <>
            {title}
            &nbsp;{t('misc.endsIn')}&nbsp;
            <Timer expiresAt={afterCallTimerExpiresAt} />
          </>
        }
      </>
    </Typography.Title>
    {
      callRequestedBy &&
      <Typography.Title level={5} style={{ margin: 0 }}>
        {t('requestedBy.', { context: callRequestedBy })}
      </Typography.Title>
    }
  </>;
};

export default VirtualConversationTitle;
