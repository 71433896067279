import { Space, Typography } from 'antd';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import Header from '../presenters/Header';

export interface IMainLayoutProps {
  expanded?: boolean;
  onExpandedChangeRequested?(expanded: boolean): void;
  campaignName: string;
  title: ReactNode;
  customer: ReactNode;
  additionalDetails: ReactNode;
  phoneNumbers: ReactNode;
  dialAttempts: ReactNode;
  actions: ReactNode;
  loadingOverlay: ReactNode;
  showPhoneNumbers: boolean;
  showAdditionalDetails: boolean;
  warning: ReactNode;
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 1px;
  max-height: 100%;
`;

const ContentContainer = styled.div<{ $show: boolean }>`
  min-height: 1px;
  flex: 1 1 auto;
  display: ${p => p.$show ? 'flex' : 'none'};
  flex-direction: column;
  padding: 0 ${p => p.theme.padding}px;
`;

const DialAttempts = styled.div`
  padding-bottom: ${p => p.theme.paddingXS}px;
`;

const PhoneNumbers = styled.div`
  min-height: 1px;
  flex-shrink: 1;
  overflow: auto;
  padding: ${p => p.theme.paddingXS}px ${p => p.theme.padding}px;
  border: ${p => p.theme.lineWidth}px solid ${p => p.theme.colorBorder};
  border-radius: 6px;
`;

const Actions = styled.div`
  padding: ${p => p.theme.paddingSM}px 0;
`;

const MainLayout: React.FC<IMainLayoutProps> = ({ expanded, onExpandedChangeRequested, campaignName, title, customer, additionalDetails, phoneNumbers, actions, loadingOverlay, dialAttempts, showPhoneNumbers, showAdditionalDetails, warning }) => {

  return (
    <MainContainer>
      {expanded !== undefined && <Header campaignName={campaignName} expanded={expanded} onExpandedChangeRequested={onExpandedChangeRequested} />}
      <ContentContainer $show={expanded !== false}>
        <Space direction='vertical' style={{ flexShrink: 0 }}>
          <div>{title}</div>
          <div>{warning}</div>
          <div>{customer}</div>
          {/* for old Agent UI */}
          {expanded === undefined && <Typography.Title level={4} style={{ margin: 0 }}>{campaignName}</Typography.Title>}
          {showAdditionalDetails && <div>{additionalDetails}</div>}
          <DialAttempts>{dialAttempts}</DialAttempts>
        </Space>
        {showPhoneNumbers && <PhoneNumbers>{phoneNumbers}</PhoneNumbers>}
        <Actions>{actions}</Actions>
        {loadingOverlay}
      </ContentContainer>
    </MainContainer >
  );
};

export default MainLayout;
