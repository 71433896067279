import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  onCancel(): void;
  onSubmit(): void
}

const ActionsContainer = styled.div`
  display: flex;
  gap: ${p => p.theme.padding}px;
  padding-top: ${p => p.theme.paddingSM}px;
`;

const EditActions: FC<Props> = ({ onCancel, onSubmit }) => {
  const { t } = useTranslation();

  return <ActionsContainer>
    <Button icon={<CloseOutlined />} onClick={onCancel}>
      {t('misc.cancel')}
    </Button>
    <Button type='primary' htmlType='submit' icon={<CheckOutlined />} onClick={onSubmit}>
      {t('misc.save')}
    </Button>
  </ActionsContainer>;
};

export default EditActions;