import { MessageInstance } from 'antd/es/message/interface';
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import ServiceHandler from './components/containers/ServiceHandler';
import Providers from './components/providers/Providers';
import './utils/sortUtils';
import './versionInfo';

const RootContainer = styled.div`
  height: 100%;
`;

export interface ContactLookupFragmentProps {
  apiBaseUrl: string;
  token: string;
  language?: string;
  messageApi?: MessageInstance;

  workItemId: string;

  /** Feature flags to configure the behavior of some features and components. */
  featureFlags?: {
    /** If set, sensitive information such as agent names will be hidden in the Activity History. */
    hideSensitiveAgentInformation?: boolean;
  };
}

function getErrorMessageForMissingProp(prop: string) {
  return `Error: Empty/undefined ${prop} property received in ContactLookupFragment.`;
}

const requiredProps: (keyof ContactLookupFragmentProps)[] = ['apiBaseUrl', 'token', 'workItemId'];

function validateProps(props: ContactLookupFragmentProps) {
  requiredProps.forEach(key => {
    if (!props[key])
      throw new Error(getErrorMessageForMissingProp(key));
  });
}

export default function ContactLookupFragment(props: ContactLookupFragmentProps): ReactElement {
  validateProps(props);

  return (
    <RootContainer id="buzzeasy-fragment-contactLookup" role="region">
      <Providers {...props}>
        <ServiceHandler />
      </Providers>
    </RootContainer>
  );
}

export { default as CrmChannelSelector, CrmChannelSelectorProps } from './crm/CrmChannelSelector';

