import { Button, Space } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getInteractiveElementProps } from '../../utils/accessibility';
import AdditionalDetail from './AdditionalDetail';

const StyledLink = styled(Button).attrs({ type: 'link' })`
  &&:focus-visible {
    outline-offset: -${p => p.theme.lineWidthFocus}px;
  }
`;

export interface IExpandedAdditionalDetailsProps {
  additionalDetails: Record<string, string>;
  onShowLess(): void;
}

const ExpandedAdditionalDetails: React.FC<IExpandedAdditionalDetailsProps> = ({ additionalDetails, onShowLess }) => {
  const { t } = useTranslation();

  return (
    <Space align='baseline' size={['middle', 'small']} wrap>
      {
        Object.keys(additionalDetails).map((key) => <AdditionalDetail key={key} title={key} value={additionalDetails[key]} />)
      }
      <StyledLink {...getInteractiveElementProps(onShowLess)} aria-expanded="true" aria-label={t('details.showLessTitle')}>{t('details.less')}</StyledLink>
    </Space>
  );
};

export default ExpandedAdditionalDetails;
