import { HTMLAttributes } from 'react';

type Element = HTMLElement | SVGSVGElement;

type OnClickEventHandler<T extends Element> = NonNullable<HTMLAttributes<T>['onClick']>;
type OnClickEvent<T extends Element> = Parameters<OnClickEventHandler<T>>[0];

type OnKeyDownEventHandler<T extends Element> = NonNullable<HTMLAttributes<T>['onKeyDown']>;
type OnKeyDownEvent<T extends Element> = Parameters<OnKeyDownEventHandler<T>>[0];

export type OnActivateHandler<T extends Element = Element> = (e: OnClickEvent<T> | OnKeyDownEvent<T>) => void;

type ElementButtonProps<T extends Element> = Required<Pick<HTMLAttributes<T>, 'tabIndex' | 'onClick' | 'onKeyDown'>>;

function handleOnKeyDown<T extends Element>(e: OnKeyDownEvent<T>, onActivate: OnActivateHandler<T>): void {
  if (
    (e.key === 'Enter' || e.code === 'Space') &&
    !e.ctrlKey &&
    !e.shiftKey &&
    !e.altKey &&
    !e.metaKey
  ) {
    onActivate(e);
  }
}

export function getInteractiveElementProps<T extends Element = Element>(onActivate: OnActivateHandler<T>): ElementButtonProps<T> {

  return {
    tabIndex: 0,
    onClick: onActivate,
    onKeyDown: (e) => handleOnKeyDown(e, onActivate),
  };
}