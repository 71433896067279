import { UserOutlined } from '@ant-design/icons';
import { Avatar, Col, List, Row, theme } from 'antd';
import Text from 'antd/es/typography/Text';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Customer } from '../../models/customer';
import { concatCustomerPhones, getCustomerFullName, getCustomerPrimaryPhone } from '../../models/customer.helpers';
import { spaceSizeToPixels } from '../../utils/antDesignUtils';
import Stack from '../layouts/Stack';
import CustomerContact from './CustomerContact';
import { getInteractiveElementProps } from './CustomerList.accessibilityHelpers';

const StyledMeta = styled(List.Item.Meta)`
  .ant-list-item-meta-title {
    margin-top: 0;
  }
`;

const ListItem = styled(List.Item) <{ $isActive: boolean; $forceShowActions?: boolean }>`
  && {
    cursor: pointer;
    background-color: ${p => p.$isActive && p.theme.colorBgTextHover};
    padding-left: ${p => p.theme.paddingMD}px;
    padding-right: ${p => p.theme.paddingMD}px;

    &:focus-visible {
      outline: ${p => p.theme.lineWidthFocus}px ${p => p.theme.lineType} ${p => p.theme.colorPrimaryBorder};
      outline-offset: -${p => p.theme.lineWidthFocus}px;
      transition: outline-offset 0s, outline 0s;
    }

    &:hover {
      background-color: ${p => p.theme.colorBgTextHover};
    }

    position: relative;

    .cdf-action-container {
      opacity: 0;
      pointer-events: none;
    }

    &:hover, &:focus, &:focus-within ${p => p.$forceShowActions && ', &'} {
      .cdf-action-container {
        opacity: 1;
        pointer-events: inherit;
      }
    }
  }
`;

const ActionsContainer = styled(Stack).attrs({ className: 'cdf-action-container', gap: 'small' })`
  position: absolute;
  right: ${p => spaceSizeToPixels(p.theme, 'large')}px;
  top: 50%;
  transform: translateY(-50%);

  transition: all ${p => p.theme.motionDurationMid} ${p => p.theme.motionEaseInOut};
`;

export interface CustomerListItemProps {
  isActive: boolean;
  customer: Customer;
  onClick(): void;
  actions?: React.ReactNode[] | ((customer: Customer) => React.ReactNode[]);
  forceShowActions?: boolean;
}

export default function CustomerListItem({ isActive, customer, onClick, actions, forceShowActions }: CustomerListItemProps): ReactElement {
  const { token } = theme.useToken();
  const { t } = useTranslation();

  return (
    <ListItem $isActive={isActive} $forceShowActions={forceShowActions} {...getInteractiveElementProps(onClick)}>
      <StyledMeta
        avatar={<Avatar icon={<UserOutlined />} />}
        title={<Text ellipsis aria-label={t('customer.customerName')}>{getCustomerFullName(customer)}</Text>}
        description={
          <Row wrap={false} gutter={spaceSizeToPixels(token, 'small')}>
            <Col flex="150px">
              <CustomerContact
                type="phone"
                value={(getCustomerPrimaryPhone(customer.phoneNumbers) || 'n/a') + (customer.phoneNumbers.length > 1 ? ', ...' : '')} tooltip={concatCustomerPhones(customer.phoneNumbers, '\n') || 'n/a'}
                aria-label={t('customer.customerPhoneNumbers')}
              />
            </Col>
            <Col flex="auto">
              <CustomerContact
                type="email"
                value={customer.email || 'n/a'}
                aria-label={t('customer.customerEmailAddress')}
              />
            </Col>
          </Row>
        }
      />
      {
        actions &&
        <ActionsContainer className="cdf-action-container">
          {
            typeof actions === 'function'
              ? actions(customer)
              : actions
          }
        </ActionsContainer>
      }
    </ListItem>
  );
}