import { LoadingOutlined } from '@ant-design/icons';
import { Result } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Center = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const Loading = () => {
  const { t } = useTranslation();

  return (
    <Center>
      <Result
        icon={<LoadingOutlined />}
        title={t('misc.pleaseWait') + '...'}
      />
    </Center>
  );
};

export default Loading;
