import { SpaceSize } from 'antd/es/space';
import React, { HTMLAttributes, PropsWithChildren, ReactElement } from 'react';
import styled from 'styled-components';
import { spaceSizeToPixels } from '../../utils/antDesignUtils';
import { createShouldForwardProp } from '../../utils/styledUtils';

type Alignment = 'start' | 'end' | 'center' | 'space-between' | 'space-around' | 'space-evenly' | 'baseline' | 'stretch';

export interface StackProps extends HTMLAttributes<HTMLDivElement> {
  inline?: boolean;
  vertical?: boolean;
  wrap?: boolean;
  horizontalAlign?: Alignment;
  verticalAlign?: Alignment;
  gap?: NonNullable<SpaceSize> | [NonNullable<SpaceSize>, NonNullable<SpaceSize>];
  itemProps?: {
    grow?: number;
    shrink?: number;
  }
}

const Container = styled.div.withConfig({ shouldForwardProp: createShouldForwardProp(['inline', 'vertical', 'wrap', 'horizontalAlign', 'verticalAlign', 'gap', 'itemProps']) })<StackProps>`
  display: ${p => p.inline ? 'inline-flex' : 'flex'};
  flex-direction: ${p => p.vertical ? 'column' : 'row'};
  flex-wrap: ${p => p.wrap ? 'wrap' : undefined};

  justify-content: ${p => p.vertical ? p.verticalAlign : p.horizontalAlign};
  align-items: ${p => p.vertical ? p.horizontalAlign : p.verticalAlign};
  gap: ${p => p.gap
    ? Array.isArray(p.gap)
      ? `${spaceSizeToPixels(p.theme, p.gap[1])}px ${spaceSizeToPixels(p.theme, p.gap[0])}px`
      : `${spaceSizeToPixels(p.theme, p.gap)}px`
    : 0
  };

  > * {
    flex: ${p => p.itemProps?.grow ?? 0} ${p => p.itemProps?.shrink ?? 0} auto;
    min-width: 1px;
    min-height: 1px;
    max-width: 100%;
    max-height: 100%;
  }

  max-width: 100%;
  max-height: 100%;
`;

export default function Stack(props: PropsWithChildren<StackProps>): ReactElement {

  return <Container {...props}>{props.children}</Container>;
}
