import { CloseOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { CustomerDataPage, CustomerListPage, CustomerListPageProps, CustomerPageProviders } from '@buzzeasy/customer-data-fragment-2';
import React, { ReactElement, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import useCustomerPageActions from '../../hooks/useCustomerPageActions';
import { AgentWorkspace } from '../../models/agentWorkspace';
import { GeneralizedChannel } from '../../models/channels';
import { Customer, defaultCustomer } from '../../models/customer';
import { getCustomerFullName } from '../../models/customer.helpers';
import { useGetCustomerByIdQuery } from '../../redux/apis/customerApi';
import emailConnectorApi from '../../redux/apis/emailConnectorApi';
import { InitiateEmailParams } from '../../redux/apis/emailHubApi';
import { InitiateCallParams } from '../../redux/apis/sipConnectorApi';
import { useAppDispatch } from '../../redux/store';
import AdhocOutboundModal, { AdhocOutboundModalProps } from '../presenters/AdhocOutboundModal';
import { BasePropsContext, ClfProps } from '../providers/BasePropsProvider';
import ChannelSelectionPage from './ChannelSelectionPage';
import WorkspaceSelectionPage from './WorkspaceSelectionPage';

const Grid = styled.div`
  height: 100%;
  display: grid;
  grid-auto-columns: minmax(1px, 1fr);
  grid-template-rows: 1fr;
  grid-auto-flow: column;

  > :not(:last-child) {
    border-right: ${p => p.theme.lineWidthBold}px ${p => p.theme.lineType} ${p => p.theme.colorBorder};
  }
`;

const PageContainer = styled.div`
  height: 100%;
  overflow: hidden;
`;

interface PageConfigurationStateBase {
  readonly state: string;
}

interface WorkspaceSelectionState extends PageConfigurationStateBase {
  readonly state: 'workspace-selection';
  selectedWorkspace: AgentWorkspace | null;
}

interface LookupState extends PageConfigurationStateBase {
  readonly state: 'lookup';
  readonly selectedWorkspace: AgentWorkspace | null;
  selectedCustomerId: string | null;
}

interface OutboundStateBase extends PageConfigurationStateBase {
  readonly state: 'initiate-outbound';
  readonly selectedWorkspace: AgentWorkspace | null;
  readonly selectedCustomerId: string;
  readonly mediaGroup: 'voice' | 'email';
  readonly outboundParams: { phoneNumber: string } | Pick<InitiateEmailParams, 'customerName' | 'customerEmailAddress'>;
  selectedChannelId: number | null;
}

interface VoiceOutboundState extends OutboundStateBase {
  readonly mediaGroup: 'voice';
  readonly outboundParams: { phoneNumber: string };
}

interface EmailOutboundState extends OutboundStateBase {
  readonly mediaGroup: 'email';
  readonly outboundParams: Pick<InitiateEmailParams, 'customerName' | 'customerEmailAddress'>;
}

type PageConfigurationState = WorkspaceSelectionState | LookupState | VoiceOutboundState | EmailOutboundState;

interface PageManagerProps {
  onInitiateCall(params: Omit<InitiateCallParams, 'workItemId'>): Promise<boolean>;
  onInitiateEmail(params: InitiateEmailParams): Promise<boolean>;
  onRunScript(customerId: string): void;
}
/**
 * Collects and stores data required to initiate outbound conversations from the displayed pages while making sure the pages are displayed in the correct order.
 */
export default function PageManager({ onInitiateCall, onInitiateEmail, onRunScript }: PageManagerProps): ReactElement {
  const baseProps = useContext(BasePropsContext) as ClfProps;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [pageConfig, setPageConfig] = useState<PageConfigurationState>({ state: 'workspace-selection', selectedWorkspace: null });
  const [focusedCustomerId, setFocusedCustomerId] = useState<Customer['customerId'] | null>(null);
  const [displayedAdhocOutboundModal, setDisplayedAdhocOutboundModal] = useState<AdhocOutboundModalProps['mediaGroup'] | null>(null);

  const selectedCustomerId = 'selectedCustomerId' in pageConfig ? pageConfig.selectedCustomerId : null;
  
  const { data: foundCustomer = defaultCustomer } = useGetCustomerByIdQuery(selectedCustomerId ?? '', { skip: !selectedCustomerId || selectedCustomerId === 'CREATE' });

  const setSelectedCustomerId = useCallback(
    (cId: string | null) => setPageConfig(curr => ({ ...curr as LookupState, selectedCustomerId: cId })),
    [],
  );

  const goBackToListOnly = useCallback(
    () => setSelectedCustomerId(null),
    [setSelectedCustomerId],
  );

  const handleInitiateCall = useCallback(
    (phoneNumber: string, customerId?: string) => {
      if (pageConfig.state !== 'lookup')
        return;

      // cleanup for phone dropdown
      setFocusedCustomerId(null);

      // if workspace has a channel skip selection page
      if (pageConfig.selectedWorkspace?.voiceChannelId) {
        onInitiateCall({
          channelId: pageConfig.selectedWorkspace?.voiceChannelId ?? 0,
          toNumber: phoneNumber,
          toCustomer: customerId || pageConfig.selectedCustomerId || null,
          musicOnHoldBundleName: pageConfig.selectedWorkspace?.musicOnHoldBundleName ?? null,
        });
      }
      else {
        setPageConfig(curr => ({
          ...curr as LookupState,
          selectedCustomerId,
          state: 'initiate-outbound',
          mediaGroup: 'voice',
          outboundParams: { phoneNumber },
          selectedChannelId: null,
        } as VoiceOutboundState));
      }
    },
    [onInitiateCall, pageConfig, selectedCustomerId],
  );

  const handleInitiateEmail = useCallback(
    async (customerEmailAddress: string, customerId?: string, customerName?: string) => {
      if (pageConfig.state !== 'lookup')
        return;

      // cleanup for phone dropdown
      setFocusedCustomerId(null);

      // if workspace has a channel skip selection page
      if (pageConfig.selectedWorkspace?.emailChannelId) {
        const emailChannelResult = await dispatch(emailConnectorApi.endpoints.getEmailChannelById.initiate(pageConfig.selectedWorkspace?.emailChannelId ?? 0));
        const emailChannel = 'data' in emailChannelResult ? emailChannelResult.data : undefined;

        onInitiateEmail({
          channelId: pageConfig.selectedWorkspace?.emailChannelId ?? 0,
          channelName: emailChannel?.channelName ?? '',
          channelEmailAddress: emailChannel?.defaultUser ?? '',
          customerId: customerId || pageConfig.selectedCustomerId || null,
          customerName: customerName || getCustomerFullName(foundCustomer),
          customerEmailAddress,
        });
      }
      else {
        setPageConfig(curr => ({
          ...curr as LookupState,
          selectedCustomerId,
          state: 'initiate-outbound',
          mediaGroup: 'email',
          outboundParams: {
            customerName: customerName || getCustomerFullName(foundCustomer),
            customerEmailAddress,
          },
          selectedChannelId: null,
        } as EmailOutboundState));
      }
    },
    [dispatch, foundCustomer, onInitiateEmail, pageConfig, selectedCustomerId],
  );

  const runScript = useCallback(
    (customerId: string) => onRunScript(customerId),
    [onRunScript],
  );

  const { customerDataActionsFactory, customerListActionsFactory } = useCustomerPageActions(
    !!pageConfig.selectedWorkspace?.scriptingConfig,
    handleInitiateCall,
    handleInitiateEmail,
    runScript,
    setSelectedCustomerId,
    setFocusedCustomerId
  );

  const handleChannelSelected = useCallback(
    async ({ id, name, identifier }: GeneralizedChannel) => {
      if (pageConfig.state !== 'initiate-outbound')
        return false;

      // cleanup for phone dropdown
      setFocusedCustomerId(null);

      switch (pageConfig.mediaGroup) {
        case 'voice':
          return onInitiateCall({
            channelId: id,
            toNumber: pageConfig.outboundParams.phoneNumber,
            toCustomer: pageConfig.selectedCustomerId,
            musicOnHoldBundleName: pageConfig.selectedWorkspace?.musicOnHoldBundleName ?? null,
          });
        case 'email':
          return onInitiateEmail({
            channelId: id,
            channelName: name,
            channelEmailAddress: identifier,
            customerId: pageConfig.selectedCustomerId,
            customerName: pageConfig.outboundParams.customerName,
            customerEmailAddress: pageConfig.outboundParams.customerEmailAddress,
          });
      }
    },
    [onInitiateCall, onInitiateEmail, pageConfig],
  );

  const handleChannelSelectionClosed = useCallback(
    () => setPageConfig((curr) => ({
      state: 'lookup',
      selectedWorkspace: (curr as OutboundStateBase).selectedWorkspace,
      selectedCustomerId: (curr as OutboundStateBase).selectedCustomerId,
    })),
    [],
  );

  const customerListFloatingActions = useMemo<CustomerListPageProps['floatingActions']>(
    () => [
      {
        icon: <PhoneOutlined />,
        onClick: () => setDisplayedAdhocOutboundModal('voice'),
        tooltip: t('adhoc.startAdHocCall'),
      },
      {
        icon: <MailOutlined />,
        onClick: () => setDisplayedAdhocOutboundModal('email'),
        tooltip: t('adhoc.startAdHocEmail'),
      },
    ],
    [t],
  );

  const handleAdhocModalInitiate = useCallback(
    (identifier: string) => {
      setDisplayedAdhocOutboundModal(null);

      switch (displayedAdhocOutboundModal) {
        case 'voice':
          handleInitiateCall(identifier);
          break;
        case 'email':
          handleInitiateEmail(identifier);
          break;
      }
    },
    [displayedAdhocOutboundModal, handleInitiateCall, handleInitiateEmail],
  );

  const onWorkspaceSelected = useCallback(
    (selectedWorkspace: AgentWorkspace | null) => setPageConfig({ state: 'lookup', selectedWorkspace, selectedCustomerId: null }),
    []
  );

  const businessUnitId = pageConfig.selectedWorkspace?.businessUnitId ?? null;

  return (
    <Grid>
      {
        pageConfig.state === 'workspace-selection' &&
        <PageContainer>
          <WorkspaceSelectionPage
            onWorkspaceSelected={onWorkspaceSelected}
          />
        </PageContainer>
      }
      <CustomerPageProviders
        {...baseProps}
        businessUnitId={businessUnitId}
      >
        {
          pageConfig.state === 'lookup' &&
          <PageContainer>
            <CustomerListPage
              businessUnitId={businessUnitId}
              selectedCustomerId={selectedCustomerId}
              onCustomerSelected={setSelectedCustomerId}
              onCreate={() => setSelectedCustomerId('CREATE')}
              isCreateDisabled={!!selectedCustomerId}
              customerActions={customerListActionsFactory}
              focusedCustomerId={focusedCustomerId}
              floatingActions={customerListFloatingActions}
            />
          </PageContainer>
        }
        {
          (pageConfig.state === 'lookup' && selectedCustomerId) &&
          <PageContainer>
            <CustomerDataPage
              createNew={selectedCustomerId === 'CREATE'}
              customerId={selectedCustomerId}
              onCustomerCreated={(cId) => setSelectedCustomerId(cId)}
              onCancelCreate={goBackToListOnly}
              actions={customerDataActionsFactory}
              leftNavButtonProps={{ text: t('customerData.closeCustomerData'), icon: <CloseOutlined />, onClick: goBackToListOnly }}
            />
          </PageContainer>
        }
        {
          (pageConfig.state === 'initiate-outbound' && selectedCustomerId) &&
          <PageContainer>
            <CustomerDataPage
              customerId={pageConfig.selectedCustomerId}
              leftNavButtonProps={{ text: t('customerData.customerData') }}
            />
          </PageContainer>
        }
      </CustomerPageProviders>
      {
        pageConfig.state === 'initiate-outbound' &&
        <PageContainer>
          <ChannelSelectionPage
            businessUnitId={businessUnitId}
            mediaGroup={pageConfig.mediaGroup}
            onChannelSelected={handleChannelSelected}
            onCancel={handleChannelSelectionClosed}
          />
        </PageContainer>
      }
      {
        displayedAdhocOutboundModal &&
        <AdhocOutboundModal
          isOpen={true}
          mediaGroup={displayedAdhocOutboundModal}
          onInitiate={handleAdhocModalInitiate}
          onCancel={() => setDisplayedAdhocOutboundModal(null)}
        />
      }
    </Grid>
  );
}