import { Spin } from 'antd';
import React, { PropsWithChildren, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

export interface ContentWithLoadingProps {
  isLoading: boolean;
}

export default function ContentWithLoading({ children, isLoading }: PropsWithChildren<ContentWithLoadingProps>): ReactElement {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <Spin tip={t('misc.loading') + '...'}>
        {children}
      </Spin>
    );
  }

  return <>{children}</>;
}