import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  // https://github.com/ant-design/ant-design/tree/master/components

  .ac-pushButton {
    outline: none;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    background-color: transparent;
    border: ${p => p.theme.lineWidth}px ${p => p.theme.lineType} transparent;
    cursor: pointer;
    transition: all ${p => p.theme.motionDurationMid} ${p => p.theme.motionEaseInOut};
    user-select: none;
    touch-action: manipulation;
    line-height: ${p => p.theme.lineHeight};
    color: ${p => p.theme.colorText};

    font-size: ${p => p.theme.fontSize}px;
    height: ${p => p.theme.controlHeight}px;
    padding: ${p => Math.max(0, (p.theme.controlHeight - p.theme.fontSize * p.theme.lineHeight) / 2 - p.theme.lineWidth)}px ${p => p.theme.paddingContentHorizontal - p.theme.lineWidth}px;
    border-radius: ${p => p.theme.borderRadius}px;

    background-color: ${p => p.theme.colorBgContainer};
    border-color: ${p => p.theme.colorBorder};
    box-shadow: 0 ${p => p.theme.controlOutlineWidth}px 0 ${p => p.theme.controlTmpOutline};

    &:hover {
      color: ${p => p.theme.colorPrimaryHover};
      border-color: ${p => p.theme.colorPrimaryHover};
    }

    > span {
      display: inline-block;
    }
  }

  .ac-textInput, .ac-numberInput, .ac-dateInput {
    font-family: ${p => p.theme.fontFamily};

    box-sizing: border-box;
    margin: 0;
    padding: ${p => Math.max(Math.round(((p.theme.controlHeight - p.theme.fontSize * p.theme.lineHeight) / 2) * 10) / 10 - p.theme.lineWidth, 3)}px ${p => p.theme.paddingSM - p.theme.lineWidth}px;
    color: ${p => p.theme.colorText};
    font-size: ${p => p.theme.fontSize}px;
    line-height: ${p => p.theme.lineHeight};
    background-color: ${p => p.theme.colorBgContainer};
    background-image: none;
    border-width: ${p => p.theme.lineWidth}px;
    border-style: ${p => p.theme.lineType};
    border-color: ${p => p.theme.colorBorder};
    border-radius: ${p => p.theme.borderRadius}px;
    transition: all ${p => p.theme.motionDurationMid};

    &:hover {
      border-color: ${p => p.theme.colorPrimaryHover};
      border-inline-end-width: ${p => p.theme.lineWidth}px;
    }

    :focus {
      border-color: ${p => p.theme.colorPrimaryHover};
      box-shadow: 0 0 0 ${p => p.theme.controlOutlineWidth}px ${p => p.theme.controlOutline};
      border-inline-end-width: ${p => p.theme.lineWidth}px;
      outline: 0;
    }
  }

  .ac-toggleInput {
    input {
      display: block;
      width: ${p => p.theme.controlInteractiveSize}px;
      height: ${p => p.theme.controlInteractiveSize}px;
      direction: ltr;
      background-color: ${p => p.theme.colorBgContainer};
      border: ${p => p.theme.lineWidth}px ${p => p.theme.lineType} ${p => p.theme.colorBorder};
      border-radius: ${p => p.theme.borderRadiusSM}px;
      border-collapse: separate;
      transition: all ${p => p.theme.motionDurationSlow};
    }
  }

  .ac-choiceSetInput-expanded {
    input {
      display: block;
      width: ${p => p.theme.controlInteractiveSize}px;
      height: ${p => p.theme.controlInteractiveSize}px;
      background-color: ${p => p.theme.colorBgContainer};
      border: ${p => p.theme.lineWidth}px ${p => p.theme.lineType} ${p => p.theme.colorBorder};
      border-radius: 50%;
      transition: all ${p => p.theme.motionDurationMid};
    }
  }

  div[aria-label="Prev"] img, div[aria-label="Next"] img {
    box-sizing: border-box;
    
    outline: none;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    background-color: transparent;
    border: ${p => p.theme.lineWidth}px ${p => p.theme.lineType} transparent;
    cursor: pointer;
    transition: all ${p => p.theme.motionDurationMid} ${p => p.theme.motionEaseInOut};
    user-select: none;
    touch-action: manipulation;
    line-height: ${p => p.theme.lineHeight};
    color: ${p => p.theme.colorText};
    fill: ${p => p.theme.colorText};

    height: ${p => p.theme.controlHeight}px;
    padding: ${p => p.theme.controlHeight / 8}px;
    border-radius: 6px;

    svg {
      color: inherit;
      fill: inherit;
    }

    color: ${p => p.theme.colorTextLightSolid};
    background-color: ${p => p.theme.colorPrimary};

    box-shadow: 0 ${p => p.theme.controlOutlineWidth}px 0 ${p => p.theme.controlTmpOutline};

    &:hover, &:active {
      color: ${p => p.theme.colorTextLightSolid};
      fill: ${p => p.theme.colorTextLightSolid};
      background-color: ${p => p.theme.colorPrimaryHover};
    }
  }
`;

export default GlobalStyles;