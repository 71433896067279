import { SpaceSize } from 'antd/es/space';
import React, { PropsWithChildren, ReactElement } from 'react';
import styled from 'styled-components';
import { spaceSizeToPixels } from '../../utils/antDesignUtils';
import { createShouldForwardProp } from '../../utils/styledUtils';

const FrameDiv = styled.div.withConfig({ shouldForwardProp: createShouldForwardProp(['horizontalPadding', 'verticalPadding']) })<{ horizontalPadding: NonNullable<SpaceSize>; verticalPadding: NonNullable<SpaceSize> }>`
  padding: ${p => spaceSizeToPixels(p.theme, p.verticalPadding)}px ${p => spaceSizeToPixels(p.theme, p.horizontalPadding)}px;
`;

export interface FrameProps extends Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'ref'> {
  padding?: SpaceSize;
  horizontalPadding?: SpaceSize;
  verticalPadding?: SpaceSize;
}

export default function Frame({ children, padding, horizontalPadding, verticalPadding, ...divProps }: PropsWithChildren<FrameProps>): ReactElement {

  return (
    <FrameDiv {...divProps} horizontalPadding={horizontalPadding ?? padding ?? 'small'} verticalPadding={verticalPadding ?? padding ?? 'small'}>
      {children}
    </FrameDiv>
  );
}