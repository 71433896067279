import { GlobalToken } from 'antd';

export const getHostConfig = (theme: GlobalToken) => ({
  supportsInteractivity: true,
  fontFamily: theme.fontFamily,
  fontSizes: {
    small: theme.fontSizeSM,
    default: theme.fontSize,
    medium: theme.fontSizeLG,
    large: theme.fontSizeXL,
    extraLarge: theme.fontSizeXL,
  },
  fontWeights: {
    lighter: 200,
    default: 400,
    bolder: 600,
  },
  imageSizes: {
    small: 40,
    medium: 80,
    large: 160,
  },
  actions: {
    maxActions: 5,
    buttonSpacing: 10,
    showCard: {
      actionMode: 'Inline',
      inlineTopMargin: 16,
      style: 'emphasis',
    },
    actionsOrientation: 'Horizontal',
    actionAlignment: 'Left',
    spacing: 'Default',
    preExpandSingleShowCardAction: false,
  },
  adaptiveCard: {
    allowCustomStyle: false,
  },
  imageSet: {
    imageSize: 'Medium',
    maxImageHeight: 100,
  },
  factSet: {
    title: {
      size: 'Default',
      color: 'Default',
      isSubtle: false,
      weight: 'Bolder',
      warp: true,
    },
    value: {
      size: 'Default',
      color: 'Default',
      isSubtle: false,
      weight: 'Default',
      warp: true,
    },
    spacing: 10,
  },
  choiceSetInputValueSeparator: ',',
  spacing: {
    small: theme.sizeXXS,
    default: theme.sizeXS,
    medium: theme.sizeMD,
    large: theme.sizeXL,
    extraLarge: theme.sizeXXL,
    padding: theme.sizeSM,
  },
  separator: {
    lineThickness: theme.lineWidth,
    lineColor: theme.colorBorder,
  },
  containerStyles: {
    default: {
      foregroundColors: {
        default: {
          default: theme.colorText,
          subtle: theme.colorTextSecondary,
        },
        dark: {
          default: theme.colorTextBase,
          subtle: theme.colorText,
        },
        light: {
          default: theme.colorTextTertiary,
          subtle: theme.colorTextQuaternary,
        },
        accent: {
          default: theme.colorPrimary,
          subtle: theme.colorPrimaryTextActive,
        },
        good: {
          default: theme.colorSuccess,
          subtle: theme.colorSuccessTextActive,
        },
        warning: {
          default: theme.colorWarning,
          subtle: theme.colorWarningTextActive,
        },
        attention: {
          default: theme.colorError,
          subtle: theme.colorErrorTextActive,
        },
      },
      backgroundColor: theme.colorBgContainer,
    },
    emphasis: {
      foregroundColors: {
        default: {
          default: theme.colorText,
          subtle: theme.colorTextSecondary,
        },
        dark: {
          default: theme.colorTextBase,
          subtle: theme.colorText,
        },
        light: {
          default: theme.colorTextTertiary,
          subtle: theme.colorTextQuaternary,
        },
        accent: {
          default: theme.colorPrimary,
          subtle: theme.colorPrimaryTextActive,
        },
        good: {
          default: theme.colorSuccess,
          subtle: theme.colorSuccessTextActive,
        },
        warning: {
          default: theme.colorWarning,
          subtle: theme.colorWarningTextActive,
        },
        attention: {
          default: theme.colorError,
          subtle: theme.colorErrorTextActive,
        },
      },
      backgroundColor: theme.colorFill,
    },
  },
});