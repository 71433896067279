import React from 'react';
import AdditionalDetails from '../presenters/AdditionalDetails';
import ExpandedAdditionalDetails from '../presenters/ExpandedAdditionalDetails';

export interface IAdditionalDetailsContainerProps {
  additionalDetails: Record<string, string>;
  isExpanded: boolean;
  onShowLess(): void;
  onShowMore(): void;
}

const AdditionalDetailsContainer: React.FC<IAdditionalDetailsContainerProps> = ({ onShowLess, onShowMore, additionalDetails, isExpanded }) => {

  return (
    <>
      {isExpanded ?
        <ExpandedAdditionalDetails additionalDetails={additionalDetails} onShowLess={onShowLess} />
        :
        <AdditionalDetails additionalDetails={additionalDetails} onShowMore={onShowMore} />
      }
    </>
  );
};

export default AdditionalDetailsContainer;
