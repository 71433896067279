import { EditOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Space, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface CustomerProps {
  customerName: string;
  onEdit(): void;
  showEditButton: boolean
}

const Customer: React.FC<CustomerProps> = ({ customerName, showEditButton, onEdit }) => {
  const { t } = useTranslation();

  return (
    <Space align='center' size='middle'>
      <Avatar icon={<UserOutlined />} />
      <Typography.Title level={4} style={{ margin: 0 }}>{customerName}</Typography.Title>
      {showEditButton &&
        <Typography.Link onClick={onEdit}>
          <EditOutlined />&nbsp;{t('misc.edit')}
        </Typography.Link>
      }
    </Space>
  );
};

export default Customer;
