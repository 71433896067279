import { CloseOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { Alert, Button } from 'antd';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce';
import { EmailChannel, GeneralizedChannel, VoiceChannel } from '../../models/channels';
import { useListEmailChannelsQuery } from '../../redux/apis/emailConnectorApi';
import { useListVoiceChannelsQuery } from '../../redux/apis/sipConnectorApi';
import Frame from '../layouts/Frame';
import ContentWithLoading from '../presenters/ContentWithLoading';
import ListWithSearchAndSelect, { ListItem } from '../presenters/ListWithSearchAndSelect';

const RootContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  min-height: 1px;
  flex-grow: 1;
`;

function mapVoiceChannel(vc: VoiceChannel): GeneralizedChannel {
  return {
    id: vc.id,
    name: vc.name,
    identifier: vc.phoneNumber ?? '',
    isDefault: vc.isDefault,
    businessUnitId: vc.businessUnitId,
  };
}

function mapEmailChannel(ec: EmailChannel): GeneralizedChannel {
  return {
    id: Number.parseInt(ec.channelId, 10),
    name: ec.channelName,
    identifier: ec.defaultUser,
    isDefault: ec.isDefault,
    businessUnitId: ec.businessUnitId,
  };
}

function getIcon(mediaGroup: 'voice' | 'email') {
  switch (mediaGroup) {
    case 'voice':
      return <PhoneOutlined />;
    case 'email':
      return <MailOutlined />;
  }
}

export interface ChannelSelectionPageProps {
  businessUnitId: string | null;
  mediaGroup: 'voice' | 'email';
  onChannelSelected(channel: GeneralizedChannel): Promise<boolean>;
  onCancel(): void;
}

export default function ChannelSelectionPage({ businessUnitId, mediaGroup, onChannelSelected, onCancel }: ChannelSelectionPageProps): ReactElement {
  const [isInitiating, setIsInitiating] = useState(false);
  const { t } = useTranslation();

  const { data: voiceChannels, isLoading: isVoiceChannelsLoading } = useListVoiceChannelsQuery(undefined, { skip: mediaGroup !== 'voice' });
  const { data: emailChannels, isLoading: isEmailChannelsLoading } = useListEmailChannelsQuery(undefined, { skip: mediaGroup !== 'email' });

  const generalizedChannelsInBusinessUnit = useMemo(
    () => {
      let channels;

      switch (mediaGroup) {
        case 'voice':
          channels = voiceChannels?.map(mapVoiceChannel) ?? [];
          break;
        case 'email':
          channels = emailChannels?.map(mapEmailChannel) ?? [];
          break;
      }

      return channels.filter(c => c.businessUnitId === businessUnitId);
    },
    [businessUnitId, emailChannels, mediaGroup, voiceChannels],
  );

  const handleSelect = useCallback(
    async (selectedChannel: GeneralizedChannel) => {
      if (selectedChannel) {
        setIsInitiating(true);

        const success = await onChannelSelected(selectedChannel);

        if (!success) {
          setIsInitiating(false);
        }
      }
    },
    [onChannelSelected],
  );

  // automatically select channel if there is only one
  useEffect(
    () => {
      if (!isInitiating && generalizedChannelsInBusinessUnit.length === 1)
        handleSelect(generalizedChannelsInBusinessUnit[0]);
    },
    [generalizedChannelsInBusinessUnit, handleSelect, isInitiating],
  );

  const [searchText, setSearchText] = useState('');
  const [debouncedSearchText] = useDebounce(searchText, 800);

  const channels = useMemo(
    () => {
      const filter = debouncedSearchText.toLocaleLowerCase();

      return generalizedChannelsInBusinessUnit
        .filter(c => c.name.toLocaleLowerCase().includes(filter))
        .sortBy(c => c.name)
        .sortBy(c => !c.isDefault);
    },
    [debouncedSearchText, generalizedChannelsInBusinessUnit],
  );

  const defaultChannel = useMemo(
    () => channels.find(c => c.isDefault),
    [channels],
  );

  return (
    <RootContainer inert={isInitiating ? '' : undefined}>
      <div role="navigation">
        <Button type="link" icon={<CloseOutlined />} onClick={onCancel}>{t('misc.cancel')}</Button>
      </div>
      <ContentContainer>
        <ContentWithLoading isLoading={isVoiceChannelsLoading || isEmailChannelsLoading}>
          {
            (generalizedChannelsInBusinessUnit.length === 0)
              ? (
                <Frame horizontalPadding="middle" verticalPadding="small">
                  <Alert type="error" showIcon message={t('channelSelection.noXChannelsConfiguredMessage', { value: t('channelTypes.', { context: mediaGroup }) })} />
                </Frame>
              )
              : (
                <ListWithSearchAndSelect
                  hintText={t('channelSelection.channelSelectHint')}
                  searchPlaceholder={t('channelSelection.searchChannels')}
                  searchLoading={searchText !== debouncedSearchText}
                  searchText={searchText}
                  onSearchTextChange={setSearchText}

                  dataSource={channels}
                  defaultItem={defaultChannel}
                  renderItem={(channel, selected) => <ListItem selected={selected} icon={getIcon(mediaGroup)} title={channel.name} description={channel.identifier} />}

                  selectButtonText={t('channelSelection.startConversation')}
                  onSelect={handleSelect}
                />
              )
          }
        </ContentWithLoading>
      </ContentContainer>
    </RootContainer>
  );
}