import { Select, SelectProps } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Filter, FilterType, FilterWithType, MediaType } from '../../models/typings';
import { addFilter, removeFilter, selectFilterByFilterType } from '../../redux/activityHistorySlice';
import { useAppDispatch } from '../../redux/store';

interface IProps {
  filterType: FilterType;
  title: string;
}

const BaseFilterContainer: FC<IProps> = ({ filterType, title }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const filter = useSelector(selectFilterByFilterType(filterType));

  const filterValueToLabel = (value: string | MediaType): string | undefined => {
    if (filterType === 'filterByMediaType') {
      return t('media.', { context: value as MediaType });
    }
    if (filterType === 'filterByChannel' || filterType === 'filterByTag') {
      return value as string;
    }
    return;
  };

  const options: SelectProps['options'] =
    filter.predefinedFilters.map((f: Filter) => {
      return {
        value: f.id,
        label: filterValueToLabel(f.value as string),
      };
    });

  const onSelect: SelectProps['onSelect'] = useCallback(
    (_: unknown, option: DefaultOptionType) => {
      const filterPayload: FilterWithType = {
        value: option.label as string,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        id: option.value!.toString(),
        type: filterType,
      };
      dispatch(addFilter(filterPayload));
    },
    [dispatch, filterType],
  );

  const onDeselect: SelectProps['onDeselect'] = useCallback(
    (value: string) => {
      const filterPayload: FilterWithType = { value, id: value, type: filterType };
      dispatch(removeFilter(filterPayload));
    },
    [dispatch, filterType],
  );

  return <Select
    placeholder={title}
    mode='tags'
    maxTagCount='responsive'
    options={options}
    loading={false}
    onSelect={onSelect}
    onDeselect={onDeselect}
    style={{ width: 147 }}
  />;
};

export default BaseFilterContainer;