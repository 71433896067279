import { RightOutlined } from '@ant-design/icons';
import { DownOutlined } from '@ant-design/icons/lib/icons';
import { Space } from 'antd';
import Text from 'antd/es/typography/Text';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface IProps {
  isOpen: boolean
  onExpand(): void
}

const HeaderContainer = styled.div`
  padding: ${p => p.theme.paddingXS}px ${p => p.theme.paddingSM}px;
  background-color: ${p => p.theme.colorFillAlter};
  border-bottom: ${p => p.theme.lineWidth}px solid ${p => p.theme.colorBorder};
  cursor: pointer;
`;

const MainHeader: FC<IProps> = ({ isOpen, onExpand }) => {
  const { t } = useTranslation();

  return <HeaderContainer onClick={onExpand}>
    <Space>
      {isOpen ? <DownOutlined onClick={onExpand} /> : <RightOutlined onClick={onExpand} />}
      <Text>{t('filterHeaderText')}</Text>
    </Space>
  </HeaderContainer>;
};

export default MainHeader;