import { MailOutlined, MessageOutlined, MobileOutlined, PhoneOutlined, QuestionOutlined } from '@ant-design/icons';
import { Alert, Spin, Timeline } from 'antd';
import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Activity, MediaType } from '../../models/typings';
import { selectActivitiesStatus, selectAllFilters, selectAppState, selectChannelsStatus } from '../../redux/activityHistorySlice';
import CenterContentLayout from '../layouts/CenterContentLayout';
import ActivityDetailsContainer from './ActivityDetailsContainer';
import InfiniteScrollContainer from './InfiniteScrollContainer';

interface IProps {
  activities: Activity[];
  hideSensitiveAgentInformation: boolean;
}

const createMediaTypeIcon = (type: MediaType) => {
  switch (type) {
    case 'voice':
      return <PhoneOutlined />;
    case 'webChat':
      return <MessageOutlined />;
    case 'email':
      return <MailOutlined />;
    case 'facebook':
      return <MessageOutlined />;
    case 'whatsApp':
      return <MessageOutlined />;
    case 'viber':
      return <MessageOutlined />;
    case 'instagram':
      return <MessageOutlined />;
    case 'infobip_sms':
      return <MobileOutlined />;
    case 'unknown':
      return <QuestionOutlined />;
  }
};

const TimelineContainer: FC<IProps> = ({ activities, hideSensitiveAgentInformation }) => {
  const appState = useSelector(selectAppState);
  const activitiesStatus = useSelector(selectActivitiesStatus);
  const channelsStatus = useSelector(selectChannelsStatus);
  const filters = useSelector(selectAllFilters);

  const loading = (channelsStatus === 'pending' || activitiesStatus === 'pending') && appState !== 'loadingMore';
  const init = appState === 'init';
  const rejected = activitiesStatus === 'rejected' || channelsStatus === 'rejected';
  const loaded = activitiesStatus === 'fulfilled' && channelsStatus === 'fulfilled';
  const nothingToDisplay = (appState === 'fetchActivities' || appState === 'fetchingActivities') && activities.length === 0 && filters.length === 0;
  const loadingMore = appState === 'loadingMore';

  const getFormattedDate = (dateTime: string) => {
    const date = dateTime.slice(0, 10);
    const time = new Date(dateTime).toLocaleTimeString('en-GB');
    return <>
      <div>{date}</div>
      <div>{time}</div>
    </>;
  };

  const timelineItems = useMemo(
    () => {
      const items = activities.map((a) => {
        const item = {
          label: getFormattedDate(a.date),
          dot: createMediaTypeIcon(a.mediaType),
          children: <ActivityDetailsContainer activity={a} hideSensitiveAgentInformation={hideSensitiveAgentInformation} />,
        };
        return item;
      });
      return items;
    },
    [activities, hideSensitiveAgentInformation],
  );

  let content;

  if (init || loading || nothingToDisplay) {
    content = <CenterContentLayout>
      <Spin />
    </CenterContentLayout>;
  }
  else if (rejected) {
    content = <CenterContentLayout>
      <Alert
        message='Something went wrong.'
        type='error'
        showIcon
      />
    </CenterContentLayout>;
  }
  else if (loaded || loadingMore) {
    if (activities.length === 0 && filters.length > 0) {
      content = <CenterContentLayout>
        <Alert
          message='No matching search results'
          type='info'
          showIcon
        />
      </CenterContentLayout>;
    }
    else {
      content = <InfiniteScrollContainer>
        <Timeline
          mode='left'
          items={timelineItems}
        />
      </InfiniteScrollContainer>;
    }
  }

  return <>
    {content}
  </>;
};

export default TimelineContainer;