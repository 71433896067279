import { createApi } from '@reduxjs/toolkit/query/react';
import { Customer } from '../../models/customer';
import { apiBaseQuery } from '../helpers';

const expandQuery = '$expand=PhoneNumbers,ExternalProfiles,CustomFields';
const selectQuery = '$select=CustomerId,BusinessUnitId,FirstName,MiddleName,LastName,Email,PhoneNumbers,ExternalProfiles,CustomFields';

const customerApi = createApi({
  reducerPath: 'customer',
  tagTypes: ['Customer', 'CustomerList'],
  baseQuery: apiBaseQuery('/customer-manager/v3'),
  endpoints: (builder) => ({
    getCustomerById: builder.query<Customer, string>({
      query: (customerId) => `/customers(${customerId})?${expandQuery}&${selectQuery}`,
      providesTags: (_, __, customerId) => [{ type: 'Customer', id: customerId }],
    }),
  }),
});

export default customerApi;

export const {
  useGetCustomerByIdQuery,
} = customerApi;