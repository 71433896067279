import { theme } from 'antd';
import React, { PropsWithChildren, ReactElement } from 'react';
import { ThemeProvider } from 'styled-components';

export default function StyledThemeProvider({ children }: PropsWithChildren): ReactElement {
  const { token: themeTokens } = theme.useToken();

  return (
    <ThemeProvider theme={themeTokens}>
      {children}
    </ThemeProvider>
  );
}