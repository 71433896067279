import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import React, { FC, useCallback } from 'react';
import { removeAllFiltersByType, removeFiltersAndAdd } from '../../redux/activityHistorySlice';
import { useAppDispatch } from '../../redux/store';

const { RangePicker } = DatePicker;

const DateFilterContainer: FC = () => {
  const dispatch = useAppDispatch();

  const onCalendarChange: RangePickerProps['onCalendarChange'] = useCallback(
    (_: unknown, dateStrings: [string, string]) => {
      const startDate = new Date(dateStrings[0]).toUTCString();
      const endDate = new Date(dateStrings[1].concat('T23:59:59')).toUTCString();
      const filter = `${startDate} - ${endDate}`;

      if (!dateStrings.includes('')) {
        dispatch(removeFiltersAndAdd({ id: filter, type: 'filterByDate', value: filter }));
      }
      else {
        dispatch(removeAllFiltersByType('filterByDate'));
      }
    },
    [dispatch],
  );

  return <>
    <RangePicker onCalendarChange={onCalendarChange} />
  </>;
};


export default DateFilterContainer;