import { createApi } from '@reduxjs/toolkit/query/react';
import { VoiceChannel } from '../../models/channels';
import { apiBaseQuery, post } from '../helpers';

export interface InitiateCallParams {
  channelId: number;
  toNumber: string;
  toCustomer: string | null;
  workItemId: string;
  musicOnHoldBundleName: string | null;
}

export interface InitiateCallResult {
  isSuccess: boolean;
  conversationId: string;
  errorType: string;
  reasonCode: string;
}

const sipConnectorApi = createApi({
  reducerPath: 'sipConnector',
  tagTypes: [],
  baseQuery: apiBaseQuery('/sipconnector'),
  endpoints: (builder) => ({
    listVoiceChannels: builder.query<VoiceChannel[], void>({
      query: () => '/channel-providers/voice/configurations',
    }),
    initiateCall: builder.mutation<InitiateCallResult, InitiateCallParams>({
      query: (params) => post('/api/v1/Call/initiate', params),
    }),
  }),
});

export default sipConnectorApi;

export const {
  useListVoiceChannelsQuery,
  useInitiateCallMutation,
} = sipConnectorApi;