import React, { PropsWithChildren, ReactElement } from 'react';
import { ContactLookupFragmentProps } from '../..';
import BasePropsProvider from './BasePropsProvider';
import StoreProvider from './StoreProvider';
import StyledThemeProvider from './StyledThemeProvider';
import TranslationProvider from './TranslationProvider';

export default function Providers(props: PropsWithChildren<ContactLookupFragmentProps>): ReactElement {

  return (
    <BasePropsProvider {...props}>
      <StoreProvider apiBaseUrl={props.apiBaseUrl} token={props.token}>
        <StyledThemeProvider>
          <TranslationProvider language={props.language}>
            {props.children}
          </TranslationProvider>
        </StyledThemeProvider>
      </StoreProvider>
    </BasePropsProvider>
  );
}