import ActivityHistoryFragment from '@buzzeasy/activity-history-fragment-2';
import React, { ReactElement, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { CampaignRecord } from '../../models/campaign';
import { Customer, defaultCustomer } from '../../models/customer';
import { PageProps } from '../../models/pages';
import { useRegisterCallbackMutation } from '../../redux/apis/campaignApi';
import { useCreateCustomerMutation, useGetCustomerByIdQuery, useUpdateCustomerMutation } from '../../redux/apis/customerApi';
import { selectAgentId } from '../../redux/configurationSlice';
import ContentWithLoading from '../presenters/ContentWithLoading';
import CustomerEdit from '../presenters/CustomerEdit';
import CustomerInfoWithActions, { CustomerInfoWithActionsProps } from '../presenters/CustomerInfoWithActions';
import PageNavBar from '../presenters/PageNavBar';
import { BasePropsContext } from '../providers/BasePropsProvider';

const RootContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  min-height: 1px;
  overflow: auto;
  flex-grow: 1;

  display: flex;
  flex-direction: column;
`;

const CustomerContainer = styled.div`
  min-height: 1px;
  flex: 0 0 auto;
  overflow: auto;
`;

const ActivityContainer = styled.div`
  min-height: 1px;
  flex: 1 1 auto;
  overflow: hidden;

  border-top: ${p => p.theme.lineWidth}px ${p => p.theme.lineType} ${p => p.theme.colorBorder};

  display: flex;
  flex-direction: column;

  > * {
    min-height: 1px;
    flex: 1 1 auto;
  }
`;

export interface CustomerDataPageProps extends PageProps {
  createNew?: boolean;
  customerId: string;
  actions?: CustomerInfoWithActionsProps['actions'];
  disableSchedule?: boolean;
  onCustomerCreated?(customerId: string): void;
  onCancelCreate?(): void;
}

/**
 * The React component that deals with presentation and management of a single customer
 * NOTE: This component needs to be placed inside the `CustomerPageProviders` component
 */
export default function CustomerDataPage({ createNew, customerId, onCustomerCreated, onCancelCreate, actions, disableSchedule, leftNavButtonProps, rightNavButtonProps }: CustomerDataPageProps): ReactElement {
  const baseProps = useContext(BasePropsContext);
  const { businessUnitId, messageApi } = baseProps;
  const agentId = useSelector(selectAgentId);
  const { t } = useTranslation();

  const [isEditing, setIsEditing] = useState(!!createNew);
  const [isProcessing, setIsProcessing] = useState(false);

  const { data: foundCustomer, isLoading } = useGetCustomerByIdQuery(customerId, { skip: !!createNew });
  const [createCustomer] = useCreateCustomerMutation();
  const [updateCustomer] = useUpdateCustomerMutation();

  const [registerCallback] = useRegisterCallbackMutation();

  const customer = useMemo(
    () => foundCustomer ?? defaultCustomer,
    [foundCustomer],
  );

  const handleSave = useCallback(
    async (updatedCustomer: Customer) => {
      try {
        setIsProcessing(true);

        if (createNew) {
          const createdCustomer = await createCustomer({ ...updatedCustomer, businessUnitId }).unwrap();
          onCustomerCreated?.(createdCustomer.customerId);
        }
        else {
          await updateCustomer(updatedCustomer).unwrap();
        }

        messageApi.success(t('messages.customerSaved'));
        setIsEditing(false);
      }
      catch {
        messageApi.error(t('messages.customerSaveFailed'));
      }
      finally {
        setIsProcessing(false);
      }
    },
    [businessUnitId, createCustomer, createNew, messageApi, onCustomerCreated, t, updateCustomer],
  );

  const handleCancel = useCallback(
    () => {
      if (createNew)
        onCancelCreate?.();
      else
        setIsEditing(false);
    },
    [createNew, onCancelCreate],
  );

  const handleSchedule = useCallback(
    async (campaignId: string, phoneNumber: string, date: Date, toMyself: boolean) => {
      const record: CampaignRecord = {
        customerId: customer.customerId,
        customerFirstName: customer.firstName ?? '',
        customerLastName: customer.lastName ?? '',
        customerPhone: phoneNumber,
        customerPhoneTag: undefined,
        customerPhoneColor: undefined,
        additionalPhoneNumbers: customer.phoneNumbers.filter(p => p.number !== phoneNumber).map(p => ({ phoneNumber: p.number, tag: p.type || undefined, color: undefined })),
        customFields: {},
        scheduleAfter: date.toISOString(),
        preferredAgentId: toMyself ? agentId : undefined,
        callbackRequestedBy: 'Agent',
      };

      try {
        await registerCallback({ campaignId, record }).unwrap();
        messageApi.success(t('messages.callScheduled'));
        return true;
      }
      catch {
        messageApi.error(t('messages.callScheduleFailed'));
        return false;
      }
    },
    [customer.customerId, customer.firstName, customer.lastName, customer.phoneNumbers, agentId, registerCallback, messageApi, t],
  );

  return (
    <RootContainer id="buzzeasy-customerData_page" role="region">
      <PageNavBar leftNavButtonProps={leftNavButtonProps} rightNavButtonProps={rightNavButtonProps} />
      <ContentContainer>
        <CustomerContainer>
          {
            isEditing
              ? <CustomerEdit
                customer={customer}
                onSave={handleSave}
                onCancel={handleCancel}
                saveDisabled={isProcessing}
              />
              : (
                <ContentWithLoading isLoading={isLoading}>
                  <CustomerInfoWithActions
                    businessUnitId={businessUnitId}
                    customer={customer}
                    onEditClick={() => setIsEditing(true)}
                    onSchedule={handleSchedule}
                    actions={actions}
                    disableSchedule={disableSchedule}
                  />
                </ContentWithLoading>
              )
          }
        </CustomerContainer>
        <ActivityContainer hidden={isEditing} className="cdf-ActivityHistoryFragment_Container">
          <ActivityHistoryFragment
            {...baseProps}
            customerId={customerId}
          />
        </ActivityContainer>
      </ContentContainer>
    </RootContainer>
  );
}