import { PhoneOutlined } from '@ant-design/icons';
import { Space, Tag, Tooltip, Typography, theme } from 'antd';
import React from 'react';
interface PhoneNumberWithTagProps {
  phoneNumber: string;
  tag?: string;
  tagColor: string | null;
  isDisabled: boolean;
  disabledReason?: string;
}

const PhoneNumberWithTag: React.FC<PhoneNumberWithTagProps> = ({ phoneNumber, tag, tagColor, isDisabled, disabledReason }) => {
  const { token } = theme.useToken();
  return (
    <Tooltip title={disabledReason}>
      <Space>
        <Typography.Text style={{ color: isDisabled ? token.colorTextDisabled : token.colorTextLabel }}>
          <Space>
            <PhoneOutlined />
            {phoneNumber}
          </Space>
        </Typography.Text>
        {tag && <Tag color={tagColor ?? undefined}>{tag}</Tag>}
      </Space>
    </Tooltip>
  );
};

export default PhoneNumberWithTag;
