import { createApi } from '@reduxjs/toolkit/query/react';
import { Campaign, CampaignRecord } from '../../models/campaign';
import { apiBaseQuery, post } from '../helpers';

export interface InitiateCallParams {
  channelId: number;
  toNumber: string;
  toCustomer: string;
  workItemId: string;
}

const campaignApi = createApi({
  reducerPath: 'cdf-campaignApi',
  tagTypes: ['Callback'],
  baseQuery: apiBaseQuery('/v1/campaigns'),
  endpoints: (builder) => ({
    getCallbackCampaigns: builder.query<Campaign[], string | null>({
      query: (businessUnitId) => `/callback?businessUnitId=${businessUnitId ?? ''}`,
    }),
    getAlreadyExistingCallback: builder.query<CampaignRecord | null, { campaignId: string; phoneNumber: string }>({
      query: ({ campaignId, phoneNumber }) => `/${campaignId}/duplicate?phone=${encodeURIComponent(phoneNumber)}`,
      providesTags: (_, __, { campaignId, phoneNumber }) => [{ type: 'Callback', id: `${campaignId} ${phoneNumber}` }],
    }),
    registerCallback: builder.mutation<void, {campaignId: string; record: CampaignRecord }>({
      query: ({ campaignId, record }) => post(`/${campaignId}/callback`, record),
      invalidatesTags: (_, __, { campaignId, record: { customerPhone } }) => [{ type: 'Callback', id: `${campaignId} ${customerPhone}` }],
    }),
  }),
});

export default campaignApi;

export const {
  useGetCallbackCampaignsQuery,
  useGetAlreadyExistingCallbackQuery,
  useRegisterCallbackMutation,
} = campaignApi;