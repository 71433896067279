import JSZip from 'jszip';
import { Message } from '../models/typings';

class TranscriptService {

  public create = async (channelName: string, conversationId: string, chatMessages: Message[]) => {
    const messagesWithAttachment = chatMessages.filter(m => m.attachments?.length !== 0);
    const messagesBlob = this.createMessagesBlob(channelName, conversationId, chatMessages);

    if (messagesWithAttachment.length === 0) {
      return {
        isZip: false,
        blob: messagesBlob,
      };
    }

    const zipBlob = await this.zipAttachments(messagesBlob, messagesWithAttachment);

    return {
      isZip: true,
      blob: zipBlob,
    };
  };

  private createMessagesBlob = (channelName: string, conversationId: string, chatMessages: Message[]) => {
    const title = `Downloaded at: ${new Date().toLocaleString()}\r\n`;
    const conversationInfo = `Conversation Id: ${conversationId}\r\n\r\n`;

    const orderedChatMessages = [...chatMessages]
      .filter(m => m.messageBody || m.attachments)
      .sort((a, b) => a.createdAt.localeCompare(b.createdAt));

    const formattedMessages = orderedChatMessages.map(m => {
      const date = new Date(m.createdAt).toLocaleString();
      const line = `[${date}] ${m.from !== 'Customer' ? channelName : m.from}\r\n`;

      if (m.messageBody)
        return line + `${m.messageBody}\r\n\r\n`;

      if (m.attachments)
        return line + `${m.attachments[0].name}\r\n\r\n`;

      return line;
    });

    return new Blob([title, conversationInfo, ...formattedMessages], { type: 'text/plain;charset=utf-8' });

  };

  private zipAttachments = async (messagesBlob: Blob, messagesWithAttachment: Message[]) => {
    const zip = new JSZip();
    zip.file('transcript.txt', messagesBlob);
    const attachmentsFolder = zip.folder('attachments');

    for (const messageWithAttachment of messagesWithAttachment) {
      if (messageWithAttachment.attachments) {
        const response = await fetch(messageWithAttachment.attachments[0].contentUrl);
        const attachmentBlob = await response.blob();
        attachmentsFolder?.file(messageWithAttachment.attachments[0].name, attachmentBlob);
      }
    }

    const zipBlob = await zip.generateAsync({ type: 'blob' });
    return zipBlob;
  };
}
export const transcriptService = new TranscriptService();