import React, { ReactElement, useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import ChannelSelectionPage, { ChannelSelectionPageProps } from '../../../components/containers/ChannelSelectionPage';
import WorkspaceSelectionPage, { WorkspaceSelectionPageProps } from '../../../components/containers/WorkspaceSelectionPage';
import { BasePropsContext, CcsProps } from '../../../components/providers/BasePropsProvider';
import { AgentWorkspace } from '../../../models/agentWorkspace';
import { InitiateCallParams } from '../../../redux/apis/sipConnectorApi';

const PageContainer = styled.div`
  height: 100%;
  overflow: hidden;
`;

export interface PageManagerProps {
  onInitiateCall(params: Pick<InitiateCallParams, 'channelId' | 'musicOnHoldBundleName'>): Promise<boolean>;
}

export default function PageManager({ onInitiateCall }: PageManagerProps): ReactElement {
  const { mediaGroup, onCancel } = useContext(BasePropsContext) as CcsProps;
  
  const [progressState, setProgressState] = useState<'workspaceSelection' | 'channelSelection'>('workspaceSelection');
  const [selectedWorkspace, setSelectedWorkspace] = useState<AgentWorkspace | null>(null);

  const handleWorkspaceSelected = useCallback<WorkspaceSelectionPageProps['onWorkspaceSelected']>(
    (ws) => {
      setSelectedWorkspace(ws);
      setProgressState('channelSelection');

      if (ws?.voiceChannelId) {
        onInitiateCall({
          channelId: ws.voiceChannelId,
          musicOnHoldBundleName: selectedWorkspace?.musicOnHoldBundleName ?? null,
        });
      }
    },
    [onInitiateCall, selectedWorkspace?.musicOnHoldBundleName],
  );

  const handleChannelSelected = useCallback<ChannelSelectionPageProps['onChannelSelected']>(
    (channel) => {
      return onInitiateCall({
        channelId: channel.id,
        musicOnHoldBundleName: selectedWorkspace?.musicOnHoldBundleName ?? null,
      });
    },
    [onInitiateCall, selectedWorkspace?.musicOnHoldBundleName],
  );

  switch (progressState) {
    case 'workspaceSelection':
      return (
        <PageContainer>
          <WorkspaceSelectionPage
            onWorkspaceSelected={handleWorkspaceSelected}
            allowCancel
            onCancel={onCancel}
          />
        </PageContainer>
      );
    case 'channelSelection':
      return (
        <PageContainer>
          <ChannelSelectionPage
            businessUnitId={selectedWorkspace?.businessUnitId ?? null}
            mediaGroup={mediaGroup}
            onChannelSelected={handleChannelSelected}
            onCancel={onCancel}
          />
        </PageContainer>
      );
  }
}