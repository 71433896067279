import { endOfDay, startOfDay } from 'date-fns';

export type DateRange = {
  startDate: Date;
  endDate: Date;
};

class DateService {
  public getRangeFromString = (range: string) => {
    const split = range.split(' - ');
    const startDate = new Date(split[0]);
    const endDate = new Date(split[1]);

    return {
      startDate: startOfDay(startDate),
      endDate: endOfDay(endDate),
    };
  };

  public getRangeAsLocalDateRange = (range: string) => {
    const r = this.getRangeFromString(range);
    return `${r.startDate.toLocaleDateString()} - ${r.endDate.toLocaleDateString()}`;
  };
}

const dateService = new DateService();
export default dateService;