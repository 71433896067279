import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

interface IProps {
  children: ReactNode
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: ${p => p.theme.paddingMD}px;
`;

const CenterContentLayout: FC<IProps> = ({ children }) => {
  return <Container>
    {children}
  </Container>;
};


export default CenterContentLayout;