import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, CheckboxOptionType, DatePicker, Modal, Radio, Select } from 'antd';
import { PickerPanelDateProps } from 'antd/es/calendar/generateCalendar';
import { DatePickerProps } from 'antd/es/date-picker';
import { DefaultOptionType } from 'antd/es/select';
import dayjs, { Dayjs } from 'dayjs';
import { TFunction } from 'i18next';
import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Customer } from '../../models/customer';
import { getCustomerFullName, getCustomerPrimaryPhone } from '../../models/customer.helpers';
import { useGetAlreadyExistingCallbackQuery, useGetCallbackCampaignsQuery } from '../../redux/apis/campaignApi';
import Stack from '../layouts/Stack';
import WarningText from './ScheduleCall.WarningText';

// this is to make my eyes not bleed
const StackWithStyledSelect = styled(Stack)`
  .ant-select-status-warning .ant-select-selector {
    border-color: ${p => p.theme.colorWarning} !important;
  }
`;


function getRescheduleAgentOptions(t: TFunction<'translation'>): CheckboxOptionType[] {
  return [
    { value: false, label: t('scheduleCall.anyone') },
    { value: true, label: t('scheduleCall.myself') },
  ];
}
/**
 * Generate a range of numbers
 * @param start Inclusive start
 * @param end Exclusive end
 * @returns An array containing all the integers from `start` to `end - 1`
 */
function range(start: number, end: number): number[] {
  return Array.from(new Array(end - start)).map((_, i) => i + start);
}

function isSameDay(left: Date, right: Date) {
  return left.getFullYear() === right.getFullYear() &&
    left.getMonth() === right.getMonth() &&
    left.getDate() === right.getDate();
}

const disabledDate: DatePickerProps['disabledDate'] = (current) => {
  return current.isBefore(dayjs().startOf('day'));
};

const disabledDateTime: PickerPanelDateProps<Dayjs>['disabledTime'] = (current) => {
  const date = new Date();

  return {
    disabledHours: () => current && isSameDay(date, current.toDate())
      ? range(0, 24).filter(x => x < date.getHours())
      : [],
    disabledMinutes: () => current && isSameDay(date, current.toDate()) && date.getHours() === current.toDate().getHours()
      ? range(0, 60).filter(x => x <= date.getMinutes())
      : [],
  };
};

export interface ScheduleCallProps {
  businessUnitId: string | null;
  customer: Customer;
  onSchedule(campaignId: string, phoneNumber: string, date: Date, toMyself: boolean): void;
  onCancel(): void;
}

export default function ScheduleCall({ businessUnitId, customer, onSchedule, onCancel }: ScheduleCallProps): ReactElement {
  const [modalApi, modalContextHolder] = Modal.useModal();
  const { t } = useTranslation();

  const [selectedCampaignId, setSelectedCampaignId] = useState('');
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(getCustomerPrimaryPhone(customer.phoneNumbers) ?? '');
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [rescheduleToMyself, setRescheduleToMyself] = useState(false);

  const { data: callbackCampaigns } = useGetCallbackCampaignsQuery(businessUnitId);
  const campaignOptions = useMemo<DefaultOptionType[]>(
    () => callbackCampaigns?.map(c => ({ value: c.id, label: c.name })).sort((a, b) => a.label.localeCompare(b.label, 'en', { sensitivity: 'base' })) ?? [],
    [callbackCampaigns],
  );

  const phoneOptions = useMemo<DefaultOptionType[]>(
    () => customer.phoneNumbers.map(p => ({ value: p.number, label: p.number })),
    [customer.phoneNumbers],
  );

  const rescheduleAgentOptions = useMemo(
    () => getRescheduleAgentOptions(t),
    [t],
  );

  const { data: alreadyExistingCallback } = useGetAlreadyExistingCallbackQuery({ campaignId: selectedCampaignId, phoneNumber: selectedPhoneNumber }, { skip: !selectedCampaignId || !selectedPhoneNumber });

  const handleSchedule = useCallback(
    () => {
      if (!selectedDate || selectedDate <= new Date())
        return;

      modalApi.confirm({
        title: t('scheduleCall.scheduleCall'),
        icon: <ExclamationCircleFilled />,
        content: <Trans i18nKey="scheduleCall.scheduleXConfirmationModalText" values={{ customerName: getCustomerFullName(customer), date: selectedDate.toLocaleString() }} />,
        onOk: () => onSchedule(selectedCampaignId, selectedPhoneNumber, selectedDate, rescheduleToMyself),
      });
    },
    [selectedDate, modalApi, t, customer, onSchedule, selectedCampaignId, selectedPhoneNumber, rescheduleToMyself],
  );

  const now = new Date();

  return (
    <Stack vertical gap="small">
      <StackWithStyledSelect wrap gap="small">
        <Select
          aria-label={t('scheduleCall.callbackCampaign')}
          placeholder={t('scheduleCall.campaign')}
          value={selectedCampaignId || undefined}
          onChange={(value) => setSelectedCampaignId(value)}
          options={campaignOptions}
          showSearch
          filterOption={(str, option) => (option?.label as string | undefined)?.toLocaleLowerCase().includes(str.toLocaleLowerCase()) ?? false}
          style={{ width: 200 }}
          status={!selectedCampaignId ? 'warning' : undefined}
        />
        <Select
          aria-label={t('scheduleCall.callbackPhone')}
          placeholder={t('scheduleCall.phone')}
          value={selectedPhoneNumber}
          onChange={(value) => setSelectedPhoneNumber(value)}
          options={phoneOptions}
          style={{ width: 150 }}
          status={!selectedPhoneNumber ? 'warning' : undefined}
        />
        <DatePicker
          aria-label={t('scheduleCall.callbackDate')}
          placeholder={t('scheduleCall.date')}
          showTime
          showNow={false}
          disabledDate={disabledDate}
          disabledTime={disabledDateTime}
          format="YYYY-MM-DD HH:mm"
          value={selectedDate ? dayjs(selectedDate) : undefined}
          onChange={(_, dateStr) => setSelectedDate(dateStr ? new Date(dateStr) : undefined)}
          status={!selectedDate ? 'warning' : (selectedDate <= now ? 'error' : undefined)}
        />
        <Radio.Group
          options={rescheduleAgentOptions}
          value={rescheduleToMyself}
          onChange={() => setRescheduleToMyself(!rescheduleToMyself)}
          optionType="button"
        />
      </StackWithStyledSelect>
      <Stack wrap gap="small" verticalAlign="baseline">
        <Button type="default" onClick={onCancel}>{t('misc.cancel')}</Button>
        <Button type="primary" disabled={!selectedCampaignId || !selectedPhoneNumber || !selectedDate || selectedDate <= now} onClick={handleSchedule}>{t('scheduleCall.schedule')}</Button>
        {
          !!alreadyExistingCallback &&
          <WarningText>{t('scheduleCall.callbackAlreadyExists')}</WarningText>
        }
      </Stack>
      {modalContextHolder}
    </Stack>
  );
}