import { AdaptiveCard, HostConfig, IAdaptiveCard, SubmitAction } from 'adaptivecards';
import { theme as antdTheme } from 'antd';
import markdownIt from 'markdown-it';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { escapeCustomJs, getAdaptiveCardsInstance, mapActionVariables, mapCardVariables, mapToSubmitVariables } from './CardRenderer.helpers';
import LoadingSpinner from './LoadingSpinner';
import ScriptRunnerService, { CardResponse } from './ScriptRunnerService';
import { getHostConfig } from './getHostConfig';

const { useToken } = antdTheme;

AdaptiveCard.onProcessMarkdown = (text, result) => {
  result.outputHtml = markdownIt().render(text);
  result.didProcess = true;
};

const scriptingUrls: Record<string, string> = {
  'https://api-dev.geomant.cloud': 'https://script-runner-qa.geomant.cloud',
  'https://api-qa.geomant.cloud': 'https://script-runner-qa.geomant.cloud',
  'https://api-prod.geomant.cloud': 'https://script-runner-prod.geomant.cloud',
  'https://api-us.geomant.cloud': 'https://script-runner-prod.geomant.cloud',
};

const CardContainer = styled.div<{ $show: boolean }>`
  display: ${p => !p.$show && 'none'};

  min-height: 1px;
  flex-grow: 1;
  overflow: auto;
`;

interface IProps {
  token: string;
  apiBaseUrl: string;
  scriptProcessId: string;
  show: boolean;
}

// This code is certified Spaghetti
const CardRenderer: FC<IProps> = ({ token, apiBaseUrl, scriptProcessId, show }) => {
  const { token: theme } = useToken();

  const containerRef = useRef<HTMLDivElement>(null);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const [isLoading, setIsLoading] = useState(true);

  const variablesRef = useRef<CardResponse['variables']>({});
  const submitActionRef = useRef<SubmitAction | null>(null);
  const jsContentRef = useRef('');

  const adaptiveCard = useMemo(
    () => getAdaptiveCardsInstance(scriptProcessId),
    [scriptProcessId],
  );

  const scriptRunnerService = useMemo(
    () => {
      const scriptingUrl = scriptingUrls[apiBaseUrl];
      if (!scriptingUrl)
        return null;

      return new ScriptRunnerService(scriptingUrl, scriptProcessId, token);
    },
    [apiBaseUrl, scriptProcessId, token],
  );

  // remove the custom JS block from the card
  const removeJsContentFromCard = useCallback(
    (card: IAdaptiveCard) => {
      jsContentRef.current = '';
      const updatedCard: IAdaptiveCard & { body: NonNullable<IAdaptiveCard['body']> } = { ...card, body: [] };

      for (const element of (card.body ?? [])) {
        if (element.type === 'TextBlock' && element.id?.startsWith('script'))
          jsContentRef.current = escapeCustomJs(element.text);
        else
          updatedCard.body.push(element);
      }

      return updatedCard;
    },
    [],
  );

  // renders the card into the container div
  const render = useCallback(
    () => {
      if (!containerRef.current)
        return;

      const renderedCard = adaptiveCard.render();

      if (!renderedCard)
        return;

      setIsLoading(false);
      containerRef.current.innerHTML = '';
      containerRef.current.appendChild(renderedCard);
    },
    [adaptiveCard],
  );

  // parse card and store the JS content if the card contains one
  const parseCard = useCallback(
    (card: CardResponse | null) => {
      if (!card)
        return;

      variablesRef.current = card.variables;
      const nextCard = removeJsContentFromCard(card.nextCard);
      adaptiveCard.parse(nextCard);
      render();
    },
    [adaptiveCard, removeJsContentFromCard, render],
  );

  // submit the current card's action using the modified variables from the custom JS script
  const executeCard = useCallback(
    async (variables: Record<string, unknown>) => {
      if (!scriptRunnerService || !submitActionRef.current)
        return;

      const data = mapToSubmitVariables(variables, variablesRef.current);
      const card = await scriptRunnerService.executeCard(data);
      parseCard(card);
    },
    [parseCard, scriptRunnerService],
  );

  // create EventListener to receive the modified variables from the custom JS script
  useEffect(
    () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const receiveModifiedVariables = (e: MessageEvent) => {
        if (typeof e.data === 'object' && 'type' in e.data && e.data.type === 'customJsData') {
          executeCard(e.data.variables);
        }
      };

      window.addEventListener('message', receiveModifiedVariables);
      return () => window.removeEventListener('message', receiveModifiedVariables);
    },
    [executeCard],
  );

  // assembles and runs the custom JS script after execute but before submit
  const assembleAndRunCardJs = useCallback(
    (action: SubmitAction) => {
      if (!iframeRef.current || !iframeRef.current.contentDocument)
        return;

      const variables = {
        ...mapCardVariables(variablesRef.current),
        ...mapActionVariables((action.data ?? {}) as Record<string, unknown>, variablesRef.current),
      };

      iframeRef.current.contentDocument.body.innerHTML = '';

      // used to detect 'action' access and warn the user about that it's not a feature but rather an exploit that (for convenience) we also implemented here
      const actionTrap = {
        get(target: SubmitAction, prop: keyof SubmitAction) {
          // eslint-disable-next-line no-console
          console.warn('Warning: Unsupported script custom JS\nAccess of the \'action\' variable should be avoided because it can lead to undefined behavior.');
          return target[prop];
        },
      };

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (iframeRef.current.contentWindow as any).action = new Proxy(action, actionTrap);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (iframeRef.current.contentWindow as any).variables = variables;

      const scriptTag = iframeRef.current.contentDocument.createElement('script');
      scriptTag.innerHTML = `
try { eval(\`${jsContentRef.current}\`); }
catch (ex) { console.error('Custom JS failed to run:', ex); }
finally { window.parent.postMessage({ type: 'customJsData', variables }, '*'); }
`;
      iframeRef.current.contentDocument.body.appendChild(scriptTag);
    },
    [],
  );

  // rerender because of theme change
  useEffect(
    () => {
      adaptiveCard.hostConfig = new HostConfig(getHostConfig(theme));
      render();
    },
    [adaptiveCard, render, theme],
  );

  // rebind onExecuteAction after scriptRunnerService change
  useEffect(
    () => {
      adaptiveCard.onExecuteAction = async (action) => {
        if (!scriptRunnerService)
          return;

        setIsLoading(true);
        assembleAndRunCardJs((action as SubmitAction));
        submitActionRef.current = action as SubmitAction;
      };
    },
    [adaptiveCard, assembleAndRunCardJs, scriptRunnerService],
  );

  // load current card after scriptRunnerService change
  useEffect(
    () => {
      if (!scriptRunnerService)
        return;

      (async function loadCard() {
        const card = await scriptRunnerService.getCurrentCard();
        parseCard(card);
      })();
    },
    [parseCard, scriptRunnerService],
  );

  return <>
    {isLoading && <LoadingSpinner show={show} />}
    <CardContainer ref={containerRef} $show={show && !isLoading}></CardContainer>
    <iframe ref={iframeRef} style={{ display: 'none' }}></iframe>
  </>;
};

export default CardRenderer;