import { IAdaptiveCard } from 'adaptivecards';

interface Variable {
  id: string;
  name: string;
  value: unknown;
}

export interface CardResponse {
  isSuccess: boolean;
  nextCard: IAdaptiveCard;
  variables: Record<string, Variable>;
}

class ScriptRunnerService {
  private url: string;

  private token: string;

  constructor(runnerUrl: string, processId: string, token: string) {
    this.url = `${runnerUrl}/api/process/?processId=${processId}`;
    this.token = token;
  }

  private get() {
    const init: RequestInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`,
      },
      mode: 'cors',
    };

    return fetch(this.url, init);
  }

  private put(data: unknown) {
    const init: RequestInit = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`,
      },
      mode: 'cors',
      body: JSON.stringify(data),
    };

    return fetch(this.url, init);
  }

  private async parseResponse(response: Response): Promise<CardResponse | null> {
    try {
      const body = await response.json();
      if ('nextCard' in body)
        return body as CardResponse;

      // eslint-disable-next-line no-console
      console.error({ message: 'Unexpected response body', status: response.status, body });
    }
    catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    return null;
  }

  public async getCurrentCard(): Promise<CardResponse | null> {
    try {
      const response = await this.get();
      return await this.parseResponse(response);
    }
    catch {
      return null;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async executeCard(data: unknown): Promise<CardResponse | null> {
    try {
      const response = await this.put(data);
      return await this.parseResponse(response);
    }
    catch {
      return null;
    }
  }
}

export default ScriptRunnerService;