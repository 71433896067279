import { DeleteOutlined, MailOutlined, PhoneOutlined, PlusCircleOutlined, SaveOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import { AutoComplete, Button, Input, Tooltip, theme } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import useGetCustomerFields from '../../hooks/useGetCustomerFields';
import { Customer, CustomerPhoneNumber } from '../../models/customer';
import { spaceSizeToPixels } from '../../utils/antDesignUtils';
import Icon from '../controls/Icon';
import InputWithSelect from '../controls/InputWithSelect';
import Frame from '../layouts/Frame';
import Stack from '../layouts/Stack';
import { getInputStatus, hasPhoneNumber, hasValue, isValidEmail } from './CustomerEdit.helpers';

const Grid = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(2, minmax(1px, 1fr));

  gap: ${p => spaceSizeToPixels(p.theme, 'small')}px; 
`;

const InputLabel = styled.span`
  color: ${p => p.theme.colorTextSecondary};
`;

export function mapToOptions(a: boolean, b: boolean): number {
  if (a === false && b === true)
    return -1;

  if (a === true && b === false)
    return 1;

  return 0;
}

function mapToOption(value: string): DefaultOptionType {
  return { label: value, value };
}

const defaultPhoneTypeOptions = ['mobile', 'home', 'work'];

function getPhoneTypeOptions(text: string) {
  if (!text)
    return defaultPhoneTypeOptions.map(mapToOption);

  const suggested = defaultPhoneTypeOptions.find(pt => pt.startsWith(text));

  if (suggested === text)
    return [suggested, ...defaultPhoneTypeOptions.filter(pt => pt !== suggested)].map(mapToOption);

  if (suggested)
    return [text, suggested, ...defaultPhoneTypeOptions.filter(pt => pt !== suggested)].map(mapToOption);

  return [text, ...defaultPhoneTypeOptions].map(mapToOption);
}

export interface CustomerEditProps {
  customer: Customer;
  onSave(value: Customer): void;
  onCancel(): void;
  saveDisabled: boolean;
}

export default function CustomerEdit({ customer, onSave, onCancel, saveDisabled }: CustomerEditProps): ReactElement {
  const { token } = theme.useToken();
  const { t } = useTranslation();

  const [internalCustomer, setInternalCustomer] = useState<Customer>(customer);
  const [phoneTypeOptions, setPhoneTypeOptions] = useState<DefaultOptionType[]>(getPhoneTypeOptions(''));

  useEffect(
    () => {
      setInternalCustomer(customer);
    },
    [customer],
  );

  const addPhoneNumber = useCallback(
    () => setInternalCustomer(curr => ({ ...curr, phoneNumbers: [...curr.phoneNumbers, { phoneNumberId: 0, number: '', type: '', preferred: false }] })),
    [],
  );

  const updatePhoneNumber = useCallback(
    (index: number, phone: Partial<CustomerPhoneNumber>) => setInternalCustomer(curr => ({ ...curr, phoneNumbers: curr.phoneNumbers.map((p, i) => i === index ? { ...p, ...phone } : p) })),
    [],
  );

  const setPreferredPhoneNumber = useCallback(
    (index: number) => setInternalCustomer(curr => ({ ...curr, phoneNumbers: curr.phoneNumbers.map((p, i) => ({ ...p, preferred: i === index })) })),
    [],
  );

  const deletePhoneNumber = useCallback(
    (index: number) => setInternalCustomer(curr => ({ ...curr, phoneNumbers: curr.phoneNumbers.filter((_, i) => i !== index) })),
    [],
  );

  const updateCustomerField = useCallback(
    (fieldDefinitionId: number, value: string) => {
      const field = internalCustomer.customFields.find(f => f.fieldDefinitionId === fieldDefinitionId);

      setInternalCustomer(curr => ({
        ...curr,
        customFields: field
          ? curr.customFields.map(f => f.fieldDefinitionId === fieldDefinitionId ? { ...f, value } : f)
          : [...curr.customFields, { fieldDefinitionId, value }],
      }));
    },
    [internalCustomer.customFields],
  );

  const customerFields = useGetCustomerFields(internalCustomer);

  const isValid = (hasValue(internalCustomer.email) || hasPhoneNumber(internalCustomer)) // customer required to have either email or at least 1 phone number
    && internalCustomer.phoneNumbers.every(p => p.number.trim() !== '') // check if all phone numbers are filled in
    && (hasValue(internalCustomer.email) ? isValidEmail(internalCustomer.email) : true); // if the email is filled in, it must be valid

  return (
    <Frame horizontalPadding="large" verticalPadding="small">
      <Stack vertical gap="middle">
        <Stack gap="small" itemProps={{ grow: 1, shrink: 1 }} aria-label={t('customer.customerName')}>
          <Input placeholder={t('customer.firstName')}
            value={internalCustomer.firstName ?? ''}
            onChange={(e) => setInternalCustomer({ ...internalCustomer, firstName: e.target.value })}
          />
          <Input placeholder={t('customer.middleName')}
            value={internalCustomer.middleName ?? ''}
            onChange={(e) => setInternalCustomer({ ...internalCustomer, middleName: e.target.value })}
          />
          <Input placeholder={t('customer.lastName')}
            value={internalCustomer.lastName ?? ''}
            onChange={(e) => setInternalCustomer({ ...internalCustomer, lastName: e.target.value })}
          />
        </Stack>
        <Grid aria-label={t('customer.customerFields')}>
          {customerFields.map(f => f.type === 'Choice'
            ? <InputWithSelect
              key={f.id}
              addonBefore={<InputLabel>{f.label}</InputLabel>}
              value={f.value}
              options={f.options.map(o => ({ label: o, value: o }))}
              onChange={(val: string) => updateCustomerField(f.id, val)}
              aria-label={t('customer.xField', { value: f.label })}
            />
            : <Input
              key={f.id}
              addonBefore={<InputLabel>{f.label}</InputLabel>}
              value={f.value}
              onChange={(e) => updateCustomerField(f.id, e.target.value)}
              maxLength={200}
              aria-label={t('customer.xField', { value: f.label })}
            />
          )}
        </Grid>
        <Input placeholder={t('customer.emailAddress')}
          addonBefore={<Icon icon={MailOutlined} large color={token.colorTextTertiary} />}
          value={internalCustomer.email ?? ''}
          onChange={(e) => setInternalCustomer({ ...internalCustomer, email: e.target.value })}
          status={getInputStatus(internalCustomer.email, { required: internalCustomer.phoneNumbers.length === 0, validator: (em) => !em || isValidEmail(em) })}
        />
        <Stack vertical gap="small" role="list" aria-label={t('customer.customerPhoneNumbers')}>
          {internalCustomer.phoneNumbers.map((p, i) => (
            <Stack key={i} gap="small" role="listitem">
              <Input
                placeholder={t('customer.phoneNumber')}
                addonBefore={<Icon icon={PhoneOutlined} large color={token.colorTextTertiary} />}
                value={p.number}
                onChange={(e) => updatePhoneNumber(i, { number: e.target.value })}
                status={getInputStatus(p.number, { required: true })}
                maxLength={30}
                style={{ flexShrink: 1 }}
              />
              <AutoComplete
                placeholder={t('customer.type')}
                aria-label={t('customer.phoneNumberType')}
                value={p.type ?? ''}
                options={phoneTypeOptions}
                onSelect={(str) => updatePhoneNumber(i, { type: str })}
                onSearch={(str) => setPhoneTypeOptions(getPhoneTypeOptions(str))}
                onChange={(str) => updatePhoneNumber(i, { type: str })}
                style={{ width: 125 }}
              />
              <Tooltip title={t('customer.markAsPreferred')}>
                {
                  p.preferred
                    ? <Button type="link" aria-label={t('customer.markAsPreferred')} icon={<StarFilled />} onClick={() => updatePhoneNumber(i, { preferred: false })} role="radio" aria-checked="true" />
                    : <Button type="link" aria-label={t('customer.markAsPreferred')} icon={<StarOutlined />} onClick={() => setPreferredPhoneNumber(i)} role="radio" aria-checked="false" />
                }
              </Tooltip>
              <Tooltip title={t('customer.removePhone')}>
                <Button type="link" aria-label={t('customer.removePhone')} danger icon={<DeleteOutlined />} onClick={() => deletePhoneNumber(i)} />
              </Tooltip>
            </Stack>
          ))}
          <Button type="default" icon={<PlusCircleOutlined />} onClick={addPhoneNumber} disabled={internalCustomer.phoneNumbers.length >= 10} style={{ width: 'fit-content' }}>{t('customer.addPhone')}</Button>
        </Stack>
        <Stack gap="small">
          <Button type="default" onClick={onCancel}>{t('misc.cancel')}</Button>
          <Button type="primary" icon={<SaveOutlined />} onClick={() => onSave(internalCustomer)} disabled={!isValid || saveDisabled}>{t('misc.save')}</Button>
        </Stack>
      </Stack>
    </Frame>
  );
}