interface VersionInfo {
  version: string;
  buildId: string;
}

{
  const w = window as unknown as { _buzzeasy: Record<string, VersionInfo> | undefined };

  if (!w._buzzeasy) {
    w._buzzeasy = {};
  }

  w._buzzeasy['contact-lookup-fragment'] = {
    version: '1.11.2',
    buildId: '20240130.7',
  };
}