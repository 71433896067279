import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

export interface DataCacheState {
  customerListSearchText: string;
}

const initialState: DataCacheState = {
  customerListSearchText: '',
};

const dataCacheSlice = createSlice({
  name: 'cdf-dataCache',
  initialState,
  reducers: {
    setCustomerListSearchText: (state, { payload }: PayloadAction<string>) => ({ ...state, customerListSearchText: payload }),
  },
});

export default dataCacheSlice;

export const selectCustomerListSearchText = (state: RootState): string => state['cdf-dataCache'].customerListSearchText;

export const {
  setCustomerListSearchText,
} = dataCacheSlice.actions;