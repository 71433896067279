import { ApiResponse, Conversation, CustomerRelations, EmailMessageModel, MessageIntegrationEvent } from '../models/typings';
import { IActivityService } from '../providers/ActivityServiceProvider';

const oDataApiUrlMapping: Record<string, string> =
{
  'https://api-dev.geomant.cloud': 'https://odata-dev.buzzeasy.com',
  'https://api-qa.geomant.cloud': 'https://odata-qa.buzzeasy.com',
  'https://api-prod.geomant.cloud': 'https://odata.buzzeasy.com',
  'https://api-us.geomant.cloud': 'https://odata-us.buzzeasy.com',
};

export default class ActivityService implements IActivityService {

  private token: string;
  private oDataApiUrl: string;
  private contentManagerApiUrl: string;

  constructor(token: string, apiBaseUrl: string) {
    this.token = token;
    this.oDataApiUrl = oDataApiUrlMapping[apiBaseUrl];
    this.contentManagerApiUrl = apiBaseUrl + '/content/v2';
  }

  public async getCustomerRelatedEntities(customerId: string) {
    return (this.get<CustomerRelations>(`${this.oDataApiUrl}/extras/customers/${customerId}/relations`));
  }

  public async getConversationsByQueryString(queryString: string) {
    return this.get<ApiResponse<Conversation>>(`${this.oDataApiUrl}/v1/Conversations${queryString}`);
  }

  public async getConversationHistoryByConversationId(conversationId: string) {
    return this.get<MessageIntegrationEvent[]>(`${this.contentManagerApiUrl}/conversationhistory?conversation_id=${conversationId}`);
  }

  public async getEmailsByConversationId(conversationId: string) {
    return this.get<EmailMessageModel[]>(`${this.contentManagerApiUrl}/messages/email/byconversationid?conversationId=${conversationId}`);
  }

  private async get<T>(url: string) {
    const headers = this.createHeaders();
    const resp = await fetch(url, { headers });
    if (!resp.ok) {
      return Promise.reject(new Error(resp.statusText));
    }
    const body = await resp.json();
    return body as T;
  }

  private createHeaders() {
    return {
      Accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
    };
  }
}