import { createApi } from '@reduxjs/toolkit/query/react';
import { apiBaseQuery, post } from '../helpers';

export interface InitiateEmailParams {
  customerId: string | null;
  customerName: string;
  customerEmailAddress: string;
  channelId: number;
  channelName: string;
  channelEmailAddress: string;
}

const emailHubApi = createApi({
  reducerPath: 'emailHub',
  tagTypes: [],
  baseQuery: apiBaseQuery('/email'),
  endpoints: (builder) => ({
    initiateEmail: builder.mutation<void, InitiateEmailParams>({
      query: (params) => post('/initiate', params),
    }),
  }),
});

export default emailHubApi;

export const {
  useInitiateEmailMutation,
} = emailHubApi;