import { FacebookOutlined, InstagramOutlined, LinkOutlined, MailOutlined, MessageOutlined, MobileOutlined, NodeIndexOutlined, PhoneOutlined, QuestionOutlined, RobotOutlined, WhatsAppOutlined } from '@ant-design/icons';
import React, { ReactNode } from 'react';

export const externalProfileTypes = ['botframework', 'directline', 'facebook', 'infobip_sms', 'instagram', 'link', 'viber', 'whatsapp'] as const;
export type ExternalProfileType = typeof externalProfileTypes[number];
export type ContactType = typeof externalProfileTypes[number] | 'email' | 'phone' | 'customField' | null;

export function getContactIcon(type: ContactType): ReactNode {
  switch (type) {
    case 'botframework':
      return <RobotOutlined />;
    case 'directline':
      return <NodeIndexOutlined />;
    case 'email':
      return <MailOutlined />;
    case 'facebook':
      return <FacebookOutlined />;
    case 'infobip_sms':
      return <MobileOutlined />;
    case 'instagram':
      return <InstagramOutlined />;
    case 'link':
      return <LinkOutlined />;
    case 'phone':
      return <PhoneOutlined />;
    case 'viber':
      return <MessageOutlined />;
    case 'whatsapp':
      return <WhatsAppOutlined />;
    default:
      return <QuestionOutlined />;
  }
}