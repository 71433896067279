import { PlusOutlined, UserAddOutlined } from '@ant-design/icons';
import { FloatButton, Pagination } from 'antd';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { PageProps } from '../../models/pages';
import { CustomerSearchProps, useSearchCustomersQuery } from '../../redux/apis/customerApi';
import { selectCustomerListSearchText, setCustomerListSearchText } from '../../redux/dataCacheSlice';
import { useAppDispatch } from '../../redux/store';
import Frame from '../layouts/Frame';
import ContentWithLoading from '../presenters/ContentWithLoading';
import CustomerList, { CustomerListProps } from '../presenters/CustomerList';
import PageNavBar from '../presenters/PageNavBar';
import SearchAndFilterBar from '../presenters/SearchAndFilterBar';

const RootContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  overflow: auto;
`;

const CenterContentDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const pageSize = 50;

export interface FloatingActionProps {
  icon: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLElement>;
  tooltip?: string;
}

export interface CustomerListPageProps extends PageProps {
  businessUnitId: string | null;
  selectedCustomerId: string | null;
  onCustomerSelected(customerId: string): void;
  onCreate(): void;
  isCreateDisabled?: boolean;
  customerActions?: CustomerListProps['customerActions'];
  focusedCustomerId?: CustomerListProps['focusedCustomerId'];
  floatingActions?: FloatingActionProps[];
}

/**
 * The React component that only deals with retrieving and listing the customers
 * NOTE: This component needs to be placed inside the `CustomerPageProviders` component
 */
export default function CustomerListPage({ businessUnitId, selectedCustomerId, onCustomerSelected, onCreate, isCreateDisabled, customerActions, focusedCustomerId, floatingActions, leftNavButtonProps, rightNavButtonProps }: CustomerListPageProps): ReactElement {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const searchText = useSelector(selectCustomerListSearchText);
  const setSearchText = useCallback(
    (str: string) => dispatch(setCustomerListSearchText(str)),
    [dispatch],
  );

  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState<CustomerSearchProps['filters']>({ needsToHaveName: true, needsToHavePhone: false, needsToHaveEmail: false, businessUnitId });

  useEffect(
    () => { setFilters(curr => ({ ...curr, businessUnitId })); },
    [businessUnitId],
  );

  const { data: searchResults, isLoading, isFetching } = useSearchCustomersQuery({
    skip: page * pageSize,
    top: pageSize,
    searchText,
    filters,
  });

  const customers = useMemo(
    () => searchResults?.value ?? [],
    [searchResults?.value],
  );

  const total = useMemo(
    () => searchResults?.['@odata.count'] ?? 0,
    [searchResults],
  );

  const updateSearchText = useCallback(
    (st: string) => {
      setSearchText(st);
      setPage(0);
    },
    [setSearchText],
  );

  const updateFilters = useCallback(
    (f: CustomerSearchProps['filters']) => {
      setFilters(f);
      setPage(0);
    },
    [],
  );

  const allFloatingActions = useMemo<FloatingActionProps[]>(
    () => [
      ...(!isCreateDisabled ? [{ icon: <UserAddOutlined />, onClick: onCreate, tooltip: t('actions.createCustomerTitle') }] : []),
      ...(floatingActions ?? []),
    ].reverse(),
    [floatingActions, isCreateDisabled, onCreate, t],
  );

  return (
    <RootContainer id="buzzeasy-customerList_page" role="region">
      <PageNavBar leftNavButtonProps={leftNavButtonProps} rightNavButtonProps={rightNavButtonProps} />
      <Frame horizontalPadding="middle" verticalPadding="small">
        <SearchAndFilterBar
          searchText={searchText}
          onSearchTextChange={updateSearchText}
          filters={filters}
          onFiltersChange={updateFilters}
          isSearchLoading={isLoading || isFetching}
        />
      </Frame>
      <ContentContainer>
        <ContentWithLoading isLoading={isLoading || isFetching}>
          <CustomerList
            selectedCustomerId={selectedCustomerId}
            customers={customers ?? []}
            onCustomerSelected={(c) => onCustomerSelected(c.customerId)}
            customerActions={customerActions}
            focusedCustomerId={focusedCustomerId}
          />
        </ContentWithLoading>
      </ContentContainer>
      <Frame horizontalPadding="middle" verticalPadding="small">
        <CenterContentDiv>
          <Pagination
            total={total}
            current={page + 1}
            onChange={(p) => setPage(p - 1)}
            showSizeChanger={false}
            pageSize={pageSize}
          />
        </CenterContentDiv>
      </Frame>
      {
        allFloatingActions.length === 1 &&
        <FloatButton
          type="primary"
          icon={allFloatingActions[0].icon}
          onClick={allFloatingActions[0].onClick}
          tooltip={allFloatingActions[0].tooltip}
          style={{ position: 'absolute' }}
        />
      }
      {
        allFloatingActions.length > 1 &&
        <FloatButton.Group
          trigger="hover"
          type="primary"
          icon={<PlusOutlined />}
          style={{ position: 'absolute' }}
        >
          {allFloatingActions.map((fa, i) => <FloatButton key={i} icon={fa.icon} onClick={fa.onClick} tooltip={fa.tooltip} />)}
        </FloatButton.Group>
      }
    </RootContainer>
  );
}