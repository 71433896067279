import { InputProps } from 'antd';
import { Customer } from '../../models/customer';

export function isValidEmail(email: string): boolean {
  return /^(\w[\w.%+-]*\w|\w)@[\w.-]+\.[\w]{2,6}$/.test(email);
}

export function hasValue<T>(value: T): value is Exclude<T, null | undefined | ''> {
  return value !== null && value !== undefined && !(typeof value === 'string' && value.trim() === '');
}

export function getInputStatus<T extends string | number | null>(value: T, { required, validator }: { required?: boolean; validator?(value: T): boolean }): InputProps['status'] {
  if (required && !hasValue(value))
    return 'warning';

  if (validator && !validator(value))
    return 'error';

  return undefined;
}

export function hasPhoneNumber(customer: Customer): boolean {
  return customer.phoneNumbers.some(p => p.number.trim() !== '');
}