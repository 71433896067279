import { DownOutlined } from '@ant-design/icons';
import { Input, InputProps, Select, SelectProps, theme } from 'antd';
import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import Icon from './Icon';

const Container = styled.div`
  cursor: pointer;
  position: relative;
`;

const StyledInput = styled(Input)`
  .ant-input-affix-wrapper, input {
    cursor: pointer;
    user-select: none;
  }
`;

const StyledSelect = styled(Select)`
  visibility: hidden;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
`;

// works similarly to debounce but instead of the last activation it only lets through the first activation
function ignoreDuplicateActivations<TArgs extends unknown[]>(func: (...args: TArgs) => void, ignoreMilliseconds: number) {
  let timeout: NodeJS.Timeout | undefined = undefined;

  return (...args: TArgs) => {
    if (timeout === undefined)
      func(...args);

    clearTimeout(timeout);
    timeout = setTimeout(() => timeout = undefined, ignoreMilliseconds);
  };
}

export interface InputWithSelectProps extends Omit<InputProps, 'onChange' | 'onClick' | 'readOnly' | 'suffix'>, Pick<SelectProps, 'onChange' | 'options'> { }

export default function InputWithSelect({ value, options, onChange, ...props }: InputWithSelectProps): ReactElement {
  const { token } = theme.useToken();

  const [open, setOpen] = useState(false);

  const toggleOpen = useMemo(
    () => ignoreDuplicateActivations(() => setOpen(curr => !curr), 300),
    []
  );

  const handleOnChange = useCallback<NonNullable<typeof onChange>>(
    (...args) => {
      onChange?.(...args);
      toggleOpen();
    },
    [onChange, toggleOpen],
  );

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(
    () => {
      function closeIfNotSelfOrChild(e: MouseEvent) {
        if (containerRef.current !== e.target && !containerRef.current?.contains(e.target as Node))
          setOpen(false);
      }

      window.addEventListener('click', closeIfNotSelfOrChild);
      return () => window.removeEventListener('click', closeIfNotSelfOrChild);
    },
    [],
  );

  return (
    <Container onClick={toggleOpen} ref={containerRef}>
      <StyledInput
        {...props}
        readOnly
        value={value}
        suffix={<Icon icon={DownOutlined} small color={token.colorTextQuaternary} />}
      />
      <StyledSelect
        open={open}
        value={value}
        options={options}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onChange={handleOnChange as any} // for some reason styled brakes typing
        onDropdownVisibleChange={toggleOpen}
      />
    </Container>
  );
}