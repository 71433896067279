import jwtDecode from 'jwt-decode';
import React, { FC, ReactNode, createContext, useMemo } from 'react';

export interface IProps {
  token: string;
  children: ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const UserContext = createContext<string>(undefined!);

const UserProvider: FC<IProps> = ({ token, children }) => {

  const currentUserId = useMemo(
    () => jwtDecode<{ sub: string }>(token).sub,
    [token],
  );

  return <UserContext.Provider value={currentUserId}>
    {children}
  </UserContext.Provider>;
};

export default UserProvider;