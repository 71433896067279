import { WarningOutlined } from '@ant-design/icons';
import { Space, theme } from 'antd';
import Text from 'antd/es/typography/Text';
import React, { PropsWithChildren, ReactElement } from 'react';

export default function WarningText({ children }: PropsWithChildren): ReactElement {
  const { token } = theme.useToken();

  return (
    <Space size="small">
      <WarningOutlined style={{ color: token.colorWarningText }} />
      <Text style={{ color: token.colorWarningText }}>{children}</Text>
    </Space>
  );
}