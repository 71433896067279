import { createApi } from '@reduxjs/toolkit/query/react';
import { EmailChannel } from '../../models/channels';
import { apiBaseQuery } from '../helpers';

const emailConnectorApi = createApi({
  reducerPath: 'emailConnector',
  tagTypes: [],
  baseQuery: apiBaseQuery(''),
  endpoints: (builder) => ({
    getEmailChannelById: builder.query<EmailChannel, number>({
      query: (channelId) => `/channel-providers/graph-email/configurations/${channelId}`,
    }),
    listEmailChannels: builder.query<EmailChannel[], void>({
      query: () => '/channel-providers/graph-email/configurations',
    }),
  }),
});

export default emailConnectorApi;

export const {
  useGetEmailChannelByIdQuery,
  useListEmailChannelsQuery,
} = emailConnectorApi;