import { Select, SelectProps } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Filter } from '../../models/typings';
import { removeAllFiltersByType, removeFiltersAndAdd, selectFilterByFilterType } from '../../redux/activityHistorySlice';
import { useAppDispatch } from '../../redux/store';

type Direction = 'Inbound' | 'Outbound' | 'All';

const DirectionFilterContainer: FC = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const filter = useSelector(selectFilterByFilterType('filterByDirection'));

  const getDirectionLabel = (dir: Direction) => {
    switch (dir) {
      case 'Inbound':
        return t('direction.inbound');
      case 'Outbound':
        return t('direction.outbound');
      case 'All':
        return t('direction.all');
    }
  };

  const options: SelectProps['options'] =
    filter.predefinedFilters.map((f: Filter) => {
      return {
        value: f.id,
        label: getDirectionLabel(f.id as Direction),
      };
    });

  const onSelect: SelectProps['onSelect'] = useCallback(
    (_: unknown, option: DefaultOptionType) => {
      if (option.value === 'Inbound') {
        dispatch(removeFiltersAndAdd({
          id: option.label as string,
          value: false,
          type: 'filterByDirection',
        }));
      }

      if (option.value === 'Outbound') {
        dispatch(removeFiltersAndAdd({
          id: option.label as string,
          value: true,
          type: 'filterByDirection',
        }));
      }

      if (option.value === 'All') {
        dispatch(removeAllFiltersByType('filterByDirection'));
      }
    },
    [dispatch],
  );

  return <Select
    placeholder={t('filterTitle.direction')}
    style={{ width: 147 }}
    options={options}
    onSelect={onSelect}
  />;
};

export default DirectionFilterContainer;