import { Radio, Tooltip } from 'antd';
import React, { FC } from 'react';
import styled from 'styled-components';
import PhoneNumberWithTag from './PhoneNumberWithTag';

const RadioContent = styled.div`
  margin-left: ${p => p.theme.marginXXS}px;
`;

export interface IPhoneNumberRadioButtonProps {
  phoneNumber: string;
  tag?: string;
  tagColor: string | null;
  isDisabled: boolean;
  disabledReason?: string;
}

const PhoneNumberRadioButton: FC<IPhoneNumberRadioButtonProps> = ({ phoneNumber, tag, tagColor, isDisabled, disabledReason }) => {
  return (
    <Radio value={phoneNumber} disabled={isDisabled}>
      <Tooltip title={disabledReason}>
        <RadioContent>
          <PhoneNumberWithTag phoneNumber={phoneNumber} tag={tag} tagColor={tagColor} isDisabled={isDisabled} />
        </RadioContent>
      </Tooltip>
    </Radio>
  );
};

export default PhoneNumberRadioButton;
