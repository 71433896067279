import { MessageInstance } from 'antd/es/message/interface';
import React, { ReactElement } from 'react';
import Providers from '../components/providers/Providers';
import ServiceHandler from './components/containers/ServiceHandler';

export interface CrmChannelSelectorProps {
  apiBaseUrl: string;
  token: string;
  language?: string;
  messageApi?: MessageInstance;
  
  workItemId: string;
  mediaGroup: 'email' | 'voice';
  customerId?: string;
  customerIdentifier: string;
  onCancel(): void;
}

function getErrorMessageForMissingProp(prop: string) {
  return `Error: Empty/undefined ${prop} property received in ContactLookupFragment.`;
}

const requiredProps: (keyof CrmChannelSelectorProps)[] = ['apiBaseUrl', 'token', 'workItemId', 'mediaGroup', 'customerIdentifier'];

function validateProps(props: CrmChannelSelectorProps) {
  requiredProps.forEach(key => {
    if (!props[key])
      throw new Error(getErrorMessageForMissingProp(key));
  });
}

export default function CrmChannelSelector(props: CrmChannelSelectorProps): ReactElement {
  validateProps(props);

  if (props.mediaGroup !== 'voice')
    throw new Error('Only voice mediaGroup is supported by CrmChannelSelector at the moment.');
    
  return (
    <Providers {...props}>
      <ServiceHandler />
    </Providers>
  );
}