import { HubConnection, HubConnectionState } from '@microsoft/signalr';
import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { ConversationInfo, ErrorEvent } from '../models/dtos';
import { setConversationInfo, setError, } from '../redux/conversationSlice';
import virtualConversationHub, {
  IVirtualConversationHub,
} from '../services/virtualConversationHub';

export interface IVirtualConversationHubProviderProps {
  token: string;
  apiBaseUrl: string;
  conversationId: string;
  children: ReactNode;
}

const VirtualConversationHubProvider: FC<IVirtualConversationHubProviderProps> = ({
  token,
  apiBaseUrl,
  children,
  conversationId,
}) => {
  const dispatch = useDispatch();
  const [connection, setConnection] = useState<HubConnection>();

  const handleConversationInfo = useCallback(
    (conversationInfo: ConversationInfo) => {
      if (conversationInfo.conversationId === conversationId) {
        dispatch(setConversationInfo(conversationInfo));
      }
    },
    [dispatch, conversationId]
  );

  const handleErrorEvent = useCallback(
    (errorEvent: ErrorEvent) => {
      if (errorEvent.conversationId === conversationId) {
        dispatch(setError(errorEvent));
      }
    },
    [dispatch, conversationId]
  )

  useEffect(() => {
    if (token && apiBaseUrl) {
      const newConnection = virtualConversationHub.createConnection(
        apiBaseUrl + '/campaign/hub',
        token
      );

      newConnection.on('SendConversationInfo', handleConversationInfo);
      newConnection.on('SendErrorEvent', handleErrorEvent)

      newConnection.start().then(() => {
        setConnection(newConnection);
      });
    }
  }, [token, apiBaseUrl, handleConversationInfo]);

  useEffect(() => {
    return () => {
      if (
        connection &&
        connection.state !== HubConnectionState.Disconnected &&
        connection.state !== HubConnectionState.Disconnecting
      ) {
        connection.stop();
      }
    };
  }, []);

  return (
    <VirtualConversationHubContext.Provider value={virtualConversationHub}>
      {connection && children}
    </VirtualConversationHubContext.Provider>
  );
};

export const VirtualConversationHubContext = createContext<IVirtualConversationHub>(virtualConversationHub);
export default VirtualConversationHubProvider;
