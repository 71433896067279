import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

interface IProps {
  header: ReactNode;
  content: ReactNode
}

const OuterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${p => p.theme.paddingXS}px;
  border-bottom: ${p => p.theme.lineWidth}px solid ${p => p.theme.colorBorder};
`;

const ActivityDetailsLayout: FC<IProps> = ({ header, content }) => {

  return <OuterContainer>
    {header}
    {content &&
      <ContentContainer>
        {content}
      </ContentContainer>
    }
  </OuterContainer>;
};

export default ActivityDetailsLayout;