import i18next from 'i18next';
import React, { PropsWithChildren, ReactElement, useMemo } from 'react';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import translations from '../translations';

export interface TranslationProviderProps {
  language?: string;
}

export default function TranslationProvider({ children, language }: PropsWithChildren<TranslationProviderProps>): ReactElement {

  const i18nextInstance = useMemo(
    () => {
      const instance = i18next.createInstance();

      instance
        .use(initReactI18next)
        .init({
          resources: translations as unknown as Parameters<(typeof i18next)['init']>[0]['resources'],
          lng: language,
          fallbackLng: 'en-US',
          interpolation: {
            escapeValue: false,
          },
        });

      return instance;
    },
    [language],
  );
  
  return (
    <I18nextProvider i18n={i18nextInstance}>
      {children}
    </I18nextProvider>
  );
}