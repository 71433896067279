import { RightOutlined } from '@ant-design/icons';
import { Button, Space, Typography } from 'antd';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export interface HeaderProps {
  campaignName: string;
  expanded: boolean;
  onExpandedChangeRequested?(expanded: boolean): void;
}

const { Text } = Typography;

const ExpandButton = styled(Button) <{ $expanded: boolean }>`
  box-sizing: border-box;

  flex-shrink: 0;
  width: 100%;
  height: ${p => p.theme.controlHeight + 2 * p.theme.paddingXXS}px;

  text-align: start;
  padding: ${p => p.theme.paddingXXS}px ${p => p.theme.paddingXS}px;

  .anticon {
    transform: rotate(${p => p.$expanded ? 90 : 0}deg);
    transition: all ${p => p.theme.motionDurationMid} ${p => p.theme.motionEaseInOut};
  }
`;

export default function Header({ campaignName, expanded, onExpandedChangeRequested }: HeaderProps): ReactElement {
  const { t } = useTranslation();

  return (
    <ExpandButton type="link" $expanded={expanded} aria-label={expanded ? t('collapseFragment') : t('expandFragment')} aria-expanded={expanded} onClick={() => onExpandedChangeRequested?.(!expanded)}>
      <Space>
        <RightOutlined />
        <Text strong aria-label={t('misc.title')}>{campaignName} - {t('fragmentName')}</Text>
      </Space>
    </ExpandButton>
  );
}