import { CountdownProps, Statistic } from 'antd';
import React, { FC, useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import { IVirtualConversationFragmentProps } from '../..';
import { BasePropsContext } from '../../providers/BasePropsProvider';

const HiddenCountdown = styled(Statistic.Countdown)`
  display: none;
`;

interface IProps {
  expiresAt: Date | string;
  onFinish?(): void;
  formatTimeSpan?: IVirtualConversationFragmentProps['formattingApi']['formatTimeSpan'];
}

const Timer: FC<IProps> = ({ expiresAt, onFinish, formatTimeSpan }) => {
  const baseProps = useContext(BasePropsContext);

  // This whole shebang is here because of one specific Timer use case where it is rendered inside an AntD notification and it doesn't have access to the BasePropsContext.
  if (!baseProps && !formatTimeSpan)
    throw new Error('Timer cannot be used without a provided formatTimeSpan method. Please either make sure that the component has access to BasePropsContext, or provide the method explicitly as a prop.');

  formatTimeSpan ??= baseProps.formattingApi.formatTimeSpan;

  const [remainingSeconds, setRemainingSeconds] = useState((toTime(expiresAt) - new Date().getTime()) / 1000);

  const onChange = useCallback<NonNullable<CountdownProps['onChange']>>(
    (time) => {
      if (typeof time !== 'number')
        return;

      setRemainingSeconds(Math.floor(time / 1000));
    },
    [],
  );

  if (!baseProps && !formatTimeSpan)
    throw new Error('Timer cannot be used without a provided formatTimeSpan method. Please either make sure that the component has access to BasePropsContext, or provide the method explicitly as a prop.');
    
  return <>
    <HiddenCountdown value={toTime(expiresAt)} onChange={onChange} onFinish={onFinish} />
    {formatTimeSpan(remainingSeconds)}
  </>;
};

export default Timer;

function toTime(date: Date | string) {
  const actualDate = typeof date === 'string' ? new Date(date) : date;
  return actualDate.getTime();
}