import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { VirtualConversationFsmState } from '../../models/dtos';
import ActionsLayout from '../layouts/ActionsLayout';
import Reschedule from '../presenters/Reschedule';

interface IActionsContainerProps {
  currentRescheduleAfter?: string;
  currentRescheduleToMyself: boolean;
  onReschedule(scheduledAfter: string | undefined, toMyself: boolean): void;
  onCloseAdditionalDetails(): void;
  onDial(): void;
  onReject(): void;
  onDisconnect(): void;
  onCancel(): void;
  remainingReschedules?: number,
  rescheduleLimit?: number,
  virtualConversationFsmState: VirtualConversationFsmState;
  rescheduleLimitReached: boolean
  isCallButtonDisabled: boolean,
  isCancelButtonEnabled: boolean,
}

const ActionsContainer: React.FC<IActionsContainerProps> = ({
  currentRescheduleAfter,
  currentRescheduleToMyself,
  virtualConversationFsmState,
  remainingReschedules,
  rescheduleLimit,
  rescheduleLimitReached,
  isCallButtonDisabled,
  isCancelButtonEnabled,
  onDial,
  onReschedule,
  onCloseAdditionalDetails,
  onReject,
  onDisconnect,
  onCancel,
}) => {
  const { t } = useTranslation();

  let availableButtons: ReactNode[] = [];
  switch (virtualConversationFsmState) {
    case 'waitingForDialOrReject':
      availableButtons = [
        <Button danger icon={<CloseOutlined />} onClick={onReject} key="reject">
          {currentRescheduleAfter ? t('actions.rejectAndReschedule') : t('actions.reject')}
        </Button>,
        <Button disabled={!!currentRescheduleAfter} type="primary" icon={<CheckOutlined />} onClick={onDial} key="accept">
          {t('actions.acceptAndCall')}
        </Button>,
      ];
      if (isCancelButtonEnabled) {
        availableButtons.push(<Button icon={<CloseOutlined />} onClick={onCancel}>{t('actions.cancelAndClose')}</Button>);
      }
      break;
    case 'waitingForDisconnect': {
      availableButtons = [
        <Button type={isCallButtonDisabled ? 'primary' : 'default'} icon={<CheckOutlined />} onClick={onDisconnect} key='markAsComplete'>
          {currentRescheduleAfter ? t('actions.markAndCompleteReschedule') : t('actions.markAsComplete')}
        </Button>,
        <Button type={!isCallButtonDisabled ? 'primary' : 'default'} onClick={onDial} key="redial" disabled={isCallButtonDisabled}>
          {t('actions.callCustomer')}
        </Button>,
      ];
    }
  }

  return (
    <ActionsLayout
      reschedule={
        <Reschedule
          onReschedule={onReschedule}
          onCloseAdditionalDetails={onCloseAdditionalDetails}
          currentRescheduleAfter={currentRescheduleAfter}
          currentRescheduleToMyself={currentRescheduleToMyself}
          remainingReschedules={remainingReschedules}
          rescheduleLimit={rescheduleLimit}
          rescheduleLimitReached={rescheduleLimitReached}
        />
      }
      buttons={availableButtons}
    />
  );
};

export default ActionsContainer;
