import React, { PropsWithChildren, ReactElement, useMemo } from 'react';
import { Provider } from 'react-redux';
import { createStore } from '../../redux/store';

export interface StoreProviderProps {
  apiBaseUrl: string;
  token: string;
}

export default function StoreProvider({ children, apiBaseUrl, token }: PropsWithChildren<StoreProviderProps>): ReactElement {
  const store = useMemo(
    () => createStore(apiBaseUrl, token),
    [apiBaseUrl, token],
  );
  
  return (
    <Provider store={store}>
      {children}
    </Provider>
  );
}