import { Alert } from 'antd';
import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export interface RescheduledWarningTextProps {
  scheduleTarget: string;
}

export default function RescheduledWarningText({ scheduleTarget }: RescheduledWarningTextProps): ReactElement {
  const { t } = useTranslation();

  const hasScheduleTargetPassed = useMemo<boolean>(
    () => {
      const now = new Date().getTime();
      const scheduledTo = new Date(scheduleTarget).getTime();
      
      return now > scheduledTo;
    },
    [scheduleTarget],
  );
  
  return (
    <Alert
      message={t('feedback.rescheduledToXWarning', { value: getFormattedDate(scheduleTarget) })}
      type={hasScheduleTargetPassed ? 'warning' : 'info'}
      closable
    />
  );
}

function getFormattedDate(dateTime: string): string {
  const date = dateTime.slice(0, 10).replaceAll('-', '.');
  const time = new Date(dateTime).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
  return (`${date} - ${time}`);
}