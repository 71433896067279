import { Select, SelectProps } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import React, { FC, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Filter, FilterWithType } from '../../models/typings';
import { UserContext } from '../../providers/UserProvider';
import { addFilter, removeFilter, selectFilterByFilterType } from '../../redux/activityHistorySlice';
import { useAppDispatch } from '../../redux/store';

interface Props {
  hideSensitiveAgentInformation: boolean
}

const AgentFilterContainer: FC<Props> = ({ hideSensitiveAgentInformation }) => {
  const { t } = useTranslation();

  const currentUserId = useContext(UserContext);

  const dispatch = useAppDispatch();
  const filter = useSelector(selectFilterByFilterType('filterByAgent'));

  const options: SelectProps['options'] = useMemo(
    () => {
      if (hideSensitiveAgentInformation) {
        const currentUser = filter.predefinedFilters.find(f => f.id === currentUserId);

        if (currentUser) {
          return [{
            value: currentUser.id,
            label: currentUser.value as string,
          },
          ];
        }
        return [];
      }

      return filter.predefinedFilters.map((f: Filter) => {
        return {
          value: f.id,
          label: f.value as string,
        };
      });
    },
    [currentUserId, filter, hideSensitiveAgentInformation],
  );

  const onSelect: SelectProps['onSelect'] = useCallback(
    (_: unknown, option: DefaultOptionType) => {
      const filterPayload: FilterWithType = {
        value: option.label as string,
        id: option.value as string,
        type: 'filterByAgent',
      };
      dispatch(addFilter(filterPayload));
    },
    [dispatch],
  );

  const onDeselect: SelectProps['onDeselect'] = useCallback(
    (value: string) => {
      const filterPayload: FilterWithType = { value, id: value, type: 'filterByAgent' };
      dispatch(removeFilter(filterPayload));
    },
    [dispatch],
  );

  return <Select
    placeholder={t('filterTitle.agent')}
    mode='tags'
    maxTagCount='responsive'
    options={options}
    loading={false}
    onSelect={onSelect}
    onDeselect={onDeselect}
    style={{ width: 147 }}
  />;
};

export default AgentFilterContainer;