import { RightOutlined } from '@ant-design/icons';
import { Button, Space, Typography } from 'antd';
import React, { ReactElement } from 'react';
import styled from 'styled-components';

const { Text } = Typography;

const ExpandButton = styled(Button) <{ $expanded: boolean }>`
  width: 100%;
  height: ${p => p.theme.controlHeight + 2 * p.theme.paddingXXS}px;
  padding: ${p => p.theme.paddingXXS}px ${p => p.theme.paddingXS}px;

  text-align: start;

  .anticon {
    transform: rotate(${p => p.$expanded ? 90 : 0}deg);
    transition: all ${p => p.theme.motionDurationMid} ${p => p.theme.motionEaseInOut};
  }
`;

export interface HeaderProps {
  expanded: boolean;
  onExpandedChangeRequested?(expanded: boolean): void;
}

export default function Header({ expanded, onExpandedChangeRequested }: HeaderProps): ReactElement {

  return (
    <ExpandButton type="link" $expanded={expanded} aria-label={`${expanded ? 'Collapse' : 'Expand'} scripting`} aria-expanded={expanded} onClick={() => onExpandedChangeRequested?.(!expanded)}>
      <Space>
        <RightOutlined />
        <Text strong>Scripting</Text>
      </Space>
    </ExpandButton>
  );
}