import { Alert, Button, List, Space } from 'antd';
import Search from 'antd/es/input/Search';
import React, { ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { spaceSizeToPixels } from '../../utils/antDesignUtils';
import { createShouldForwardProp } from '../../utils/styledUtils';
import Frame from '../layouts/Frame';

const RootContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  overflow: auto;
`;

const ItemContainer = styled(List.Item).withConfig({ shouldForwardProp: createShouldForwardProp(['active']) }) <{ active: boolean }>`
  && {
    cursor: pointer;
    position: relative;

    padding: ${p => p.theme.paddingSM}px ${p => p.theme.paddingMD}px;

    .clf-select-button {
      opacity: 0;
      pointer-events: none;
    }

    ${p => p.active ? '&,' : ''} &:hover {
      background-color: ${p => p.theme.colorBgTextHover};

      .clf-select-button {
        opacity: 1;
        pointer-events: inherit;
      }
    }
  }
`;

const SelectButton = styled(Button).attrs({ type: 'primary', className: 'clf-select-button' })`
  position: absolute;
  right: ${p => spaceSizeToPixels(p.theme, 'large')}px;
  top: 50%;
  transform: translateY(-50%);
`;

export interface ListWithSearchAndSelectProps<T> {
  hintText?: string;
  searchPlaceholder: string;
  searchLoading?: boolean;
  searchText: string;
  onSearchTextChange(value: string): void;
  dataSource: NonNullable<T>[];
  defaultItem?: T;
  renderItem(item: NonNullable<T>, active: boolean, index: number): ReactNode;
  itemEquals?(left: T | undefined, right: T | undefined): boolean;
  onSelect(item: NonNullable<T>): void;
  selectButtonText?: string;
}

export default function ListWithSearchAndSelect<T>(props: ListWithSearchAndSelectProps<T>): ReactElement {
  const { hintText, searchLoading, searchPlaceholder, searchText, onSearchTextChange, defaultItem, dataSource, renderItem, itemEquals, onSelect, selectButtonText } = props;

  const { t } = useTranslation();

  return (
    <RootContainer>
      <Frame horizontalPadding="middle" verticalPadding="small">
        <Space direction="vertical" size="small" style={{ width: '100%' }}>
          {hintText && <Alert type="info" showIcon message={hintText} />}
          <Search
            allowClear
            loading={searchLoading}
            placeholder={searchPlaceholder}
            value={searchText}
            onChange={(e) => onSearchTextChange(e.target.value)}
            onSearch={onSearchTextChange}
          />
        </Space>
      </Frame>
      <ContentContainer>
        <List
          dataSource={dataSource}
          renderItem={(item, index) => {
            const active = itemEquals ? itemEquals(item, defaultItem) : item === defaultItem;
            return (
              <ItemContainer active={active} onClick={() => onSelect(item)}>
                {renderItem(item, active, index)}
                <SelectButton onClick={(e) => { e.stopPropagation(); onSelect(item); }}>{selectButtonText ?? t('misc.select')}</SelectButton>
              </ItemContainer>
            );
          }}
        />
      </ContentContainer>
    </RootContainer>
  );
}

const ListItemMeta = styled(List.Item.Meta)`
  &&& {
    align-items: center;

    .ant-list-item-meta-title {
      margin: 0;
    }
  }
`;

const IconContainer = styled.div.withConfig({ shouldForwardProp: createShouldForwardProp(['selected']) }) <{ selected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: ${p => p.selected ? p.theme.colorPrimaryHover : p.theme.colorTextQuaternary};
    width: 32px;
    height: 32px;

    transition: all ${p => p.theme.motionDurationMid} ${p => p.theme.motionEaseInOut};
  }
`;


export interface WorkspaceListItemProps {
  selected: boolean;
  icon?: ReactNode;
  title: string;
  description?: string;
}

function ListItem({ selected, icon, title, description }: WorkspaceListItemProps): ReactElement {

  return (
    <ListItemMeta
      avatar={
        <IconContainer selected={selected}>
          {icon}
        </IconContainer>
      }
      title={title}
      description={description ?? ''}
    />
  );
}

export { ListItem };

