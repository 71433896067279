export type FilterType = 'filterByMediaType' | 'filterByTag' | 'filterByDate' | 'filterByChannel' | 'filterByAgent' | 'filterByDirection';

export const FilterTypeKeys: FilterType[] = ['filterByMediaType', 'filterByTag', 'filterByDate', 'filterByChannel', 'filterByAgent', 'filterByDirection'];

export type MediaType = 'voice' | 'webChat' | 'email' | 'facebook' | 'whatsApp' | 'viber' | 'instagram' | 'infobip_sms' | 'unknown';

export const MediaTypes: string[] = ['whatsApp', 'viber', 'email', 'voice', 'facebook', 'webChat', 'instagram', 'infobip_sms'];

export type RequestStatus = 'idle' | 'rejected' | 'pending' | 'fulfilled';

export type ActivityProp = keyof Pick<Activity, 'mediaType' | 'date' | 'tag' | 'channelId'>;

export type Status = 'idle' | 'pending' | 'failed' | 'fulfilled';

export type OrderDirection = 'asc' | 'desc';

export type Filters = Record<FilterType, FilterCategory>;

export type AppState = 'init' | 'fetchActivities' | 'fetchingActivities' | 'idle' | 'loadingMore';

export type Channel = {
  id: number;
  name: string;
  channelType: string;
  deletedAt?: string;
};

export type Message = {
  id: string;
  createdAt: string;
  from: string;
  messageBody: string;
  attachments?: Array<Attachment>;
};

export type Activity = {
  id: string;
  mediaType: MediaType;
  agentId: string;
  date: string;
  duration: number;
  tag: string;
  messagesRequestStatus: RequestStatus;
  messages: Message[];
  emails: EmailMessageModel[];
  channelId: number;
  notes: string;
  result: string;
  isOutbound: boolean;
};

export type Paging = {
  skip: number;
  top: number;
};

export type ActivityHistorySlice = {
  customerId: string;
  orderDirection: OrderDirection;
  filters: Filters;
  activities: Activity[];
  totalNumberOfActivities: number;
  channels: Channel[];
  channelsStatus: RequestStatus;
  activitiesStatus: RequestStatus;
  appState: AppState;
  pageSize: number;
  currentActivitiesRequestId: string | undefined;
  agents: UserReadModel[];
};

export type Filter = {
  value: string | boolean;
  id: string;
};

export type FilterWithType = Filter & {
  type: FilterType;
};

export type ApiResponse<T> = {
  '@odata.context': string;
  '@odata.count': number;
  value: T[];
};

export type Attachment = {
  contentType: string;
  contentUrl: string;
  content: Record<string, unknown>;
  name: string;
  thumbnailUrl: string;
};

export type QueueSegment = {
  queueId?: string
  conversationId?: string
  queuedAt: string;
  queuedAtUtc: string;
  dequeuedAt: string;
  dequeuedAtUtc: string;
  durationInSeconds: number;
  dequeuedReason?: string
  channelId: number;
  conversation?: Conversation
};

export type RejectedEnqueueAttempt = {
  queueId?: string
  conversationId?: string
  rejectedAt: string;
  rejectedAtUtc: string;
  rejectReason: string;
  channelId: number;
  conversation?: Conversation
};

export type WorkItem = {
  id: string;
  startTime: string;
  startTimeUtc: string;
  endTime: string;
  endTimeUtc: string;
  durationInSeconds: number;
  completionCode?: string;
  agentId: string;
  mediaType: string;
  result: string;
  customerId?: string;
  primaryConversationId?: string;
  notes: string;
  activeDurationInSeconds: number;
  wrapUpDurationInSeconds: number;
  queueId?: string;
  primaryChannelId?: number;
  previewDurationInSeconds: number;
  lookupDurationInSecods: number;
  conversations: Conversation[];
};

export type Conversation = {
  id: string;
  channelId: number;
  channelType: string;
  startedAt: string;
  startedAtUtc: string;
  endedAt: string;
  endedAtUtc: string;
  durationInSeconds: number;
  conversationEndedReason: string;
  customerId?: string;
  customerFirstName?: string;
  customerLastName?: string;
  isOutbound: boolean;
  inChannelClosedDurationInSeconds: number;
  inChannelOpenDurationInSeconds: number;
  nextConversationId?: string;
  transferInitiatedBy?: string;
  workItems: WorkItem[];
  queueSegments: QueueSegment[];
  rejectedEnqueueAttempts: RejectedEnqueueAttempt[]
};

export type DateRange = {
  startDate: string;
  endDate: string;
};

export type EmailAddress = {
  address: string;
  name: string;
};

export type FilterCategory = {
  predefinedFilters: Filter[];
  selectedFilters: Filter[];
};

export type MessageIntegrationEvent = {
  chatServiceId?: number;
  customerId?: string;
  from: string;
  messageBody: string;
  attachments?: Attachment[];
  createdAt: string;
  channelId?: string;
  conversationId?: string;
  suggestedActions?: SuggestedActions;
  attachmentLayout?: string;
  summary?: string;
  additionalData?: { [key: string]: string };
  messageFrom?: string;
  messageTo?: string;
  speak?: string;
  inputHint?: string;
  reactionsAdded?: Array<MessageReaction>;
  reactionsRemoved?: Array<MessageReaction>;
  stringValue?: string;
  channelData?: string;
  id: string;
  creationDate: Date;
  sessionId?: string;
  tenantId?: string;
};

export interface MessageReaction {
  type?: string;
}

export interface SuggestedActions {
  to?: Array<string>;
  actions?: Array<CardAction>;
}

export interface CardAction {
  type?: string;
  title?: string;
  image?: string;
  text?: string;
  displayText?: string;
  value?: unknown;
}

export type EmailMessageModel = {
  messageId: string;
  conversationId: string;
  subject: string;
  body: EmailContentModel;
  bodyPreview: string;
  createdDateTime: string;
  participantId: string | null;
  hasAttachments: boolean;
  importance: boolean;
  receivedDateTime: string;
  replyTo: EmailAddress[];
  to: EmailAddress[];
  cc: EmailAddress[];
  bcc: EmailAddress[];
  from: EmailAddress;
  attachments: Attachment[];
  senderType: string | null;
};

export type UserReadModel = {
  id: string;
  name: string;
};

export type UserManagementApiResponse = {
  total: number;
  results: UserReadModel[];
};

export type EmailContentModel = {
  id: string;
  content: string;
  contentType: string;
};

export type Agent = UserReadModel & {
  deletedAt: string;
};

export type CustomerRelations = {
  customerId: string;
  channels: Channel[];
  agents: Agent[];
  completionCodes: string[]
};

export type SenderType = 'customer' | 'agent' | 'supervisor' | 'bot';

export type ResultType = 'Handled' | 'Cancelled' | 'Failed' | 'Abandoned' | 'Rejected' | 'Missed' | 'QueueTimedOut' | 'CallbackRequested' | 'CustomerDisconnected' | 'NoStaffedAgentLoggedIn' | 'NoAgentLoggedIn' | 'QueueSizeLimitReached' | 'CallbackRequested' | 'TransferToExternalFailed' | 'TransferToExternal' | 'TransferToChannel' | 'IdleTimeout' | 'BotFailed' | 'CustomerDisconnected' | 'Default' | 'CustomerJoinFailed' | 'InternalError' | 'Unspecified' | 'TransferToAgentFailed' | 'CallbackCanceled' | 'CancelledByAgent' | 'AgentJoinFailed';