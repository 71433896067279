import { Form } from 'antd';
import React, { FC, RefObject, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useManageNotifications from '../../hooks/useManageNotifications';
import { PhoneNumber } from '../../models/dtos';
import { BasePropsContext } from '../../providers/BasePropsProvider';
import { VirtualConversationHubContext } from '../../providers/VirtualConversationHubProvider';
import { clearError, selectConversationInfo } from '../../redux/conversationSlice';
import MainLayout from '../layouts/MainLayout';
import Customer from '../presenters/Customer';
import DialAttempts from '../presenters/DialAttempts';
import EditActions from '../presenters/EditActions';
import Loading from '../presenters/Loading';
import LoadingOverlay from '../presenters/Overlay';
import RescheduledWarningText from '../presenters/RescheduledWarningText';
import VirtualConversationTitle from '../presenters/VirtualConversationTitle';
import ActionsContainer from './ActionsContainer';
import AdditionalDetailsContainer from './AdditionalDetailsContainer';
import PhoneNumbersContainer from './PhoneNumbersContainer';
import PhoneNumbersFormContainer from './PhoneNumbersFormContainer';

interface MainContainerProps {
  rootContainerRef: RefObject<HTMLDivElement>;
}

const MainContainer: FC<MainContainerProps> = ({ rootContainerRef }) => {
  const { expanded, onExpandedChangeRequested, notificationApi } = useContext(BasePropsContext);

  const conversationInfo = useSelector(selectConversationInfo);
  const {
    conversationId,
    afterCallTimerExpiresAt,
    customFields,
    campaignName,
    rescheduledAfter,
    rescheduleToMyself,
    previewTimerExpiresAt,
    phoneNumbers,
    virtualConversationFsmState,
    customerName,
    dialAttempts,
    dialLimit,
    rescheduleLimit,
    remainingReschedules,
    cancellableByAgent,
    callbackRequestedBy,
    scheduleTarget,
    errorReason,
    errorType,
    operation,
  } = conversationInfo;

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const hub = useContext(VirtualConversationHubContext);

  const [editMode, setEditMode] = useState<boolean>(false);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<string>('');
  const [isOperationInProgress, setIsOperationInProgress] = useState<boolean>(false);
  const [showAdditionalDetails, setShowAdditionalDetails] = useState<boolean>(true);
  const [showPhoneNumbers, setShowPhoneNumbers] = useState<boolean>(true);
  const [isAdditionalDetailsExpanded, setIsAdditionalDetailsExpanded] = useState<boolean>(false);
  const [fragmentHeight, setFragmentHeight] = useState<number>();

  const isConversationInfoAvailable = !!campaignName && !!customerName && phoneNumbers.length > 0 && !!virtualConversationFsmState;
  const rescheduleLimitReached = remainingReschedules === 0;
  const dialLimitReached = dialLimit != null && dialAttempts != null && dialAttempts === dialLimit;
  const displayDialAttempts = dialLimit != null && dialAttempts != null && !editMode && showPhoneNumbers;
  const isCallButtonDisabled = dialLimitReached || !selectedPhoneNumber;
  const isPhoneNumberSelectionDisabled = virtualConversationFsmState !== 'waitingForDialOrReject' && virtualConversationFsmState !== 'waitingForDisconnect';

  const [form] = Form.useForm();

  useManageNotifications();

  useEffect(() => {
    if (errorType) {
      const key = `vcf-conversation-${conversationId}`;
      let message: string | undefined = undefined;
      switch (errorType) {
        case 'Unauthorized':
          message = t('feedback.unauthorized');
          notificationApi.error({ key, message });
          break;
        case 'DialLimitReached':
          message = t('feedback.dialAttemptLimitReached');
          notificationApi.warning({ key, message });
          break;
        case 'OperationFailed':
          message = t('feedback.actionFailed');
          notificationApi.error({ key, message });
          break;
        case 'RescheduleLimitReached':
          message = t('feedback.rescheduleLimitReached');
          notificationApi.warning({ key, message });
          break;
      }
      dispatch(clearError());
    }
  }, [errorReason, errorType, operation, t]
  );

  useEffect(() => {
    setIsOperationInProgress(false);
  }, [conversationInfo]);

  useEffect(() => {
    if (conversationId) hub.peek({ conversationId });
  }, [conversationId, hub]);

  const onReject = () => {
    hub.reject({ conversationId });
  };

  const onReschedule = (scheduledAfter: string | undefined, toMyself: boolean) => {
    hub.reschedule({ conversationId, scheduledAfter, toMyself });
    setIsAdditionalDetailsExpanded(false);

    if (fragmentHeight && fragmentHeight < 550) {
      setShowPhoneNumbers(true);
    }
  };

  const onDial = () => {
    hub.dial({ conversationId, phoneNumber: selectedPhoneNumber });
  };

  const onDisconnect = () => {
    hub.disconnect({ conversationId });
  };

  const onFinish = (numbers: PhoneNumber[]) => {
    hub.updatePhoneNumbers({ conversationId, phoneNumbers: numbers });
    setIsOperationInProgress(true);
    setEditMode(false);
  };

  const onCancel = () => {
    hub.cancel({ conversationId });
  };

  const onEdit = useCallback(
    () => {
      setEditMode(true);
      setIsAdditionalDetailsExpanded(false);

      if (fragmentHeight && fragmentHeight < 550) {
        setShowPhoneNumbers(true);
        setShowAdditionalDetails(false);
      }
    },
    [fragmentHeight],
  );

  const onCancelEdit = useCallback(
    () => {
      setEditMode(false);

      if (fragmentHeight && fragmentHeight < 550) {
        setShowAdditionalDetails(true);
      }
    },
    [fragmentHeight],
  );

  const onShowMore = useCallback(
    () => {
      setIsAdditionalDetailsExpanded(true);

      if (fragmentHeight && fragmentHeight < 550)
        setShowPhoneNumbers(false);
    },
    [fragmentHeight],
  );

  const onShowLess = useCallback(
    () => {
      setIsAdditionalDetailsExpanded(false);

      if (fragmentHeight && fragmentHeight < 550)
        setShowPhoneNumbers(true);
    },
    [fragmentHeight],
  );

  useEffect(
    () => {
      const observer = new ResizeObserver((entries) => {
        const containerHeight = entries[0].contentRect.height;
        setFragmentHeight(containerHeight);
      });

      if (rootContainerRef.current) {
        observer.observe(rootContainerRef.current);
      }

      return () => {
        observer.disconnect();
      };
    },
    [rootContainerRef],
  );

  return (
    <>
      {isConversationInfoAvailable
        ? <MainLayout
          expanded={expanded}
          onExpandedChangeRequested={onExpandedChangeRequested}
          campaignName={campaignName}
          showPhoneNumbers={showPhoneNumbers}
          showAdditionalDetails={showAdditionalDetails}
          title={
            <VirtualConversationTitle
              title={campaignName}
              afterCallTimerExpiresAt={afterCallTimerExpiresAt}
              previewTimerExpiresAt={previewTimerExpiresAt}
              callRequestedBy={callbackRequestedBy}
            />
          }
          warning={
            scheduleTarget &&
            <RescheduledWarningText scheduleTarget={scheduleTarget} />
          }
          customer={
            <Customer
              customerName={customerName}
              onEdit={onEdit}
              showEditButton={!editMode}
            />
          }
          dialAttempts={
            displayDialAttempts
              ? <DialAttempts
                dialAttempts={dialAttempts}
                dialLimit={dialLimit}
                dialLimitReached={dialLimitReached}
              />
              : null
          }
          additionalDetails={
            customFields ?
              <AdditionalDetailsContainer
                additionalDetails={customFields}
                isExpanded={isAdditionalDetailsExpanded}
                onShowLess={onShowLess}
                onShowMore={onShowMore}
              />
              :
              null
          }
          phoneNumbers={
            editMode ?
              <PhoneNumbersFormContainer
                form={form}
                phoneNumbers={phoneNumbers}
                onFinish={onFinish}
              />
              :
              <PhoneNumbersContainer
                phoneNumbers={phoneNumbers}
                isPhoneNumberSelectionDisabled={isPhoneNumberSelectionDisabled}
                selectedPhoneNumber={selectedPhoneNumber}
                setSelectedPhoneNumber={setSelectedPhoneNumber}
                dialLimitReached={dialLimitReached}
              />
          }
          actions={
            editMode ?
              <EditActions
                onCancel={onCancelEdit}
                onSubmit={() => form.submit()}
              />
              :
              < ActionsContainer
                currentRescheduleAfter={rescheduledAfter}
                currentRescheduleToMyself={rescheduleToMyself}
                virtualConversationFsmState={virtualConversationFsmState}
                rescheduleLimitReached={rescheduleLimitReached}
                remainingReschedules={remainingReschedules}
                rescheduleLimit={rescheduleLimit}
                isCallButtonDisabled={isCallButtonDisabled}
                isCancelButtonEnabled={cancellableByAgent}
                onDial={onDial}
                onReschedule={onReschedule}
                onCloseAdditionalDetails={onShowLess}
                onReject={onReject}
                onDisconnect={onDisconnect}
                onCancel={onCancel}
              />
          }
          loadingOverlay={
            isOperationInProgress && <LoadingOverlay />
          }
        />
        : <Loading />
      }
    </>
  );
};

export default MainContainer;
