import { RobotOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { SenderType } from '../../models/typings';
import TokenProvider from '../../providers/TokenProvider';

interface IProps {
  senderType: SenderType;
  content: ReactNode;
  isInternal: boolean;
}

const OuterContainer = styled.div<{ isInternal: boolean }>`
  display: flex;
  flex-direction: ${p => p.isInternal ? 'row-reverse' : 'row'};
  align-items: center;
  gap: ${p => p.theme.paddingXXS}px;
`;

const MessageLineContainer = styled.div`
  max-width: 75%;
  display: flex;
  flex-direction: row;
`;

const MessageBubble = styled.div<{ senderType: SenderType }>`
  width: max-content;
  border-radius: 8px;
  padding: ${p => p.theme.paddingXS}px;
  background-color: ${p => p.senderType === 'customer' ? p.theme.colorBgContainerDisabled : (p.senderType === 'bot' ? p.theme.colorSuccessBgHover : p.theme.colorPrimaryBgHover)};
`;

const LeftTriangle = styled.div<{ senderType: SenderType }>`
  width: 0;
  height: 0;
  align-self: center;
  border-top: 6px solid transparent;
  border-left: 8px solid ${p => p.senderType === 'customer' ? p.theme.colorBgContainerDisabled : (p.senderType === 'bot' ? p.theme.colorSuccessBgHover : p.theme.colorPrimaryBgHover)};
  border-bottom: 6px solid transparent;
`;

const RightTriangle = styled.div<{ senderType: SenderType }>`
  width: 0;
  height: 0;
  align-self: center;
  border-top: 6px solid transparent;
  border-right: 8px solid ${p => p.senderType === 'customer' ? p.theme.colorBgContainerDisabled : (p.senderType === 'bot' ? p.theme.colorSuccessBgHover : p.theme.colorPrimaryBgHover)};
  border-bottom: 6px solid transparent;
`;

const MessageLine: FC<IProps> = ({ senderType, isInternal, content }) => {

  return <OuterContainer isInternal={isInternal}>
    <TokenProvider
      overrideTokens={senderType === 'agent' ?
        (themeTokens) => ({ colorTextPlaceholder: themeTokens.colorPrimaryText })
        :
        (senderType === 'bot' ?
          (themeTokens) => ({ colorTextPlaceholder: themeTokens.colorSuccessTextHover })
          :
          (themeTokens) => ({ colorTextPlaceholder: themeTokens.colorTextPlaceholder }))} >
      <Avatar size='small' icon={senderType === 'bot' ? <RobotOutlined /> : <UserOutlined />} />
    </TokenProvider>
    <MessageLineContainer>
      {!isInternal && <RightTriangle senderType={senderType} />}
      <MessageBubble senderType={senderType}>{content}</MessageBubble>
      {isInternal && <LeftTriangle senderType={senderType} />}
    </MessageLineContainer>
  </OuterContainer >;
};


export default MessageLine;