import React, { FC } from 'react';
import { Attachment } from '../../models/typings';
import FlexColLayout from '../layouts/FlexColLayout';

interface IProps {
  attachments: Attachment[]
}

const TranscriptLinesContainerLink: FC<IProps> = ({ attachments }) => {

  return <FlexColLayout>
    {
      attachments.map(a =>
        <a
          key={a.contentUrl}
          href={a.contentUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          {a.name}
        </a>
      )
    }
  </FlexColLayout>;
};

export default TranscriptLinesContainerLink;