import buildQuery from 'odata-query';
import { Filter, FilterTypeKeys, Filters, OrderDirection, Paging } from '../models/typings';
import dateService from './dateService';

const customerFilter = (customerId: string) => {
  const filter = { customerId };
  return filter;
};

const excludeVirtualConversationsFilter = () => {
  const filter = { channelType: { ne: 'virtual' } };
  return filter;
};

const dateRangeFilter = (from: Date, to: Date) => {
  const filter = { startedAtUtc: { gt: from, lt: to } };
  return filter;
};

const channelTypeFilter = (channelType: string[]) => {
  const filter = { channelType: { in: [...channelType] } };
  return filter;
};

const channelIdFilter = (channelId: number[]) => {
  const filter = { channelId: { in: [...channelId] } };
  return filter;
};

const agentIdFilter = (agentId: string[]) => {
  const filter = { agentId: { in: [...agentId] } };
  return filter;
};

const tagFilter = (completionCode: string[]) => {
  const filter = { completionCode: { in: [...completionCode] } };
  return filter;
};

const getDateRangesFromFilter = (selectedFilter: Filter) => {
  const dateFilterType = selectedFilter.value;
  return dateService.getRangeFromString(dateFilterType as string);
};

const directionFilter = (isOutbound: boolean) => {
  const filter = { isOutbound: { eq: isOutbound } };
  return filter;
};

const queryBuilder = (customerId: string, startTimeOrderDirection: OrderDirection, filters: Filters, paging: Paging): string => {
  const orderBy = [`startedAtUtc ${startTimeOrderDirection}`];
  //used any because of the dynamic behavior of odata-query
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const workItemsFilter: { any: { and: any[] } } = { any: { and: [] } };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const filter: { and: any[], workItems?: typeof workItemsFilter } = { and: [customerFilter(customerId), excludeVirtualConversationsFilter()] };

  FilterTypeKeys.forEach(key => {
    switch (key) {
      case 'filterByChannel': {
        const filterValues = filters[key].selectedFilters.map(selectedFilter => Number.parseInt(selectedFilter.id));
        if (filterValues.length > 0) {
          const newFilter = channelIdFilter(filterValues);
          filter.and.push(newFilter);
        }
        break;
      }
      case 'filterByMediaType': {
        const filterValues = filters[key].selectedFilters.map(selectedFilter => selectedFilter.id);
        if (filterValues.length > 0) {
          const newFilter = channelTypeFilter(filterValues);
          filter.and.push(newFilter);
        }
        break;
      }
      case 'filterByDate': {
        if (filters[key].selectedFilters.length === 1) {
          const { startDate, endDate } = getDateRangesFromFilter(filters[key].selectedFilters[0]);
          const newFilter = dateRangeFilter(startDate, endDate);
          filter.and.push(newFilter);
        }
        break;
      }
      case 'filterByAgent': {
        const filterValues = filters[key].selectedFilters.map(selectedFilter => selectedFilter.id);
        if (filterValues.length > 0) {
          const newFilter = agentIdFilter(filterValues);
          workItemsFilter.any.and.push(newFilter);
        }
        break;
      }
      case 'filterByTag': {
        const filterValues = filters[key].selectedFilters.map(selectedFilter => selectedFilter.id);
        if (filterValues.length > 0) {
          const newFilter = tagFilter(filterValues);
          workItemsFilter.any.and.push(newFilter);
        }
        break;
      }
      case 'filterByDirection': {
        if (filters[key].selectedFilters.length === 1) {
          const filterValue = filters[key].selectedFilters[0].value as boolean;
          const newFilter = directionFilter(filterValue);
          filter.and.push(newFilter);
        }
      }
    }
  });

  if (workItemsFilter.any.and.length > 0) {
    filter.workItems = workItemsFilter;
  }

  const expand = ['workItems', 'queueSegments', 'rejectedEnqueueAttempts'];

  return buildQuery({ filter, expand, orderBy, top: paging.top, skip: paging.skip, count: true });
};

export default queryBuilder;