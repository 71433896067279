/* eslint-disable @typescript-eslint/no-empty-function */
import React, { ReactElement, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useRunScriptMutation } from '../../redux/apis/blenderApi';
import { InitiateEmailParams, useInitiateEmailMutation } from '../../redux/apis/emailHubApi';
import { InitiateCallParams, useInitiateCallMutation } from '../../redux/apis/sipConnectorApi';
import { BasePropsContext, ClfProps } from '../providers/BasePropsProvider';
import PageManager from './PageManager';

type InitiateFunc<TParams> = (params: TParams) => Promise<boolean>;

export default function ServiceHandler(): ReactElement {
  const { workItemId, messageApi } = useContext(BasePropsContext) as ClfProps;
  const { t } = useTranslation();

  const [runScript] = useRunScriptMutation();
  const [initiateCall] = useInitiateCallMutation();
  const [initiateEmail] = useInitiateEmailMutation();

  const tryInitiateCall = useCallback<InitiateFunc<Omit<InitiateCallParams, 'workItemId'>>>(
    async (params) => {
      messageApi.loading({ key: 'clf-callInitiating', content: t('messages.callInitiating') + '...', duration: 0 });
      
      try {
        const { isSuccess, reasonCode } = await initiateCall({ ...params, workItemId }).unwrap();

        if (!isSuccess) {
          switch (reasonCode) {
            case 'NumberRestricted':
              messageApi.error(t('messages.callInitiateFailedBecauseNumberRestricted'));
              break;
            default:
              messageApi.error(t('messages.callInitiateFailed'));
              break;
          }
        }

        return isSuccess;
      }
      catch {
        messageApi.error(t('messages.callInitiateFailed'));
        return false;
      }
      finally {
        messageApi.destroy('clf-callInitiating');
      }
    },
    [initiateCall, messageApi, t, workItemId],
  );

  const handleInitiateEmail = useCallback<InitiateFunc<InitiateEmailParams>>(
    async (params) => {
      messageApi.loading({ key: 'clf-emailInitiating', content: t('messages.emailInitiating') + '...', duration: 0 });
      
      try {
        await initiateEmail(params).unwrap();
        return true;
      }
      catch {
        messageApi.error(t('messages.emailInitiateFailed'));
        return false;
      }
      finally {
        messageApi.destroy('clf-emailInitiating');
      }
    },
    [initiateEmail, messageApi, t],
  );

  return (
    <PageManager
      onInitiateCall={tryInitiateCall}
      onInitiateEmail={handleInitiateEmail}
      onRunScript={runScript}
    />
  );
}