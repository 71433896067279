import { DownloadOutlined, FormOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Space, Tag, Tooltip } from 'antd';
import Text from 'antd/es/typography/Text';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import TokenProvider from '../../providers/TokenProvider';
import { responsiveLayout } from '../../utils/containerSizes';

interface IProps {
  tag?: string;
  agentName: string;
  notes?: string;
  isDownloadInProgress?: boolean
  onDownload?(): void
}

const OuterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${p => p.theme.paddingSM}px 0;
  gap: ${p => p.theme.paddingXS}px;
`;

const AgentNameContainer = styled.div`
  text-align: end;
`;

const StyledText = styled(Text)`
  ${responsiveLayout.underWidthBreakPoint} {
    display: none !important;
  };
`;

const StyledSpace = styled(Space)`
   max-width: 35%;
`;

const ActivityData: FC<IProps> = ({ tag, agentName, notes, isDownloadInProgress, onDownload }) => {
  const { t } = useTranslation();

  return <OuterContainer>
    <Space wrap>
      {
        tag &&
        <Tag color='processing' title={tag}>
          <Text ellipsis style={{ maxWidth: '150px' }}>{tag}</Text>
        </Tag>
      }
      {
        onDownload &&
        <Button type='default' size='small' icon={<DownloadOutlined />} disabled={isDownloadInProgress} onClick={onDownload} title={t('activity.downloadTranscript')}>
          <StyledText>{t('activity.transcript')}</StyledText>
        </Button>}
      {
        notes &&
        <Tooltip title={notes} placement='bottom'>
          <Button type='default' size='small' icon={<FormOutlined />}>
            <StyledText>{t('activity.notes')}</StyledText>
          </Button>
        </Tooltip>
      }
    </Space>
    <StyledSpace>
      <AgentNameContainer>
        <Text>{agentName}</Text>
      </AgentNameContainer>
      <TokenProvider overrideTokens={themeTokens => ({ colorTextPlaceholder: themeTokens.colorPrimaryText })}>
        <Avatar size='small' icon={<UserOutlined />} />
      </TokenProvider>
    </StyledSpace>
  </OuterContainer >;
};

export default ActivityData;