import { useEffect, useRef } from 'react';

export type CallbackDescriptor<TState> = [
  /* callback */() => void,
  { when(newState: TState): boolean, except?(newState: TState): boolean; cleanupCallback?(): void },
];

/**
 * Activates the supplied callbacks based on their condition.
 */
export default function useStateChangeCallbacks<TState>(state: TState, callbacks: CallbackDescriptor<TState>[]): void {
  const previousStateRef = useRef(state);
  const callbacksRef = useRef(callbacks);
  callbacksRef.current = callbacks;
  
  useEffect(
    () => {
      callbacksRef.current.forEach(([callback, { when, except, cleanupCallback }]) => {
        if (when(state) && !when(previousStateRef.current) && (except ? !except(state) : true))
          callback();

        if (!when(state) && when(previousStateRef.current))
          cleanupCallback?.();
      });

      previousStateRef.current = state;
    },
    [state],
  );
}