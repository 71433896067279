import { PhoneOutlined } from '@ant-design/icons';
import { Alert, Space, Typography, theme } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface DialAttemptsProps {
  dialAttempts: number,
  dialLimit: number,
  dialLimitReached: boolean;
}

const DialAttempts: React.FC<DialAttemptsProps> = ({ dialAttempts, dialLimit, dialLimitReached }) => {
  const { token } = theme.useToken();
  const { t } = useTranslation();

  return (
    <Space align='baseline' size='middle'>
      <PhoneOutlined style={{ fontSize: '18.5px', color: token.colorTextDescription }} />
      <Typography.Title level={5} style={{ margin: 0 }}>
        {t('feedback.dialAttempts')}: {dialAttempts}/{dialLimit}
      </Typography.Title>
      {
        dialLimitReached && <Alert type="warning" message={t('feedback.dialAttemptLimitReached')} showIcon />
      }
    </Space>
  );
};

export default DialAttempts;
