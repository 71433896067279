import { Radio } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { setOrderDirection } from '../../redux/activityHistorySlice';
import { useAppDispatch } from '../../redux/store';

const OrderByDateContainer: FC = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  return <Radio.Group defaultValue='desc'>
    <Radio.Button value="asc" onClick={() => dispatch(setOrderDirection('asc'))}>{t('filterTitle.oldest')}</Radio.Button>
    <Radio.Button value="desc" onClick={() => dispatch(setOrderDirection('desc'))}>{t('filterTitle.newest')}</Radio.Button>
  </Radio.Group>;
};

export default OrderByDateContainer;