import { List } from 'antd';
import React, { ReactElement } from 'react';
import { Customer } from '../../models/customer';
import CustomerListItem, { CustomerListItemProps } from './CustomerList.Item';

export interface CustomerListProps {
  selectedCustomerId: string | null;
  customers: Customer[];
  onCustomerSelected(customer: Customer): void;
  customerActions?: CustomerListItemProps['actions'];
  focusedCustomerId?: string | null;
}

export default function CustomerList({ selectedCustomerId, customers, onCustomerSelected, customerActions, focusedCustomerId }: CustomerListProps): ReactElement {

  return (
    <List
      dataSource={customers}
      renderItem={(customer: Customer) =>
        <CustomerListItem
          isActive={customer.customerId === selectedCustomerId}
          customer={customer}
          onClick={() => onCustomerSelected(customer)}
          actions={customerActions}
          forceShowActions={customer.customerId === focusedCustomerId}
        />
      }
    />
  );
}