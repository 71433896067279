import { Space } from 'antd';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface IActionsLayoutProps {
  reschedule: ReactNode;
  buttons: ReactNode;
}

const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${p => p.theme.padding}px;
`;

const ActionsLayout: React.FC<IActionsLayoutProps> = ({ reschedule, buttons }) => {
  return (
    <Space direction='vertical' size='middle'>
      {reschedule}
      <Buttons>
        {buttons}
      </Buttons>
    </Space>
  );
};

export default ActionsLayout;
