import { ConfigProvider, theme as antdTheme } from 'antd';
import { AliasToken } from 'antd/es/theme/internal';
import React, { FC, ReactNode } from 'react';

interface IProps {
  children: ReactNode,
  overrideTokens(tokens: AliasToken): Partial<AliasToken>
}

const TokenProvider: FC<IProps> = ({ children, overrideTokens }) => {
  const { token: themeTokens } = antdTheme.useToken();

  return <ConfigProvider theme={{ token: overrideTokens(themeTokens) }}>
    {children}
  </ConfigProvider>;
};

export default TokenProvider;