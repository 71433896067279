import { createApi } from '@reduxjs/toolkit/query/react';
import { AgentWorkspace } from '../../models/agentWorkspace';
import { apiBaseQuery, post } from '../helpers';

const blenderApi = createApi({
  reducerPath: 'blender',
  tagTypes: ['Workspaces'],
  baseQuery: apiBaseQuery('/blender'),
  endpoints: (builder) => ({
    getAgentWorkspaces: builder.query<AgentWorkspace[], void>({
      query: () => '/resource/agentWorkspaces',
      providesTags: ['Workspaces'],
    }),
    setAgentWorkspace: builder.mutation<void, string>({
      query: (agentWorkspaceId) => post('/resource/setAgentWorkspace', { agentWorkspaceId }),
    }),
    runScript: builder.mutation<void, string>({
      query: (customerId) => post('/resource/runScript', { customerId }),
    }),
  }),
});

export default blenderApi;

export const {
  useGetAgentWorkspacesQuery,
  useSetAgentWorkspaceMutation,
  useRunScriptMutation,
} = blenderApi;