import { Tooltip, theme } from 'antd';
import Text from 'antd/es/typography/Text';
import React, { HTMLAttributes, ReactElement, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { ContactType, getContactIcon } from '../../utils/externalProfileUtils';
import { capitalize } from '../../utils/stringUtils';
import Icon from '../controls/Icon';
import Stack from '../layouts/Stack';
import { BasePropsContext } from '../providers/BasePropsProvider';

const Label = styled(Text)`
  cursor: inherit;
  user-select: none;
  color: ${p => p.theme.colorTextSecondary};
`;

const StyledText = styled(Text)<{ $allowCopy?: boolean }>`
  cursor: inherit;
  color: ${p => p.theme.colorText};
  flex-shrink: 1;

  ${p => p.$allowCopy && css`
    &:hover {
      color: ${p.theme.colorLinkHover};
    }

    cursor: pointer;
    transition: all ${p.theme.motionDurationMid} ${p.theme.motionEaseInOut};
  `}
`;

export interface CustomerContactProps extends Pick<HTMLAttributes<unknown>, 'role'> {
  type: ContactType;
  label?: string;
  value: string;
  tooltip?: string;
  allowCopy?: boolean;
}

export default function CustomerContact({ type, label, value, tooltip, allowCopy, role }: CustomerContactProps): ReactElement {
  const { messageApi } = useContext(BasePropsContext);
  const { token } = theme.useToken();
  const { t } = useTranslation();

  const actualType = useMemo(
    () => {
      try {
        new URL(value);
        return 'link';
      }
      catch {
        return type;
      }
    },
    [value, type],
  );

  const copyTextToClipboard = useCallback(
    async () => {
      try {
        await navigator.clipboard.writeText(value);
        messageApi.success(t('messages.textCopied'), 1);
      }
      catch {
        messageApi.error(t('messages.textCopyFailed'), 1);
      }
    },
    [messageApi, t, value],
  );

  return (
    <Stack inline gap="small" verticalAlign="center" aria-label={t('customer.customerConnection')} role={role}>
      {
        type === 'customField'
          ? <Label aria-label={t('customer.customerFieldLabel')}>{label}:</Label>
          : (
            <Tooltip title={capitalize(actualType ?? '')}>
              <Icon large color={token.colorTextTertiary}>{getContactIcon(actualType)}</Icon>
            </Tooltip>
          )
      }
      <Tooltip title={tooltip ?? value} overlayInnerStyle={{ whiteSpace: 'pre-wrap' }}>
        <StyledText ellipsis $allowCopy={allowCopy} onClick={allowCopy ? copyTextToClipboard : undefined}>
          {
            (actualType === 'link')
              ? <a href={value} target="_blank" rel="noreferrer">{value}</a>
              : value
          }
        </StyledText>
      </Tooltip>
    </Stack>
  );
}