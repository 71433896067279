import { presetPrimaryColors } from '@ant-design/colors';
import { DeleteOutlined, PhoneOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Form, FormInstance, Input, Select, Typography } from 'antd';
import { TFunction } from 'i18next';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { PhoneNumber } from '../../models/dtos';

const { Option } = Select;
const { Text } = Typography;

export interface IPhoneNumbersFormContainerProps {
  form: FormInstance;
  phoneNumbers: PhoneNumber[];
  onFinish(phoneNumbers: PhoneNumber[]): void;
}

function getColorOptionsJsx(t: TFunction<'translation'>) {
  return [
    [t('colors.default'), null],
    [t('colors.red'), 'red'],
    [t('colors.volcano'), 'volcano'],
    [t('colors.orange'), 'orange'],
    [t('colors.gold'), 'gold'],
    [t('colors.lime'), 'lime'],
    [t('colors.green'), 'green'],
    [t('colors.blue'), 'blue'],
    [t('colors.geekblue'), 'geekblue'],
    [t('colors.purple'), 'purple'],
    [t('colors.magenta'), 'magenta'],
  ].map(([label, value]) => (
    <Option key={value} value={value} label={label}>
      <Text style={{ color: value ? presetPrimaryColors[value] : undefined }}>{label}</Text>
    </Option>
  ));
}

const PhoneNumberLine = styled.div`
  display: flex;
  gap: ${p => p.theme.marginXS}px;
`;

const PhoneNumberField = styled.div`
  flex: 0 1 300px;
`;

const TagField = styled.div`
  flex: 1;
`;

const StyledPhoneFormItem = styled(Form.Item<PhoneNumber>)`
  margin-bottom: ${p => p.theme.paddingXS}px;
`;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: ${p => p.theme.paddingXS}px;
`;

const StyledInput = styled(Input)`
  .ant-input {
   min-width: 100px;
  }
`;

const PhoneNumbersFormContainer: React.FC<IPhoneNumbersFormContainerProps> = ({ form, phoneNumbers, onFinish }) => {
  const { t } = useTranslation();

  const colorOptionsJsx = useMemo(
    () => getColorOptionsJsx(t),
    [t],
  );

  return (
    <Form layout="vertical" form={form} onFinish={(values: { phoneNumbers: PhoneNumber[] }) => onFinish(values.phoneNumbers)}>
      <Form.List name="phoneNumbers" initialValue={phoneNumbers}>
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <PhoneNumberLine key={key}>
                <PhoneNumberField>
                  <StyledPhoneFormItem {...restField} name={[name, 'phoneNumber']} rules={[{ required: true, message: t('phoneList.pleaseInputAPhone') }]}>
                    <StyledInput
                      title={form.getFieldValue(['phoneNumbers', name, 'phoneNumber'])}
                      maxLength={30}
                      placeholder={t('phoneList.phoneNumber')}
                      addonBefore={<PhoneOutlined />}
                    />
                  </StyledPhoneFormItem>
                </PhoneNumberField>
                <TagField>
                  <StyledFormItem {...restField} name={[name, 'tag']}>
                    <StyledInput
                      addonAfter={
                        <StyledFormItem name={[name, 'color']} noStyle>
                          <Select style={{ width: '100px' }}>
                            {colorOptionsJsx}
                          </Select>
                        </StyledFormItem>
                      }
                    />
                  </StyledFormItem>
                </TagField>
                <Button type="link" disabled={fields.length === 1} danger onClick={() => remove(name)} icon={<DeleteOutlined />} aria-label={t('phoneList.removePhone')} />
              </PhoneNumberLine>
            ))}
            <StyledFormItem>
              <Button disabled={fields.length >= 10} onClick={() => add()} icon={<PlusCircleOutlined />}>
                {t('phoneList.addPhone')}
              </Button>
            </StyledFormItem>
            <Form.ErrorList errors={errors} />
          </>
        )}
      </Form.List>
    </Form >
  );
};

export default PhoneNumbersFormContainer;
