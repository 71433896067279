import { message } from 'antd';
import { MessageInstance } from 'antd/es/message/interface';
import React, { PropsWithChildren, ReactElement, createContext, useMemo } from 'react';
import { ContactLookupFragmentProps } from '../..';
import { CrmChannelSelectorProps } from '../../crm/CrmChannelSelector';

type WithMessageApi<T> = T & { messageApi: MessageInstance };
export type ClfProps = WithMessageApi<ContactLookupFragmentProps>;
export type CcsProps = WithMessageApi<CrmChannelSelectorProps>;

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const BasePropsContext = createContext<ClfProps | CcsProps>(undefined!);

export default function BasePropsProvider<T extends ContactLookupFragmentProps | CrmChannelSelectorProps>(props: PropsWithChildren<T>): ReactElement {
  const value = useMemo<ClfProps | CcsProps>(
    () => ({
      ...props,
      messageApi: props.messageApi ?? message,
      children: undefined,
    }),
    [props],
  );

  return (
    <BasePropsContext.Provider value={value}>
      {props.children}
    </BasePropsContext.Provider>
  );
}