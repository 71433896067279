import { AdaptiveCard } from 'adaptivecards';
import { CardResponse } from './ScriptRunnerService';

export function escapeCustomJs(str: string): string {
  return str
    .replace(/\\/g, '\\\\')
    .replace(/`/g, '\\`');
}

export function mapCardVariables(source: CardResponse['variables']): Record<string, unknown> {
  return Object.keys(source)
    .reduce(
      (coll, id) => {
        const variable = source[id];
        coll[variable.name] = variable.value;
        return coll;
      },
      {} as Record<string, unknown>,
    );
}

export function mapActionVariables(source: Record<string, unknown>, dictionary: CardResponse['variables']): Record<string, unknown> {
  return Object.keys(source)
    .reduce(
      (coll, id) => {
        const variable = Object.values(dictionary).find(v => v.id === id);
        coll[variable?.name ?? id] = source[id];
        return coll;
      },
      {} as Record<string, unknown>,
    );
}

export function mapToSubmitVariables(source: Record<string, unknown>, dictionary: CardResponse['variables']): Record<string, unknown> {
  return Object.keys(source)
    .reduce(
      (coll, name) => {
        const variable = Object.values(dictionary).find(v => v.name === name);
        coll[variable?.id ?? name] = source[name];
        return coll;
      },
      {} as Record<string, unknown>,
    );
}

export function getAdaptiveCardsInstance(scriptProcessId: string): AdaptiveCard {
  const w = window as unknown as { _buzzeasyAdaptiveCards: Record<string, AdaptiveCard> | undefined };

  if (!w._buzzeasyAdaptiveCards) {
    w._buzzeasyAdaptiveCards = {};
  }

  if (!w._buzzeasyAdaptiveCards[scriptProcessId])
    w._buzzeasyAdaptiveCards[scriptProcessId] = new AdaptiveCard();

  return w._buzzeasyAdaptiveCards[scriptProcessId];
}