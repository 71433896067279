import React, { ReactElement } from 'react';
import styled from 'styled-components';

const Container = styled.div<{ $show: boolean }>`
  @keyframes spinnerRotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }


  width: 100%;
  height: 100%;

  display: ${p => p.$show ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;

  .srf-Spinner {
    width: 75px;
    height: 75px;

    border-radius: 50%;
    border-width: 3px;
    border-style: solid;
    border-color: ${p => p.theme.colorPrimaryBg};
    border-top-color: ${p => p.theme.colorPrimary};

    animation-name: spinnerRotate;
    animation-duration: 1.3s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.53, 0.21, 0.29, 0.67);
  }
`;
Container.displayName = 'SpinnerContainer';

function LoadingSpinner({ show }: { show: boolean }): ReactElement {
  return (
    <Container className="srf-SpinnerContainer" $show={show}>
      <div className="srf-Spinner" />
    </Container>
  );
}

export default LoadingSpinner;