import { DownOutlined, LoginOutlined, LogoutOutlined, RightOutlined } from '@ant-design/icons';
import { Space, Tag } from 'antd';
import Text from 'antd/es/typography/Text';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ResultType } from '../../models/typings';
import { responsiveLayout } from '../../utils/containerSizes';

interface IProps {
  channelName: string;
  duration: string;
  result: ResultType | undefined;
  isOutbound: boolean;
  isOpen: boolean
  onExpand(): void
}

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${p => p.theme.paddingXS}px;
  padding: ${p => p.theme.paddingXS}px ${p => p.theme.paddingSM}px;
  background-color: ${p => p.theme.colorFillAlter};
  border-bottom: ${p => p.theme.lineWidth}px solid ${p => p.theme.colorBorder};
  cursor: pointer;
`;

const StyledText = styled(Text)`
  ${responsiveLayout.underWidthBreakPoint} {
    display: none;
  };
`;

const StyledTag = styled(Tag)`
  ${responsiveLayout.underWidthBreakPoint} {
    height: ${p => p.theme.sizeLG}px;
    
    .anticon+span {
      margin-inline-start: 0;
    }
  } 
`;

const getTagColor = (type: ResultType) => {
  switch (type) {
    case 'Abandoned':
    case 'CustomerDisconnected':
    case 'Missed':
    case 'CustomerJoinFailed':
    case 'AgentJoinFailed':
      return 'warning';
    case 'CallbackRequested':
    case 'Cancelled':
    case 'Rejected':
    case 'TransferToChannel':
    case 'TransferToExternal':
    case 'CallbackCanceled':
    case 'CancelledByAgent':
    case 'Unspecified':
    case 'Default':
      return 'processing';
    case 'BotFailed':
    case 'InternalError':
    case 'TransferToAgentFailed':
    case 'Failed':
    case 'IdleTimeout':
    case 'NoAgentLoggedIn':
    case 'NoStaffedAgentLoggedIn':
    case 'QueueSizeLimitReached':
    case 'QueueTimedOut':
    case 'TransferToExternalFailed':
      return 'error';
    case 'Handled':
      return 'success';
    default:
      return 'default';
  }
};

const ActivityDetailsHeader: FC<IProps> = ({ channelName, duration, result, isOutbound, isOpen, onExpand }) => {
  const { t } = useTranslation();

  return <HeaderContainer onClick={onExpand}>
    <Space size='middle'>
      {
        isOpen ? <DownOutlined onClick={onExpand} /> : <RightOutlined onClick={onExpand} />
      }
      <Text ellipsis style={{ maxWidth: '150px' }} title={channelName}>{channelName}</Text>
      <Text title={t('activity.duration')}>{duration}</Text>
      {
        isOutbound ?
          <StyledTag icon={<LogoutOutlined />} title={t('direction.outbound')}>
            <StyledText>{t('direction.outbound')}</StyledText>
          </StyledTag>
          :
          <StyledTag icon={<LoginOutlined />} title={t('direction.inbound')}>
            <StyledText>{t('direction.inbound')}</StyledText>
          </StyledTag>
      }
    </Space>
    {result !== undefined && <Tag color={getTagColor(result)}>{t('result.', { context: result })}</Tag>}
  </HeaderContainer>;
};

export default ActivityDetailsHeader;