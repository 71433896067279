type Nullable<T> = T | null | undefined;

function empty<T extends string | number | boolean>(value: Nullable<T>): value is null | undefined {
  return value === null || value === undefined;
}

function notEmpty<T extends string | number | boolean>(value: Nullable<T>): value is T {
  return value !== null && value !== undefined;
}

// ['', 'a', 'b', null]
export function stringComparer(a: Nullable<string>, b: Nullable<string>): number {
  if (notEmpty(a) && notEmpty(b))
    return a.localeCompare(b, undefined, { sensitivity: 'base' });

  if (notEmpty(a) && empty(b))
    return -1;

  if (empty(a) && notEmpty(b))
    return 1;

  return 0;
}

// [0, 1, null]
export function numberComparer(a: Nullable<number>, b: Nullable<number>): number {
  if (notEmpty(a) && notEmpty(b))
    return a - b;

  if (notEmpty(a) && empty(b))
    return -1;

  if (empty(a) && notEmpty(b))
    return 1;

  return 0;
}

// [false, null, true]
export function booleanComparer(a: Nullable<boolean>, b: Nullable<boolean>): number {
  if (a === false && b === true)
    return -1;

  if (a === true && b === false)
    return 1;

  if (a === false && empty(b))
    return -1;

  if (empty(a) && b === false)
    return 1;

  if (a === true && empty(b))
    return 1;

  if (empty(a) && b === true)
    return -1;

  return 0;
}

function getArrayBaseType<TItem, TProp>(array: TItem[], selector: (item: TItem) => TProp) {
  let baseType = 'null';

  for (const item of array) {
    const prop = selector(item);
    if (prop !== null)
      baseType = typeof prop;
  }

  if (array.every(x => { const prop = selector(x); return prop === null || typeof (prop) === baseType; }))
    return baseType;

  return 'unknown';
}

function sortByProperty<TItem, TProp extends Nullable<string | number | boolean>>(this: TItem[], selector: (item: TItem) => TProp, order: 'ASC' | 'DESC' = 'ASC'): TItem[] {
  if (this.length === 0)
    return [];

  let comparer: (a: TProp, b: TProp) => number;
  const baseType = getArrayBaseType(this, selector);

  if (baseType === 'null')
    return [...this];

  switch (baseType) {
    case 'string':
      comparer = stringComparer as unknown as (a: TProp, b: TProp) => number;
      break;
    case 'number':
      comparer = numberComparer as unknown as (a: TProp, b: TProp) => number;
      break;
    case 'boolean':
      comparer = booleanComparer as unknown as (a: TProp, b: TProp) => number;
      break;
    default:
      throw new Error(`Array must not be mixed type, or base-type '${baseType}' is not of string?, number?, boolean?.`);
  }

  const sorted = [...this];
  sorted.sort((a, b) => order === 'ASC' ? comparer(selector(a), selector(b)) : comparer(selector(b), selector(a)));
  return sorted;
}

if (!('sortBy' in Array.prototype)) {
  Object.defineProperty(Array.prototype, 'sortBy', { value: sortByProperty });
}

export type SortByPropertyFn = typeof sortByProperty;