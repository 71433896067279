import React from 'react';
import { Typography } from 'antd';

const { Text } = Typography;
interface IAdditionalDetailProps {
  title: string;
  value: string;
}

const AdditionalDetail: React.FC<IAdditionalDetailProps> = ({ title, value }) => {
  return (
    <div>
      <Text strong>{title}:&nbsp;</Text>
      <Text>{value}</Text>
    </div>
  );
};

export default AdditionalDetail;
