import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FiltersLayout from '../layouts/FiltersLayout';
import AgentFilterContainer from './AgentFilterContainer';
import BaseFilterContainer from './BaseFilterContainer';
import DateFilterContainer from './DateFilterContainer';
import DirectionFilterContainer from './DirectionFilterContainer';
import OrderByDateContainer from './OrderByDateContainer';

interface Props {
  hideSensitiveAgentInformation: boolean;
}

const FiltersContainer: FC<Props> = ({ hideSensitiveAgentInformation }) => {
  const { t } = useTranslation();

  return <FiltersLayout>
    <AgentFilterContainer hideSensitiveAgentInformation={hideSensitiveAgentInformation} />
    <BaseFilterContainer filterType='filterByChannel' title={t('filterTitle.channel')} />
    <DirectionFilterContainer />
    <BaseFilterContainer filterType='filterByMediaType' title={t('filterTitle.mediaType')} />
    <BaseFilterContainer filterType='filterByTag' title={t('filterTitle.completionCode')} />
    <DateFilterContainer />
    <OrderByDateContainer />
  </FiltersLayout>;
};

export default FiltersContainer;