import React, { FC } from 'react';
import { EmailMessageModel, Message } from '../../models/typings';
import TranscriptLinesLayout from '../layouts/TranscriptLinesLayout';
import TranscriptLinesContainerEmailLine from './TranscriptLinesContainer.EmailLine';
import TranscriptLinesContainerMessageLine from './TranscriptLinesContainer.MessageLine';

interface IProps {
  messages: Message[];
  emails: EmailMessageModel[];
}

const TranscriptLinesContainer: FC<IProps> = ({ messages, emails }) => {

  return <TranscriptLinesLayout>
    {
      messages.length > 0
        ? messages.map(m =>
          <TranscriptLinesContainerMessageLine
            key={m.id}
            id={m.id}
            from={m.from}
            content={m.messageBody}
            attachments={m.attachments}
          />
        ).reverse()
        : emails.map(e =>
          <TranscriptLinesContainerEmailLine
            key={e.messageId}
            id={e.messageId}
            from={e.participantId ? 'agent' : 'customer'}
            sender={e.senderType ?? undefined}
            body={e.body}
            attachments={e.attachments}
          />
        ).reverse()
    }
  </TranscriptLinesLayout>;
};

export default TranscriptLinesContainer;