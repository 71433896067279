/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export interface AuthState {
  token: string;
}

const initialState: AuthState = {
  token: 'not_initialized',
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
});

export const authReducer = slice.reducer;