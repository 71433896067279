import { Button } from 'antd';
import React, { MutableRefObject, useState } from 'react';
import { useOverflowDetector } from 'react-detectable-overflow';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getInteractiveElementProps } from '../../utils/accessibility';
import AdditionalDetail from './AdditionalDetail';

interface IAdditionalDetailsProps {
  additionalDetails: Record<string, string>;
  onShowMore(): void;
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  align-items: baseline;
`;

const TruncateWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow: hidden;
  gap: ${p => p.theme.margin}px;
`;

const Ellipsis = styled.span`
  font-family: ${p => p.theme.fontFamily};
  margin-left: ${p => p.theme.margin}px;
  margin-right: ${p => p.theme.margin}px;
`;

const StyledLink = styled(Button).attrs({ type: 'link' })`
  &&:focus-visible {
    outline-offset: -${p => p.theme.lineWidthFocus}px;
  }
`;

const AdditionalDetails: React.FC<IAdditionalDetailsProps> = props => {
  const { additionalDetails, onShowMore } = props;
  const { t } = useTranslation();

  const [overflow, setOverflow] = useState<boolean>();
  const { ref } = useOverflowDetector({ onChange: setOverflow });

  return (
    <Wrapper>
      <TruncateWrapper ref={ref as MutableRefObject<HTMLDivElement>}>
        {
          Object.keys(additionalDetails).map((key) => <AdditionalDetail key={key} title={key} value={additionalDetails[key]} />)
        }
      </TruncateWrapper>
      {overflow && (
        <div>
          <Ellipsis>...</Ellipsis>
          <StyledLink {...getInteractiveElementProps(onShowMore)} aria-expanded="false" aria-label={t('details.showMoreTitle')}>{t('details.more')}</StyledLink>
        </div>
      )}
    </Wrapper>
  );
};

export default AdditionalDetails;
