import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

interface IProps {
  children: ReactNode;
}

const OuterContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; 
  gap: ${p => p.theme.paddingXS}px;
  padding: ${p => p.theme.paddingSM}px;
  border-bottom: ${p => p.theme.lineWidth}px solid ${p => p.theme.colorBorder};
`;

const FiltersLayout: FC<IProps> = ({ children }) => {

  return <OuterContainer>
    {children}
  </OuterContainer>;
};

export default FiltersLayout;