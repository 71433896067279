import { Customer } from './customer';

export function getCustomerFullName(customer: Customer): string {
  return [customer.firstName, customer.middleName, customer.lastName].filter(n => !!n).join(' ');
}

export function getCustomerPrimaryPhone(customer: Customer): string | null {
  if (customer.phoneNumbers.length === 0)
    return null;

  const preferredPhone = customer.phoneNumbers.find(p => p.preferred);

  if (preferredPhone)
    return preferredPhone.number;

  return customer.phoneNumbers[0].number;
}