import { Spin } from 'antd';
import React, { FC, ReactNode, useContext } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useSelector } from 'react-redux';
import { ActivityServiceContext } from '../../providers/ActivityServiceProvider';
import { getActivitiesByCustomerId, selectAppState, selectNumberOfActivities, selectPageSize, selectTotalNumberOfActivities } from '../../redux/activityHistorySlice';
import { useAppDispatch } from '../../redux/store';
import CenterContentLayout from '../layouts/CenterContentLayout';
import TimelineItemsLayout from '../layouts/TimelineItemsLayout';

interface IProps {
  children: ReactNode;
}

const InfiniteScrollContainer: FC<IProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const service = useContext(ActivityServiceContext);

  const numberOfActivities = useSelector(selectNumberOfActivities);
  const totalNumberOfActivities = useSelector(selectTotalNumberOfActivities);
  const pageSize = useSelector(selectPageSize);
  const appState = useSelector(selectAppState);

  const hasMore = numberOfActivities < totalNumberOfActivities;

  return (
    <TimelineItemsLayout>
      <InfiniteScroll
        loadMore={() => {
          if (appState === 'idle') {
            dispatch(getActivitiesByCustomerId({ service, paging: { skip: numberOfActivities, top: pageSize }, isLoadingMore: true }));
          }
        }}
        hasMore={hasMore}
        useWindow={false}
        loader={
          <CenterContentLayout key="loader">
            <Spin />
          </CenterContentLayout>}
      >
        {children}
      </InfiniteScroll>
    </TimelineItemsLayout >
  );
};

export default InfiniteScrollContainer;