import saveAs from 'file-saver';
import React, { FC, useCallback, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { Activity, Message, ResultType } from '../../models/typings';
import { ActivityServiceContext } from '../../providers/ActivityServiceProvider';
import { UserContext } from '../../providers/UserProvider';
import { getConversationHistoryByConversationId, getEmailsByConversationId, selectAgents, selectChannels } from '../../redux/activityHistorySlice';
import { useAppDispatch } from '../../redux/store';
import { transcriptService } from '../../services/transcriptService';
import { formatTimeSpanNormal } from '../../utils/timeUtils';
import ActivityDetailsLayout from '../layouts/ActivityDetailsLayout';
import ActivityData from '../presenters/ActivityData';
import ActivityDetailsHeader from '../presenters/ActivityDetailsHeader';
import TranscriptLinesContainer from './TranscriptLinesContainer';

interface IProps {
  activity: Activity;
  hideSensitiveAgentInformation: boolean
}

const ActivityDetailsContainer: FC<IProps> = ({ activity, hideSensitiveAgentInformation }) => {
  const dispatch = useAppDispatch();
  const service = useContext(ActivityServiceContext);
  const channels = useSelector(selectChannels);
  const agents = useSelector(selectAgents);

  const currentUserId = useContext(UserContext);

  const isChat = activity.mediaType === 'webChat' || activity.mediaType === 'viber' || activity.mediaType === 'facebook' || activity.mediaType === 'instagram' || activity.mediaType === 'whatsApp' || activity.mediaType === 'infobip_sms';
  const agent = agents.find(a => a.id === activity.agentId) ?? { id: -1, name: 'N/A' };
  const agentName = hideSensitiveAgentInformation ? (agent.id === currentUserId ? agent.name : 'Other agent') : agent.name;

  const duration = formatTimeSpanNormal(activity.duration);

  const [isOpen, setIsOpen] = useState(false);
  const [isDownloadInProgress, setIsDownloadInProgress] = useState(false);

  const getChannelById = useCallback(
    (id: number) => {
      const channel = channels.find(c => c.id === id);
      return channel ?? { id, name: `unknown-${id}`, channelType: '' };
    },
    [channels],
  );

  const downloadTranscript = useCallback(
    (name: string, id: string, messages: Message[]) => {
      transcriptService.create(name, id, messages)
        .then(({ isZip, blob }) => saveAs(blob, isZip ? 'transcript.zip' : 'transcript.txt'));
      setIsDownloadInProgress(false);
    },
    [],
  );

  const getChatHistory = useCallback(
    () => {
      setIsDownloadInProgress(true);

      const channel = getChannelById(activity.channelId);
      if (activity.messagesRequestStatus !== 'fulfilled') {
        const chatHistory: Promise<{ payload: Message[] }> = dispatch(getConversationHistoryByConversationId({ service, conversationId: activity.id })) as Promise<{ payload: Message[] }>;

        chatHistory.then(({ payload }) => {
          downloadTranscript(channel.name, activity.id, payload);
        });
      }
      else {
        downloadTranscript(channel.name, activity.id, activity.messages);
      }
    },
    [activity.channelId, activity.id, activity.messages, activity.messagesRequestStatus, dispatch, downloadTranscript, getChannelById, service]
  );

  const onExpand = useCallback(
    () => {
      if (activity.mediaType !== 'voice' && activity.messagesRequestStatus === 'idle') {
        switch (activity.mediaType) {
          case 'email':
            dispatch(getEmailsByConversationId({ service, conversationId: activity.id }));
            break;
          default:
            dispatch(getConversationHistoryByConversationId({ service, conversationId: activity.id }));
        }
      }
      setIsOpen(!isOpen);
    },
    [activity.id, activity.mediaType, activity.messagesRequestStatus, dispatch, isOpen, service]
  );

  return <ActivityDetailsLayout
    header={
      <ActivityDetailsHeader
        channelName={getChannelById(activity.channelId).name}
        duration={duration}
        result={activity.result as ResultType || undefined}
        isOutbound={activity.isOutbound}
        isOpen={isOpen}
        onExpand={onExpand}
      />
    }
    content={isOpen && <>
      <ActivityData
        tag={activity.tag}
        agentName={agentName}
        notes={activity.notes}
        isDownloadInProgress={isDownloadInProgress}
        onDownload={isChat ? getChatHistory : undefined}
      />
      <TranscriptLinesContainer
        messages={activity.messages}
        emails={activity.emails}
      />
    </>
    }
  />;
};

export default ActivityDetailsContainer;