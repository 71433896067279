import React, { forwardRef, ReactNode } from 'react';
import styled from 'styled-components';

interface IProps {
  children: ReactNode;
}

const Container = styled.div`
  width: 100%;
  overflow: auto;
  padding: ${p => p.theme.paddingMD}px ${p => p.theme.paddingSM}px;
`;

// eslint-disable-next-line react/display-name
const TimelineItemsLayout = forwardRef<HTMLDivElement, IProps>((props, ref) => (
  <Container ref={ref}>
    {props.children}
  </Container>
));

export default TimelineItemsLayout;