import { createSlice } from '@reduxjs/toolkit';

export interface ConfigurationState {
  apiBaseUrl: string;
  token: string;
}

const initialState: ConfigurationState = {
  apiBaseUrl: '',
  token: '',
};

const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {},
});

export default configurationSlice;