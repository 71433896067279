import React, { createContext, PropsWithChildren, ReactElement } from 'react';
import { IVirtualConversationFragmentProps } from '..';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const BasePropsContext = createContext<IVirtualConversationFragmentProps>(undefined!);

export default function BasePropsProvider(props: PropsWithChildren<IVirtualConversationFragmentProps>): ReactElement {

  return (
    <BasePropsContext.Provider value={props}>
      {props.children}
    </BasePropsContext.Provider>
  );
}