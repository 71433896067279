import { EditOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Tooltip } from 'antd';
import Text from 'antd/es/typography/Text';
import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import useGetCustomerFields from '../../hooks/useGetCustomerFields';
import { Customer } from '../../models/customer';
import { getCustomerFullName } from '../../models/customer.helpers';
import { ExternalProfileType } from '../../utils/externalProfileUtils';
import { stringCompare } from '../../utils/stringUtils';
import Stack from '../layouts/Stack';
import CustomerContact, { CustomerContactProps } from './CustomerContact';
import { hasValue } from './CustomerEdit.helpers';

const HeaderStack = styled(Stack)`
  :nth-child(2) {
    flex-grow: 1;
    min-width: 1px;
  }
`;

const CustomerName = styled.h4`
  flex: 1 1 auto;
  margin: 0;
`;

export interface CustomerInfoProps {
  customer: Customer;
  showEditButton: boolean;
  onEditClick?(): void;
}

export default function CustomerInfo({ customer, showEditButton, onEditClick }: CustomerInfoProps): ReactElement {
  const { t } = useTranslation();

  const customerFullName = useMemo(
    () => getCustomerFullName(customer),
    [customer],
  );

  const customerFields = useGetCustomerFields(customer);
  const displayedFields = useMemo<CustomerContactProps[]>(
    () => customerFields
      .filter(({ value }) => !!value)
      .map(f => ({ type: 'customField', label: f.label, value: f.value ?? '' })),
    [customerFields],
  );

  const sortedExternalProfiles = useMemo(
    () => [...customer.externalProfiles].sort((l, r) => stringCompare(l.provider, r.provider)),
    [customer.externalProfiles],
  );

  return (
    <Stack vertical gap="small">
      <HeaderStack gap="middle" verticalAlign="center">
        <Avatar icon={<UserOutlined />} />
        <CustomerName>
          <Tooltip title={customerFullName}>
            <Text ellipsis title={customerFullName} aria-label={t('customer.customerName')}>{customerFullName}</Text>
          </Tooltip>
        </CustomerName>
        {showEditButton && <Button type="link" icon={<EditOutlined />} onClick={onEditClick}>{t('misc.edit')}</Button>}
      </HeaderStack>
      {
        displayedFields.length > 0
          ? (
            <Stack wrap gap={['middle', 0]} role="list" aria-label={t('customer.customerFields')}>
              {displayedFields.map((props, i) => <CustomerContact key={`customField-${i}`} {...props} allowCopy role="listitem" />)}
            </Stack>
          )
          : null
      }
      <Stack wrap gap={['middle', 0]} role="list" aria-label={t('customer.customerConnections')}>
        {
          customer.phoneNumbers.map((p, i) =>
            <CustomerContact key={`phoneNum-${i}`} type="phone" value={p.number} allowCopy role="listitem" />
          )
        }
        {
          hasValue(customer.email) &&
          <CustomerContact type="email" value={customer.email} allowCopy role="listitem" />
        }
        {
          sortedExternalProfiles.map((p, i) =>
            <CustomerContact key={`extProfile-${i}`} type={p.provider as ExternalProfileType} value={p.id} allowCopy role="listitem" />
          )
        }
      </Stack>
    </Stack>
  );
}