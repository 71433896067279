/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Action, ThunkDispatch, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { useDispatch } from 'react-redux';
import blenderApi from './apis/blenderApi';
import customerApi from './apis/customerApi';
import emailConnectorApi from './apis/emailConnectorApi';
import emailHubApi from './apis/emailHubApi';
import sipConnectorApi from './apis/sipConnectorApi';
import configurationSlice from './configurationSlice';

export function createStore(apiUrl: string, token: string) {
  const store = configureStore({
    reducer: {
      [configurationSlice.name]: configurationSlice.reducer,
      [blenderApi.reducerPath]: blenderApi.reducer,
      [customerApi.reducerPath]: customerApi.reducer,
      [emailConnectorApi.reducerPath]: emailConnectorApi.reducer,
      [emailHubApi.reducerPath]: emailHubApi.reducer,
      [sipConnectorApi.reducerPath]: sipConnectorApi.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
      blenderApi.middleware,
      customerApi.middleware,
      emailConnectorApi.middleware,
      emailHubApi.middleware,
      sipConnectorApi.middleware,
    ),
    preloadedState: {
      configuration: {
        apiBaseUrl: apiUrl,
        token,
      },
    },
  });

  setupListeners(store.dispatch);

  return store;
}

type StoreType = ReturnType<typeof createStore>;
export type RootState = ReturnType<StoreType['getState']>;

export type AppDispatch = ThunkDispatch<RootState, unknown, Action>;
export const useAppDispatch = () => useDispatch<AppDispatch>();