import { FontColorsOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { Col, Row, Space } from 'antd';
import Search from 'antd/es/input/Search';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';
import { CustomerSearchProps } from '../../redux/apis/customerApi';
import SearchAndFilterBarToggleButton from './SearchAndFilterBar.ToggleButton';

export interface SearchAndFilterBarProps {
  searchText: string;
  onSearchTextChange(value: string): void;
  filters: CustomerSearchProps['filters'];
  onFiltersChange(value: CustomerSearchProps['filters']): void;
  isSearchLoading?: boolean;
}

export default function SearchAndFilterBar({ searchText, onSearchTextChange, filters, onFiltersChange, isSearchLoading }: SearchAndFilterBarProps): ReactElement {
  const theme = useTheme();
  const { t } = useTranslation();

  const [innerSearchText, setInnerSearchText] = useState(searchText);

  useEffect(
    () => {
      setInnerSearchText(searchText);
    },
    [searchText],
  );

  const debouncedSearchTextChange = useDebouncedCallback(onSearchTextChange, 800);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setInnerSearchText(e.target.value);
      debouncedSearchTextChange(e.target.value);
    },
    [debouncedSearchTextChange],
  );

  const toggleFilter = useCallback(
    (filter: keyof CustomerSearchProps['filters']) => onFiltersChange({ ...filters, [filter]: !filters[filter] }),
    [filters, onFiltersChange],
  );

  return (
    <Row gutter={theme.paddingXS} wrap={false}>
      <Col flex="none">
        <Space size={theme.paddingXXS}>
          <SearchAndFilterBarToggleButton checked={filters.needsToHaveName} icon={<FontColorsOutlined />} onClick={() => toggleFilter('needsToHaveName')} tooltip={t('search.onlyShowName')} ariaLabel={t('search.hasName')} />
          <SearchAndFilterBarToggleButton checked={filters.needsToHavePhone} icon={<PhoneOutlined />} onClick={() => toggleFilter('needsToHavePhone')} tooltip={t('search.onlyShowPhone')} ariaLabel={t('search.hasPhone')} />
          <SearchAndFilterBarToggleButton checked={filters.needsToHaveEmail} icon={<MailOutlined />} onClick={() => toggleFilter('needsToHaveEmail')} tooltip={t('search.onlyShowEmail')} ariaLabel={t('search.hasEmail')} />
        </Space>
      </Col>
      <Col flex="auto">
        <Search
          autoFocus
          allowClear
          loading={isSearchLoading}
          placeholder={t('search.searchCustomers')}
          value={innerSearchText}
          onChange={handleChange}
          onSearch={onSearchTextChange}
        />
      </Col>
    </Row>
  );
}