import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { ConversationCommand, DialCommand, RescheduleCommand, UpdatePhoneNumbersCommand } from '../models/commands';

export interface IVirtualConversationHub {
  peek(command: ConversationCommand): void;
  dial(command: DialCommand): void;
  disconnect(command: ConversationCommand): void;
  reject(command: ConversationCommand): void;
  reschedule(command: RescheduleCommand): void;
  updatePhoneNumbers(command: UpdatePhoneNumbersCommand): void;
  cancel(command: ConversationCommand): void;
}

class VirtualConversationHub implements IVirtualConversationHub {

  private connection: HubConnection | undefined;

  private token = 'missing_access_token';

  public createConnection = (url: string, token: string) => {
    this.token = token;
    this.connection?.stop();
    this.connection = new HubConnectionBuilder()
      .withAutomaticReconnect()
      .withUrl(url, { accessTokenFactory: () => this.token })
      .build();

    return this.connection;
  };

  public peek = (command: ConversationCommand) => {
    this.send('Peek', command);
  };

  public dial = (command: ConversationCommand) => {
    this.send('Dial', command);
  };

  public disconnect = (command: ConversationCommand) => {
    this.send('Disconnect', command);
  };

  public reject = (command: ConversationCommand) => {
    this.send('Reject', command);
  };

  public reschedule = (command: RescheduleCommand) => {
    this.send('Reschedule', command);
  };

  public updatePhoneNumbers = (command: UpdatePhoneNumbersCommand) => {
    this.send('UpdatePhoneNumbers', command);
  };

  public cancel = (command: ConversationCommand) => {
    this.send('Cancel', command);
  };

  private send(method: string, ...args: any[]) {
    if (this.connection?.state === HubConnectionState.Connected) {
      this.connection?.send(method, ...args);
    }
    else {
      console.error('Hub is not in the connected state');
    }
  }
}

const virtualConversationHub = new VirtualConversationHub();
export default virtualConversationHub;