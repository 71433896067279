import { CodeOutlined, DownOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { Button, DropDownProps, Dropdown, Space, Tooltip } from 'antd';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Customer } from '../models/customer';
import { getCustomerFullName } from '../models/customer.helpers';

function preventEventPropagation(callback?: () => unknown) {
  return (e: React.MouseEvent<unknown, unknown>) => {
    e.stopPropagation();
    callback?.();
  };
}

function getPhoneMenuProps(customer: Customer, callCustomerPhone: (phoneNumber: string, customerId: string) => void): DropDownProps['menu'] {
  return {
    items: customer.phoneNumbers.map((p, i) => ({
      key: i,
      label: p.number,
      icon: <PhoneOutlined />,
    })),
    onClick: (info) => {
      info.domEvent.stopPropagation();
      callCustomerPhone(customer.phoneNumbers[Number.parseInt(info.key, 10)].number, customer.customerId);
    },
  };
}

interface HookReturnType {
  customerDataActionsFactory(customer: Customer): React.ReactNode[];
  customerListActionsFactory(customer: Customer): React.ReactNode[];
}

export default function useCustomerPageActions(
  allowScriptRunning: boolean,
  callCustomerPhone: (phoneNumber: string, customerId?: string) => void,
  sendEmailToCustomer: (emailAddress: string, customerId?: string, customerName?: string) => void,
  runScript: (customerId: string) => void,
  navigateToCustomer: (customerId: Customer['customerId']) => void,
  focusCustomer: (customerId: Customer['customerId'] | null) => void,
): HookReturnType {
  const { t } = useTranslation();

  const customerDataActionsFactory = useCallback(
    (customer: Customer) => {
      const hasEmail = !!customer.email?.trim();

      return [
        ...(customer.phoneNumbers.length > 1
          ? [
            <Dropdown key="phone" menu={getPhoneMenuProps(customer, callCustomerPhone)}>
              <Button type="primary">
                <Space>
                  <PhoneOutlined />
                  {t('actions.call')}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>,
          ]
          : [
            <Button key="phone" type="primary" icon={<PhoneOutlined />} disabled={customer.phoneNumbers.length === 0} onClick={() => callCustomerPhone(customer.phoneNumbers[0].number, customer.customerId)}>
              {t('actions.call')}
            </Button>,
          ]),
        <Button key="email" type="primary" icon={< MailOutlined />} disabled={!hasEmail} onClick={() => sendEmailToCustomer(customer.email ?? '', customer.customerId, getCustomerFullName(customer))}>
          {t('actions.email')}
        </Button>,
        <Button key="script" type="primary" icon={<CodeOutlined />} disabled={!allowScriptRunning} onClick={() => runScript(customer.customerId)}>
          {t('actions.runScript')}
        </Button>,
      ];
    },
    [allowScriptRunning, callCustomerPhone, runScript, sendEmailToCustomer, t],
  );

  const customerListActionsFactory = useCallback(
    (customer: Customer) => {
      const hasEmail = !!customer.email?.trim();

      return [
        ...(customer.phoneNumbers.length > 1
          ? [
            <Dropdown key="phone"
              menu={getPhoneMenuProps(customer, (phone) => {
                callCustomerPhone(phone, customer.customerId);
                navigateToCustomer(customer.customerId);
              })}
              onOpenChange={(open) => focusCustomer(open ? customer.customerId : null)}
            >
              <Tooltip title={t('actions.callCustomer')}>
                <Button type="primary" icon={<PhoneOutlined />} onClick={preventEventPropagation()} aria-label={t('actions.callCustomer')} />
              </Tooltip>
            </Dropdown >,
          ]
          : [
            <Tooltip key="phone" title={t('actions.callCustomer')}>
              <Button
                type="primary"
                icon={<PhoneOutlined />}
                disabled={customer.phoneNumbers.length === 0}
                onClick={preventEventPropagation(() => {
                  callCustomerPhone(customer.phoneNumbers[0].number, customer.customerId);
                  navigateToCustomer(customer.customerId);
                })}
                aria-label={t('actions.callCustomer')}
              />
            </Tooltip>,
          ]),
        <Tooltip key="email" title={t('actions.emailCustomer')}>
          <Button
            type="primary"
            icon={< MailOutlined />}
            disabled={!hasEmail}
            onClick={preventEventPropagation(() => {
              sendEmailToCustomer(customer.email ?? '', customer.customerId, getCustomerFullName(customer));
              navigateToCustomer(customer.customerId);
            })}
            aria-label={t('actions.emailCustomer')}
          />
        </Tooltip>,
        <Tooltip key="script" title={t('actions.runScriptOnCustomer')}>
          <Button
            type="primary"
            icon={<CodeOutlined />}
            disabled={!allowScriptRunning}
            onClick={preventEventPropagation(() => runScript(customer.customerId))}
            aria-label={t('actions.runScriptOnCustomer')}
          />
        </Tooltip>,
      ];
    },
    [allowScriptRunning, callCustomerPhone, focusCustomer, navigateToCustomer, runScript, sendEmailToCustomer, t],
  );

  return { customerDataActionsFactory, customerListActionsFactory };
}