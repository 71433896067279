import { GlobalToken } from 'antd';
import { SpaceSize } from 'antd/es/space';

export function spaceSizeToPixels(tokens: GlobalToken, spaceSize: NonNullable<SpaceSize>): number {
  if (typeof spaceSize === 'number')
    return spaceSize;

  switch (spaceSize) {
    case 'large':
      return tokens.paddingLG;
    case 'middle':
      return tokens.padding;
    case 'small':
      return tokens.paddingXS;
  }
}