import { Input, InputRef, Modal } from 'antd';
import React, { KeyboardEventHandler, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

function keyboardActivateHandler(onKeyDown: KeyboardEventHandler): KeyboardEventHandler {
  return (e) => {
    if (
      e.key === 'Enter' &&
      !e.ctrlKey &&
      !e.shiftKey &&
      !e.altKey &&
      !e.metaKey
    ) {
      onKeyDown(e);
    }
  };
}

const emailRegex = /^[\w0-9.%+-]+@[\w0-9.-]+\.[\w]{2,6}$/;

export interface AdhocOutboundModalProps {
  isOpen: boolean;
  mediaGroup: 'voice' | 'email';
  onInitiate(identifier: string): void;
  onCancel(): void;
}

export default function AdhocOutboundModal({ isOpen, mediaGroup, onInitiate, onCancel }: AdhocOutboundModalProps): ReactElement {
  const { t } = useTranslation();

  const [identifier, setIdentifier] = useState('');

  const handlePhoneNumberPaste = useCallback<React.ClipboardEventHandler<HTMLInputElement>>(
    (e) => {
      e.preventDefault();
      const clipboardText = e.clipboardData.getData('Text');
      const sanitizedText = clipboardText.startsWith('sip:') || clipboardText.includes('@') // crude but works in most cases
        ? clipboardText
        : clipboardText.replace(/[^+\d]/gi, '');

      setIdentifier(sanitizedText);
    },
    [],
  );

  const titles = useMemo(
    () => ({
      'email': t('adhoc.startAdHocEmail'),
      'voice': t('adhoc.startAdHocCall'),
    }),
    [t],
  );

  const okTexts = useMemo(
    () => ({
      'email': t('adhoc.email'),
      'voice': t('adhoc.call'),
    }),
    [t],
  );

  const placeholders = useMemo(
    () => ({
      'email': t('adhoc.emailAddress'),
      'voice': t('adhoc.phoneNumber'),
    }),
    [t],
  );

  const valid = {
    'email': identifier.length === 0 || new RegExp(emailRegex).test(identifier),
    'voice': true,
  }[mediaGroup];

  const inputRef = useRef<InputRef>(null);

  useEffect(
    () => {
      inputRef.current?.focus();
    },
    [],
  );

  return (
    <Modal
      title={titles[mediaGroup]}
      open={isOpen}
      onOk={() => onInitiate(identifier)}
      onCancel={onCancel}
      okText={okTexts[mediaGroup]}
      okButtonProps={{ disabled: identifier.trim() === '', htmlType: 'submit' }}
      width={350}
    >
      <Input
        ref={inputRef}
        value={identifier}
        onChange={(e) => setIdentifier(e.target.value)}
        placeholder={placeholders[mediaGroup]}
        maxLength={mediaGroup === 'voice' ? 30 : undefined}
        onPaste={mediaGroup === 'voice' ? handlePhoneNumberPaste : undefined}
        onKeyDown={keyboardActivateHandler(() => onInitiate(identifier))}
        status={!valid ? 'error' : undefined}
        aria-invalid={!valid}
      />
    </Modal>
  );
}