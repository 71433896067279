import { Empty } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const OuterContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EmptyContainer = styled.div`
  padding: 0 ${p => p.theme.paddingLG}px;
  border-radius: 8px;
  box-shadow: 2px 6px 19px 5px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 2px 6px 19px 5px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 2px 6px 19px 5px rgba(0, 0, 0, 0.08); 
`;

const EmptyHistory: FC = () => {
  const { t } = useTranslation();

  return <OuterContainer>
    <EmptyContainer>
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('emptyHistory')} />
    </EmptyContainer>
  </OuterContainer>;
};

export default EmptyHistory;