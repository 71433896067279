import { Radio, RadioChangeEvent, Space } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PhoneNumber } from '../../models/dtos';
import PhoneNumberRadioButton from '../presenters/PhoneNumberRadioButton';
import PhoneNumberWithTag from '../presenters/PhoneNumberWithTag';

export interface IPhoneNumbersContainerProps {
  phoneNumbers: PhoneNumber[];
  isPhoneNumberSelectionDisabled: boolean;
  selectedPhoneNumber: string;
  setSelectedPhoneNumber(value: string): void;
  dialLimitReached: boolean;
}

const PhoneNumbersContainer: FC<IPhoneNumbersContainerProps> = ({ phoneNumbers, isPhoneNumberSelectionDisabled, dialLimitReached, selectedPhoneNumber, setSelectedPhoneNumber }) => {
  const { t } = useTranslation();

  return (
    <>
      {isPhoneNumberSelectionDisabled ? (
        <Space direction="vertical" >
          {phoneNumbers.map(x => (
            <PhoneNumberWithTag
              key={x.phoneNumber}
              phoneNumber={x.phoneNumber}
              tag={x.tag}
              tagColor={x.color}
              isDisabled={dialLimitReached || x.restricted}
              disabledReason={x.restricted ? t('feedback.phoneRestricted') : undefined}
            />
          ))}
        </Space>
      ) : (
        <Radio.Group onChange={(e: RadioChangeEvent) => setSelectedPhoneNumber(e.target.value)} value={selectedPhoneNumber} disabled={dialLimitReached}>
          <Space direction="vertical" size={'middle'}>
            {phoneNumbers.map(x => (
              <PhoneNumberRadioButton
                key={x.phoneNumber}
                phoneNumber={x.phoneNumber}
                tag={x.tag}
                tagColor={x.color}
                isDisabled={dialLimitReached || x.restricted}
                disabledReason={x.restricted ? t('feedback.phoneRestricted') : undefined}
              />
            ))}
          </Space>
        </Radio.Group>
      )}
    </>
  );
};

export default PhoneNumbersContainer;
