import React, { FC } from 'react';
import { Attachment, SenderType } from '../../models/typings';
import MessageLine from '../presenters/MessageLine';
import TranscriptLinesContainerLink from './TranscriptLinesContainer.Link';

interface IProps {
  id: string
  from: string
  content: string
  attachments?: Attachment[]
}

const TranscriptLinesContainerMessageLine: FC<IProps> = ({ id, from, content, attachments }) => {
  const isInternal = from.toLowerCase() !== 'customer';
  const senderType = from.toLowerCase() as SenderType;

  return <MessageLine
    senderType={senderType}
    content={content ? content : <TranscriptLinesContainerLink attachments={attachments ?? []} />}
    isInternal={isInternal}
    key={`message-line-${id}`}
  />;
};


export default TranscriptLinesContainerMessageLine;