import React from 'react';
import styled from 'styled-components';
import Loading from './Loading';

const Wrapper = styled.div`
  position: fixed; 
  width: 100%; 
  height: 100%; 
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(229, 227, 227, 0.46); 
  z-index: 2; 
`;

const LoadingOverlay = () => {
  return (
    <Wrapper>
      <Loading />
    </Wrapper>
  );
};

export default LoadingOverlay;
