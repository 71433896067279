/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Activity,
  ActivityHistorySlice,
  ApiResponse,
  AppState,
  Channel,
  Conversation,
  Filters,
  FilterType,
  FilterWithType,
  MediaType,
  OrderDirection,
  Paging,
  RequestStatus,
  UserReadModel,
} from '../models/typings';
import { IActivityService } from '../providers/ActivityServiceProvider';
import queryBuilder from '../services/queryBuilder';
import { RootState } from './store';

type InitActivityHistory = {
  conversations: ApiResponse<Conversation>;
  channels: Channel[];
  businessTags: string[];
  agents: UserReadModel[];
};

const mapToMediaType = (channelType: string): MediaType => {
  let mediaType: MediaType;
  switch (channelType?.toLowerCase()) {
    case 'voice':
      mediaType = 'voice';
      break;
    case 'viber':
      mediaType = 'viber';
      break;
    case 'whatsapp':
      mediaType = 'whatsApp';
      break;
    case 'directline':
    case 'webchat':
      mediaType = 'webChat';
      break;
    case 'email':
      mediaType = 'email';
      break;
    case 'instagram':
      mediaType = 'instagram';
      break;
    case 'facebook':
      mediaType = 'facebook';
      break;
    case 'infobip_sms':
      mediaType = 'infobip_sms';
      break;
    default:
      mediaType = 'unknown';
  }
  return mediaType;
};

const mapConversationToActivity = (conversation: Conversation): Activity => {
  const workItem =
    conversation.workItems.length === 0
      ? null
      : conversation.workItems[conversation.workItems.length - 1];

  const queueSegment =
    conversation.queueSegments.length === 0
      ? null
      : conversation.queueSegments[conversation.queueSegments.length - 1];

  const rejectedEnqueueAttempt =
    conversation.rejectedEnqueueAttempts.length === 0
      ? null
      : conversation.rejectedEnqueueAttempts[conversation.rejectedEnqueueAttempts.length - 1];

  return {
    id: conversation.id,
    mediaType: mapToMediaType(conversation.channelType),
    agentId: workItem?.agentId ?? '',
    date: conversation.startedAtUtc,
    duration: conversation.durationInSeconds,
    tag: workItem?.completionCode ?? '',
    messagesRequestStatus: 'idle',
    channelId: conversation.channelId,
    emails: [],
    messages: [],
    notes: workItem?.notes ?? '',
    result:
      workItem?.result ??
      queueSegment?.dequeuedReason ??
      rejectedEnqueueAttempt?.rejectReason ??
      conversation.conversationEndedReason,
    isOutbound: conversation.isOutbound,
  };
};

const getActivitiesByCustomerId = createAsyncThunk<ApiResponse<Conversation>, { service: IActivityService; paging: Paging; isLoadingMore?: boolean }, { state: { activityHistoryReducer: ActivityHistorySlice } }
>('activities/getByCustomerId', async ({ service, paging }, thunkApi) => {
  const slice = thunkApi.getState().activityHistoryReducer;
  const queryString = queryBuilder(
    slice.customerId,
    slice.orderDirection,
    slice.filters,
    paging
  );
  const activities = await service.getConversationsByQueryString(queryString);
  return activities;
});

const getConversationHistoryByConversationId = createAsyncThunk(
  'activities/getConversationHistoryByConversationId',
  async ({
    service,
    conversationId,
  }: {
    service: IActivityService;
    conversationId: string;
  }) => {
    const conversationHistory =
      await service.getConversationHistoryByConversationId(conversationId);
    return conversationHistory;
  }
);

const init = createAsyncThunk<InitActivityHistory, { service: IActivityService; paging: Paging }, { state: { activityHistoryReducer: ActivityHistorySlice } }
>('activities/init', async ({ service, paging }, thunkApi) => {
  const slice = thunkApi.getState().activityHistoryReducer;
  const queryString = queryBuilder(
    slice.customerId,
    slice.orderDirection,
    slice.filters,
    paging
  );
  const activitiesPromise = service.getConversationsByQueryString(queryString);
  const customerRelatedEntitiesPromise = service.getCustomerRelatedEntities(
    slice.customerId
  );
  const [conversations, { ...customerRelatedEntities }] = await Promise.all([
    activitiesPromise,
    customerRelatedEntitiesPromise,
  ]);
  return {
    conversations,
    channels: customerRelatedEntities.channels,
    businessTags: customerRelatedEntities.completionCodes,
    agents: customerRelatedEntities.agents,
  };
});

const getEmailsByConversationId = createAsyncThunk(
  'activities/getEmailsByConversationId',
  async ({
    service,
    conversationId,
  }: {
    service: IActivityService;
    conversationId: string;
  }) => {
    const emails = await service.getEmailsByConversationId(conversationId);
    return emails;
  }
);

const initialState: ActivityHistorySlice = {
  customerId: '',
  agents: [],
  orderDirection: 'desc',
  filters: {
    filterByMediaType: {
      predefinedFilters: [
        { id: 'whatsApp', value: 'whatsApp' },
        { id: 'viber', value: 'viber' },
        { id: 'email', value: 'email' },
        { id: 'voice', value: 'voice' },
        { id: 'facebook', value: 'facebook' },
        { id: 'instagram', value: 'instagram' },
        { id: 'webChat', value: 'webChat' },
        { id: 'infobip_sms', value: 'infobip_sms' },
      ],
      selectedFilters: [],
    },
    filterByChannel: {
      predefinedFilters: [],
      selectedFilters: [],
    },
    filterByAgent: {
      predefinedFilters: [],
      selectedFilters: [],
    },
    filterByDate: {
      predefinedFilters: [],
      selectedFilters: [],
    },
    filterByTag: {
      predefinedFilters: [],
      selectedFilters: [],
    },
    filterByDirection: {
      predefinedFilters: [
        { id: 'All', value: 'All' },
        { id: 'Inbound', value: 'Inbound' },
        { id: 'Outbound', value: 'Outbound' },
      ],
      selectedFilters: [],
    },
  },
  activities: [],
  totalNumberOfActivities: 0,
  channels: [],
  channelsStatus: 'idle',
  activitiesStatus: 'idle',
  appState: 'init',
  pageSize: 20,
  currentActivitiesRequestId: undefined,
};

const activityHistorySlice = createSlice({
  initialState,
  name: 'activityHistorySlice',
  reducers: {
    setCustomerId: (state, { payload }: PayloadAction<string>) => {
      state.customerId = payload;
    },
    removeFilter: (state, { payload }: PayloadAction<FilterWithType>) => {
      state.appState = 'fetchActivities';
      state.filters[payload.type].selectedFilters = state.filters[
        payload.type
      ].selectedFilters.filter((f) => f.id !== payload.id);
    },
    addFilter: (state, { payload }: PayloadAction<FilterWithType>) => {
      if (
        !state.filters[payload.type].selectedFilters.some(
          (sf) => sf.id === payload.id
        )
      ) {
        state.appState = 'fetchActivities';
        state.filters[payload.type].selectedFilters.push({
          id: payload.id,
          value: payload.value,
        });
      }
    },
    removeFiltersAndAdd: (
      state,
      { payload }: PayloadAction<FilterWithType>
    ) => {
      state.appState = 'fetchActivities';
      state.filters[payload.type].selectedFilters = [
        { id: payload.id, value: payload.value },
      ];
    },
    removeAllFiltersByType: (state, { payload }: PayloadAction<FilterType>) => {
      state.appState = 'fetchActivities';
      state.filters[payload].selectedFilters = [];
    },
    addAllFiltersByType: (state, { payload }: PayloadAction<FilterType>) => {
      state.appState = 'fetchActivities';
      state.filters[payload].selectedFilters =
        state.filters[payload].predefinedFilters;
    },
    removeAllFilters: (state) => {
      state.appState = 'fetchActivities';
      Object.keys(state.filters).forEach((k) => {
        const key = k as FilterType;
        state.filters[key].selectedFilters = [];
      });
    },
    setOrderDirection: (state, { payload }: PayloadAction<OrderDirection>) => {
      state.appState = 'fetchActivities';
      state.orderDirection = payload;
    },
    setAppState: (state, { payload }: PayloadAction<AppState>) => {
      state.appState = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(init.pending, (state, action) => {
      state.activitiesStatus = 'pending';
      state.channelsStatus = 'pending';
      state.currentActivitiesRequestId = action.meta.requestId;
    });
    builder.addCase(init.rejected, (state) => {
      state.activitiesStatus = 'rejected';
      state.channelsStatus = 'rejected';
    });
    builder.addCase(init.fulfilled, (state, action) => {
      state.activitiesStatus = 'fulfilled';
      state.channelsStatus = 'fulfilled';
      state.appState = 'idle';
      state.activities = action.payload.conversations.value.map((cv) =>
        mapConversationToActivity(cv)
      );

      state.channels = action.payload.channels;
      state.filters.filterByTag.predefinedFilters = action.payload.businessTags
        .filter((b) => b)
        .map((b) => {
          return { id: b, value: b };
        });
      state.filters.filterByChannel.predefinedFilters =
        action.payload.channels.map((channel) => {
          return { id: channel.id.toString(), value: channel.name };
        });
      state.totalNumberOfActivities =
        action.payload.conversations['@odata.count'];
      state.agents = action.payload.agents.map((a) => {
        return { id: a.id, name: a.name };
      });
      state.filters.filterByAgent.predefinedFilters = action.payload.agents.map(
        (agent) => {
          return { id: agent.id, value: agent.name };
        }
      );
      state.currentActivitiesRequestId = undefined;
    });
    builder.addCase(getActivitiesByCustomerId.pending, (state, action) => {
      if (action.meta.arg.isLoadingMore) {
        state.appState = 'loadingMore';
      }
      state.activitiesStatus = 'pending';
      state.currentActivitiesRequestId = action.meta.requestId;
    });
    builder.addCase(getActivitiesByCustomerId.rejected, (state) => {
      state.activitiesStatus = 'rejected';
      state.currentActivitiesRequestId = undefined;
    });
    builder.addCase(getActivitiesByCustomerId.fulfilled, (state, action) => {
      if (state.currentActivitiesRequestId !== action.meta.requestId) return;

      if (state.appState !== 'loadingMore') {
        state.activities = [];
      }

      state.activities = [
        ...state.activities,
        ...action.payload.value.map((c) => mapConversationToActivity(c)),
      ];
      state.appState = 'idle';
      state.activitiesStatus = 'fulfilled';
      state.currentActivitiesRequestId = undefined;
      state.totalNumberOfActivities = action.payload['@odata.count'];
    });

    builder.addCase(
      getConversationHistoryByConversationId.pending,
      (state, action) => {
        const activity = state.activities.find(
          (a) => a.id === action.meta.arg.conversationId
        )!;
        activity.messagesRequestStatus = 'pending';
      }
    );
    builder.addCase(
      getConversationHistoryByConversationId.rejected,
      (state, action) => {
        const activity = state.activities.find(
          (a) => a.id === action.meta.arg.conversationId
        )!;
        activity.messagesRequestStatus = 'rejected';
      }
    );
    builder.addCase(
      getConversationHistoryByConversationId.fulfilled,
      (state, action) => {
        const activity = state.activities.find(
          (a) => a.id === action.meta.arg.conversationId
        )!;
        activity.messages = action.payload.map((mie) => {
          return {
            id: mie.id,
            createdAt: mie.createdAt,
            from: mie.from,
            messageBody: mie.messageBody,
            attachments: mie.attachments,
          };
        });
        activity.messagesRequestStatus = 'fulfilled';
        state.appState = 'idle';
      }
    );
    builder.addCase(getEmailsByConversationId.pending, (state, action) => {
      const activity = state.activities.find(
        (a) => a.id === action.meta.arg.conversationId
      )!;
      activity.messagesRequestStatus = 'pending';
    });
    builder.addCase(getEmailsByConversationId.rejected, (state, action) => {
      const activity = state.activities.find(
        (a) => a.id === action.meta.arg.conversationId
      )!;
      activity.messagesRequestStatus = 'rejected';
    });
    builder.addCase(getEmailsByConversationId.fulfilled, (state, action) => {
      const activity = state.activities.find(
        (a) => a.id === action.meta.arg.conversationId
      )!;
      activity.messagesRequestStatus = 'fulfilled';
      state.appState = 'idle';
      action.payload.forEach((email) => {
        //sanitize-html
        if (!activity.emails.find((e) => e.messageId === email.messageId)) {
          activity.emails.push(email);
        }
      });
    });
  },
});
const collectAllSelectedFilters = (filters: Filters) => {
  const allSelectedFilters: FilterWithType[] = [];
  Object.keys(filters).forEach((k) => {
    const key = k as FilterType;
    filters[key].selectedFilters.forEach((f) => {
      allSelectedFilters.push({ ...f, type: key });
    });
  });
  return allSelectedFilters;
};

export { getActivitiesByCustomerId, getConversationHistoryByConversationId, getEmailsByConversationId, init };

export const selectNumberOfActivities = (state: RootState): number =>
  state.activityHistoryReducer.activities.length;
export const selectTotalNumberOfActivities = (state: RootState): number =>
  state.activityHistoryReducer.totalNumberOfActivities;
export const selectPageSize = (state: RootState): number =>
  state.activityHistoryReducer.pageSize;
export const selectCustomerId = (state: RootState): string =>
  state.activityHistoryReducer.customerId;
export const selectAppState = (state: RootState): AppState =>
  state.activityHistoryReducer.appState;
export const selectActivities = (state: RootState): Activity[] =>
  state.activityHistoryReducer.activities;
export const selectFilters = (state: RootState): Filters =>
  state.activityHistoryReducer.filters;
export const selectFilterByFilterType =
  (filterType: FilterType) => (state: RootState) =>
    state.activityHistoryReducer.filters[filterType];
export const selectAllFilters = (state: RootState) =>
  collectAllSelectedFilters(state.activityHistoryReducer.filters);
export const selectChannels = (state: RootState): Channel[] =>
  state.activityHistoryReducer.channels;
export const selectActivitiesStatus = (state: RootState): RequestStatus =>
  state.activityHistoryReducer.activitiesStatus;
export const selectChannelsStatus = (state: RootState): RequestStatus =>
  state.activityHistoryReducer.channelsStatus;
export const selectOrderDirection = (state: RootState): OrderDirection =>
  state.activityHistoryReducer.orderDirection;
export const selectAgents = (state: RootState): UserReadModel[] =>
  state.activityHistoryReducer.agents;
export const {
  setAppState,
  setCustomerId,
  addFilter,
  removeFilter,
  addAllFiltersByType,
  removeAllFiltersByType,
  removeAllFilters,
  setOrderDirection,
  removeFiltersAndAdd,
} = activityHistorySlice.actions;

const activityHistoryReducer = activityHistorySlice.reducer;

export default activityHistoryReducer;
