import { Button } from 'antd';
import Text from 'antd/es/typography/Text';
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { NavButtonProps, PageProps } from '../../models/pages';

const Title = styled(Text)`
  height: ${p => p.theme.controlHeight}px;
  padding: 0 ${p => p.theme.paddingContentHorizontal - p.theme.lineWidth}px; // https://github.com/ant-design/ant-design/blob/master/components/button/style/index.ts

  color: ${p => p.theme.colorText};
  font-weight: 500;
  user-select: none;

  display: flex;
  align-items: center;
`;

function NavItem({ text, onClick, icon }: NavButtonProps): ReactElement {

  return onClick
    ? <Button type="link" icon={icon} onClick={onClick}>{text}</Button>
    : <Title>{text}</Title>;
}

const NavContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export default function PageNavBar({ leftNavButtonProps, rightNavButtonProps }: PageProps): ReactElement {

  return (
    <NavContainer role="navigation">
      {leftNavButtonProps ? <NavItem {...leftNavButtonProps} /> : <div />}
      {rightNavButtonProps ? <NavItem {...rightNavButtonProps} /> : <div />}
    </NavContainer>
  );
}