import React, { Dispatch, PropsWithChildren, ReactElement, ReactNode, SetStateAction, createContext, useState } from 'react';

export interface HeaderInfo {
  actions: ReactNode[];
}

export type HeaderInfoContextValue = [HeaderInfo, Dispatch<SetStateAction<HeaderInfo>>];

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const HeaderInfoContext = createContext<HeaderInfoContextValue>(undefined!);

export default function HeaderInfoProvider({ children }: PropsWithChildren): ReactElement {
  const value = useState<HeaderInfo>({ actions: [] });

  return (
    <HeaderInfoContext.Provider value={value}>
      {children}
    </HeaderInfoContext.Provider>
  );
}