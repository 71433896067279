import React, { FC, useEffect, useMemo, useRef } from 'react';
import { Attachment, EmailContentModel, SenderType } from '../../models/typings';
import FlexColLayout from '../layouts/FlexColLayout';
import MessageLine from '../presenters/MessageLine';
import TranscriptLinesContainerLink from './TranscriptLinesContainer.Link';

interface IProps {
  id: string
  from: string
  sender?: string
  body: EmailContentModel
  attachments?: Attachment[]
}

const TranscriptLinesContainerEmailLine: FC<IProps> = ({ id, from, sender, body, attachments }) => {
  const isInternal = sender ? sender.toLowerCase() === 'bot' : from.toLowerCase() !== 'customer';
  const senderType = sender ? sender.toLowerCase() as SenderType : from.toLowerCase() as SenderType;

  const shadowRootRef = useRef<HTMLDivElement>(null);

  const { displayedEmailContent, displayedAttachments } = useMemo<{ displayedEmailContent: string, displayedAttachments: Attachment[] }>(
    () => {
      if (attachments) {
        const cidAttachments: Attachment[] = [];

        const contentToDisplay = body.content.replaceAll(/src="cid:(.+?)"/gm, (_, g1) => {
          const cidImage = attachments.find(a => a.name === g1.split('@')[0]);

          if (cidImage) {
            cidAttachments.push(cidImage);
            return `src="${cidImage.contentUrl as string}"`;
          }
          return 'src=""';
        });

        const attachmentsToDisplay = attachments.filter(a => !cidAttachments.find(cidA => cidA.name === a.name));

        return { displayedEmailContent: contentToDisplay, displayedAttachments: attachmentsToDisplay };
      }
      return { displayedEmailContent: body.content, displayedAttachments: [] };
    },
    [attachments, body.content],
  );

  useEffect(
    () => {
      if (shadowRootRef.current) {
        if (!shadowRootRef.current.shadowRoot) {
          shadowRootRef.current.attachShadow({ mode: 'open' });
        }
        if (shadowRootRef.current.shadowRoot) {
          shadowRootRef.current.shadowRoot.innerHTML = displayedEmailContent;
        }
      }
    },
    [displayedEmailContent],
  );

  const content =
    <div>
      <FlexColLayout>
        <div ref={shadowRootRef} />
        <TranscriptLinesContainerLink attachments={displayedAttachments} />
      </FlexColLayout>
    </div>;

  return <MessageLine
    senderType={senderType}
    content={content}
    isInternal={isInternal}
    key={`message-line-${id}`}
  />;
};

export default TranscriptLinesContainerEmailLine;