import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Timer from '../components/presenters/Timer';
import { BasePropsContext } from '../providers/BasePropsProvider';
import { selectConversationInfo } from '../redux/conversationSlice';
import useStateChangeCallbacks from './useStateChangeCallbacks';

export default function useManageNotifications() {
  const { isFocused, onFocusRequested, notificationApi, formattingApi } = useContext(BasePropsContext);
  const { conversationId, previewTimerExpiresAt, virtualConversationFsmState } = useSelector(selectConversationInfo);
  const { t } = useTranslation();

  const notificationKey = `vcf-new-conversation-${conversationId}`;

  const cleanUpNotification = () => notificationApi.destroy(notificationKey);

  const sendNotification = () => {
    if (previewTimerExpiresAt && new Date(previewTimerExpiresAt) > new Date())
      notificationApi.open({
        key: notificationKey,
        message: t('notifications.newConversationAutoAccept.message'),
        description: <Trans i18nKey="notifications.newConversationAutoAccept.description" components={{ value: <Timer expiresAt={previewTimerExpiresAt} formatTimeSpan={formattingApi.formatTimeSpan} /> }} />,
        onClick: onFocusRequested,
      });
    else
      notificationApi.open({
        key: notificationKey,
        message: t('notifications.newConversation.message'),
        description: t('notifications.newConversation.description'),
        onClick: onFocusRequested,
      });
  };

  useStateChangeCallbacks({ isFocused, virtualConversationFsmState }, [
    [sendNotification, {
      when: (s) => s.virtualConversationFsmState === 'waitingForDialOrReject',
      except: (s) => s.isFocused,
      cleanupCallback: cleanUpNotification,
    }],
    [cleanUpNotification, {
      when: (s) => s.isFocused,
    }],
  ]);
}