import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

interface IProps {
  children: ReactNode;
}

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexItem = styled.div`
  flex-shrink: 0;
  flex-grow: 0;
`;

const FlexColLayout: FC<IProps> = ({ children }) => {

  return <FlexContainer>
    {React.Children.map(children, c => <FlexItem>{c}</FlexItem>)}
  </FlexContainer>;
};


export default FlexColLayout;