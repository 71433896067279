import { CloseOutlined, ContainerOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce';
import { AgentWorkspace } from '../../models/agentWorkspace';
import { useGetAgentWorkspacesQuery, useSetAgentWorkspaceMutation } from '../../redux/apis/blenderApi';
import ContentWithLoading from '../presenters/ContentWithLoading';
import ListWithSearchAndSelect, { ListItem } from '../presenters/ListWithSearchAndSelect';

const RootContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  min-height: 1px;
  flex-grow: 1;
`;

export interface WorkspaceSelectionPageProps {
  onWorkspaceSelected(workspace: AgentWorkspace | null): void;
  allowCancel?: boolean;
  onCancel?(): void;
}

export default function WorkspaceSelectionPage({ onWorkspaceSelected, allowCancel, onCancel }: WorkspaceSelectionPageProps): ReactElement {
  const { t } = useTranslation();

  const { data: foundWorkspaces, isLoading } = useGetAgentWorkspacesQuery();
  const [sendSelectedWorkspace] = useSetAgentWorkspaceMutation();

  const handleWorkspaceSelected = useCallback(
    (ws: AgentWorkspace | null) => {
      if (ws != null)
        sendSelectedWorkspace(ws.id);

      onWorkspaceSelected(ws);
    },
    [onWorkspaceSelected, sendSelectedWorkspace],
  );

  // automatically select workspace if there is none or only one
  useEffect(
    () => {
      if (foundWorkspaces) {
        const availableWorkspaces = foundWorkspaces.filter(ws => !ws.hideForLookup);

        if (availableWorkspaces.length === 0)
          handleWorkspaceSelected(null);
        else if (availableWorkspaces.length === 1)
          handleWorkspaceSelected(availableWorkspaces[0]);
      }
    },
    [foundWorkspaces, handleWorkspaceSelected],
  );

  const [searchText, setSearchText] = useState('');
  const [debouncedSearchText] = useDebounce(searchText, 800);

  const workspaces = useMemo(
    () => {
      const filter = debouncedSearchText.toLocaleLowerCase();

      return ((foundWorkspaces ?? []) as AgentWorkspace[])
        .filter(ws => !ws.hideForLookup)
        .filter(ws => ws.name.toLocaleLowerCase().includes(filter))
        .sortBy(ws => ws.name);
    },
    [debouncedSearchText, foundWorkspaces],
  );

  return (
    <RootContainer>
      {
        allowCancel &&
        <div role="navigation">
          <Button type="link" icon={<CloseOutlined />} onClick={onCancel}>{t('misc.cancel')}</Button>
        </div>
      }
      <ContentContainer>
        <ContentWithLoading isLoading={isLoading || (foundWorkspaces?.length ?? 0) <= 1}>
          <ListWithSearchAndSelect
            hintText={t('workspaceSelection.workspaceSelectionHint')}
            searchPlaceholder={t('workspaceSelection.searchWorkspaces')}
            searchLoading={searchText !== debouncedSearchText}
            searchText={searchText}
            onSearchTextChange={setSearchText}
            onSelect={handleWorkspaceSelected}

            dataSource={workspaces}
            renderItem={(workspace, active) =>
              <ListItem
                selected={active}
                icon={<ContainerOutlined />}
                title={workspace.name}
                description={undefined /* Will be workspace.description later */}
              />
            }
          />
        </ContentWithLoading>
      </ContentContainer>
    </RootContainer>
  );
}