import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CallbackRequestedBy, ConversationInfo, ErrorEvent, ErrorType, PhoneNumber, VirtualConversationFsmState } from '../models/dtos';
import { RootState } from '../providers/StoreProvider';

export interface ConversationState {
  conversationId: string;
  customerConversationId?: string;
  customFields: Record<string, string>;
  previewTimerExpiresAt?: string;
  afterCallTimerExpiresAt?: string;
  campaignName: string;
  virtualConversationFsmState: VirtualConversationFsmState;
  rescheduledAfter?: string;
  rescheduleToMyself: boolean;
  phoneNumbers: PhoneNumber[];
  customerName: string;
  customerFirstName: string;
  customerLastName: string;
  dialedNumber: string;
  dialLimit?: number;
  dialAttempts?: number;
  rescheduleLimit?: number;
  remainingReschedules?: number;
  cancellableByAgent: boolean;
  callbackRequestedBy: CallbackRequestedBy | null;
  scheduleTarget: string | null;
  errorType: ErrorType | null,
  errorReason: string | null,
  operation: string | null,
}

export const initialState: ConversationState = {
  conversationId: '',
  customerConversationId: undefined,
  customFields: {},
  previewTimerExpiresAt: undefined,
  afterCallTimerExpiresAt: undefined,
  campaignName: '',
  virtualConversationFsmState: 'waitingForAgentToBeAssigned',
  rescheduledAfter: undefined,
  rescheduleToMyself: false,
  phoneNumbers: [],
  customerName: '',
  customerFirstName: '',
  customerLastName: '',
  dialedNumber: '',
  cancellableByAgent: false,
  callbackRequestedBy: null,
  scheduleTarget: null,
  errorType: null,
  errorReason: null,
  operation: null,
};

const slice = createSlice({
  initialState,
  name: 'conversation',
  reducers: {
    setConversationInfo: (state, { payload }: PayloadAction<ConversationInfo>) => {
      state.customerConversationId = payload.customerConversationId;
      state.customFields = payload.customFields;
      state.previewTimerExpiresAt = payload.previewTimerExpiresAt;
      state.afterCallTimerExpiresAt = payload.afterCallTimerExpiresAt;
      state.campaignName = payload.campaign.name;
      state.virtualConversationFsmState = payload.virtualConversationFsmState;
      state.rescheduledAfter = payload.rescheduledAfter;
      state.rescheduleToMyself = payload.rescheduleToMyself ?? false;
      state.customerName = payload.customerName;
      state.customerFirstName = payload.customerFirstName;
      state.customerLastName = payload.customerLastName;
      state.phoneNumbers = payload.phoneNumbers;
      state.dialedNumber = payload.dialedNumber;
      state.dialLimit = payload.workItemDialLimit;
      state.dialAttempts = payload.dialAttempts;
      state.rescheduleLimit = payload.recordRescheduleLimit;
      state.remainingReschedules = payload.remainingReschedules;
      state.cancellableByAgent = payload.cancellableByAgent;
      state.callbackRequestedBy = payload.callbackRequestedBy;
      state.scheduleTarget = payload.scheduleTarget;
    },
    setError: (state, { payload }: PayloadAction<ErrorEvent>) => {
      state.errorReason = payload.errorReason;
      state.operation = payload.operation;
      state.errorType = payload.errorType;
    },
    clearError: (state) => {
      state.errorReason = null;
      state.operation = null;
      state.errorType = null;
    },
  },
});

export const conversationReducer = slice.reducer;

export const selectConversationInfo = (state: RootState) => state.conversationReducer;
export const { setConversationInfo, setError, clearError } = slice.actions;
