export const MINUTE = 60;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;

export function formatNum(num: number): string { return `${num >= 10 ? '' : '0'}${num}`; }

export function breakDownSeconds(seconds: number): { days: number; hours: number; minutes: number; seconds: number } {
  const result = { days: 0, hours: 0, minutes: 0, seconds: 0 };
  let remainingSeconds = seconds;

  if (remainingSeconds >= DAY) {
    result.days = Math.floor(remainingSeconds / DAY);
    remainingSeconds -= result.days * DAY;
  }

  if (remainingSeconds >= HOUR) {
    result.hours = Math.floor(remainingSeconds / HOUR);
    remainingSeconds -= result.hours * HOUR;
  }

  if (remainingSeconds >= MINUTE) {
    result.minutes = Math.floor(remainingSeconds / MINUTE);
    remainingSeconds -= result.minutes * MINUTE;
  }

  result.seconds = Math.floor(remainingSeconds);
  return result;
}

export function formatTimeSpanNormal(timeSeconds: number): string {
  const { hours, minutes, seconds } = breakDownSeconds(timeSeconds);
  let result = `${formatNum(minutes)}:${formatNum(seconds)}`;

  if (hours > 0)
    result = `${formatNum(hours)}:${result}`;

  return result;
}


  
  
