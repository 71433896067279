import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  .ant-timeline, 
  .ant-timeline-item {
    inset-block-start: -3px !important; 
  }
 .ant-timeline-item-label { 
    width: calc(16% - 17px) !important;
  } 
  .ant-timeline-item-content {
    inset-inline-start: calc(15% - 2px) !important; 
    width: calc(81% - 10px) !important;
  } 
  .ant-timeline-item-tail, 
  .ant-timeline-item-head { 
    inset-inline-start: 16% !important;
  }
`;

export default GlobalStyle;